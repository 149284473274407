import Vue from 'vue'
import VueRouter from 'vue-router'
import erp from './modules/erp'
import store from '@/store'
const axios = require('axios');
// import Home from '../components/Home.vue'
// import Search from '../components/Search.vue'

// 懒加载 动态加载
const Home = () => import('../business/home/index.vue')
const Personal = () => import('../business/personal/index.vue')
const MyOrder = () => import('../business/order/index.vue')
const Valuation = () => import('../business/valuation/index.vue')
const Login = () => import('../business/login/index.vue')
const userLogin = () => import('../business/login/userLogin.vue')
const Register = () => import('../business/register/index.vue')
const MemberHome = () => import('../business/memberHome/index.vue')
const BoardMaking = () => import('../business/boardMaking/index.vue')
const PcbBoardMaking = () => import('../business/boardMaking/pcbBoardMaking.vue')
const PcbBatch = () => import('../business/boardMaking/pcbBatch.vue')
const FpcBatch = () => import('../business/boardMaking/fpcBatch.vue')
const AluminumSubstrate = () => import('../business/boardMaking/aluminumSubstrate.vue')
const Manufacture = () => import('../business/manufacture/index.vue')
const PcbaMade = () => import('../business/manufacture/pcbaMade.vue')
const SteelMesh = () => import('../business/manufacture/steelMesh.vue')
const BomSupply = () => import('../business/bomSupply/index.vue')
const Solution = () => import('../business/solution/index.vue')
const SchemeDesign = () => import('../business/solution/schemeDesign.vue')
const LayoutDesign = () => import('../business/solution/layoutDesign.vue')
const FactoryDisplay = () => import('../business/factoryDisplay/index.vue')
const DisplayFactory = () => import('../business/factoryDisplay/displayFactory.vue')
const IaminatedStructure = () => import('../business/factoryDisplay/iaminatedStructure.vue')
const ProcessShow = () => import('../business/factoryDisplay/processShow.vue')
const ProductList = () => import('../business/factoryDisplay/productList.vue')
const Activity = () => import('../business/factoryDisplay/activity.vue')
const Sunbathing = () => import('../business/factoryDisplay/sunbathing.vue')
const AboutUs = () => import('../business/aboutUs/index.vue')
const AboutUs2 = () => import('../business/aboutUs/aboutUs.vue')
const ContactUs = () => import('../business/aboutUs/contactUs.vue')
const News = () => import('../business/aboutUs/news.vue')
const Layout = () => import('../business/layout/index.vue')
const LayoutErp = () => import('../business/layoutErp/index.vue')
const OrderDetail = () => import('../business/order/details.vue')
const Weixin = () => import('../business/weixin/index.vue')
const ConsumptionRecords = () => import('../business/personal/consumptionRecords.vue')
const ObtainingRecords = () => import('../business/personal/obtainingRecords.vue')
const PointsMall = () => import('../business/pointsMall/index.vue')
const ChangePassword = () => import('../business/personal/changePassword.vue')
const Information = () => import('../business/personal/information.vue')
const Address = () => import('../business/personal/address.vue')
var erpRouteList = []

Vue.use(VueRouter)

function getRouteErpList(){
	if(store.state.Layout.routeMenuList!==null){
		let arr = JSON.parse(store.state.Layout.routeMenuList)
		arr.map(res=>{
			let data2 = {
				path: res.path,
				title: res.title,
				name: res.name,
				closable: res.closable,
				meta: {
				  path:res.path,
				  name: res.meta.name,
				  icon: res.meta.icon,
				  activeMenu: res.meta.activeMenu,
				  keepAlive: res.meta.keepAlive,
				  type: res.meta.type
				},
				component: { render(c) { return c('router-view') } },
				children:[]
			}
			res.children.map(res2=>{
				let data3 = {
					path: res2.path,
					title: res2.title,
					name: res2.name,
					closable: res2.closable,
					meta: {
					  path:res2.path,
					  name: res2.meta.name,
					  icon: res2.meta.icon,
					  activeMenu: res2.meta.activeMenu,
					  keepAlive: res2.meta.keepAlive,
					  type: res2.meta.type
					},
					component: (resolve) => require([`@/business/views${res2.meta.url}`], resolve)
				}
				data2.children.push(data3)
			})
			erpRouteList.push(data2)
		})
	}
	// console.log("routeErpList1",erp)
	console.log("routeErpList2",erpRouteList)
}

getRouteErpList();

// 路由操作 no-referrer 解决跨域问题
const routes = [{
		path: '/',
		component: Home,
		meta: {
			title: "深圳市格物思信息科技有限公司 官网-元器件、PCB、SMT等一站式PCBA服务",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/personal',
		component: Personal,
		meta: {
			title: "个人中心",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/consumptionRecords',
		component: ConsumptionRecords,
		meta: {
			title: "积分消费记录",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/obtainingRecords',
		component: ObtainingRecords,
		meta: {
			title: "积分获取记录",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/pointsMall',
		component: PointsMall,
		meta: {
			title: "积分商城",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/information',
		component: Information,
		meta: {
			title: "基本信息",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/address',
		component: Address,
		meta: {
			title: "基本信息",
			name: "referrer",
			keepAlive: true,
			content: "no-referrer"
		}
	},
	{
		path: '/weixin',
		component: Weixin,
		meta: {
			title: "跳转微信小程序",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/changePassword',
		component: ChangePassword,
		meta: {
			title: "修改密码",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/myOrder',
		component: MyOrder,
		meta: {
			title: "订单",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/orderDetail',
		component: OrderDetail,
		meta: {
			title: "订单详情",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/valuation',
		component: Valuation,
		meta: {
			title: "智能报价",
			name: "referrer",
			keepAlive: true,
			content: "no-referrer"
		}
	},
	{
		path: '/login',
		component: Login,
		meta: {
			title: "登录",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/userLogin',
		component: userLogin,
		meta: {
			title: "登录",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/register',
		component: Register,
		meta: {
			title: "注册",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/memberHome',
		component: MemberHome,
		meta: {
			title: "会员首页",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		}
	},
	{
		path: '/boardMaking',
		component: BoardMaking,
		meta: {
			title: "PCB制板",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer",
		},
		children: [{
				path: 'pcbBoardMaking',
				name: 'PCB制板',
				component: PcbBoardMaking,
				meta: {
					title: "PCB制板",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer",
				},
			},
			{
				path: 'pcbBatch',
				name: 'PCB批量',
				component: PcbBatch,
				meta: {
					title: "PCB批量",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer",
				},
			},
			{
				path: 'fpcBatch',
				name: 'FPC',
				component: FpcBatch,
				meta: {
					title: "FPC",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer",
				},
			},
			{
				path: 'aluminumSubstrate',
				name: '铝基板',
				component: AluminumSubstrate,
				meta: {
					title: "铝基板",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer",
				},
			}
		]
	},
	{
		path: '/manufacture',
		component: Manufacture,
		meta: {
			title: "pcba制造",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		},
		children: [{
				path: 'pcbaMade',
				name: 'PCB制板',
				component: PcbaMade,
				meta: {
					title: "PCB制板",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer",
				},
			},
			{
				path: 'steelMesh',
				name: '钢网',
				component: SteelMesh,
				meta: {
					title: "钢网",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer",
				},
			}
		]
	},
	{
		path: '/bomSupply',
		component: BomSupply,
		meta: {
			title: "BOM供应",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		},
	},
	{
		path: '/solution',
		component: Solution,
		meta: {
			title: "解决方案",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		},
		children: [{
				path: 'schemeDesign',
				name: '方案设计',
				component: SchemeDesign,
				meta: {
					title: "方案设计",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'layoutDesign',
				name: 'Layout设计',
				component: LayoutDesign,
				meta: {
					title: "Layout设计",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			}
		]
	},
	{
		path: '/factoryDisplay',
		component: FactoryDisplay,
		meta: {
			title: "工厂展示",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		},
		children: [{
				path: 'displayFactory',
				name: '工厂展示',
				component: DisplayFactory,
				meta: {
					title: "工厂展示",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'iaminatedStructure',
				name: '层压结构',
				component: IaminatedStructure,
				meta: {
					title: "层压结构",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'processShow',
				name: '工艺能力',
				component: ProcessShow,
				meta: {
					title: "工艺能力",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'productList',
				name: '产品展示',
				component: ProductList,
				meta: {
					title: "产品展示",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'activity',
				name: '活动专题',
				component: Activity,
				meta: {
					title: "活动专题",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'sunbathing',
				name: '客户晒单',
				component: Sunbathing,
				meta: {
					title: "客户晒单",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			}

		]
	},
	{
		path: '/aboutUs',
		component: AboutUs,
		meta: {
			title: "关于格物思",
			name: "referrer",
			keepAlive: false,
			content: "no-referrer"
		},
		children: [{
				path: 'aboutUs',
				name: '关于格物思',
				component: AboutUs2,
				meta: {
					title: "关于格物思",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'contactUs',
				name: '关于格物思',
				component: ContactUs,
				meta: {
					title: "关于格物思",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			},
			{
				path: 'news',
				name: '新闻资讯',
				component: News,
				meta: {
					title: "新闻资讯",
					name: "referrer",
					keepAlive: false,
					content: "no-referrer"
				},
			}
		]
	},
	{
		path: '/order',
		component: Layout,
		icon: "el-icon-notebook-1",
		meta: {
			title: "个人中心",
			name: "referrer",
			keepAlive: true,
			content: "no-referrer"
		},
		children: [
			...erp
		]
	},
	{
		path: '/erp',
		component: LayoutErp,
		icon: "home",
		meta: {
			title: "格物思",
			name: "格物思",
			keepAlive: true,
			content: "格物思",
		},
		children: [{
			path: 'home',
			title: '首页',
			name: '/erp/home',
			closable: false,
			meta: {
			  name: '首页',
			  icon: 'home',
			  activeMenu: '/erp/home',
			  keepAlive: true,
			  type: true
			},
			component: () => import('@/business/views/erp/home/home.vue')
		},
			...erpRouteList
		]
	}
]

function getRoutesList(routes, pre) {
	return routes.reduce((array, route) => {
		const path = `${pre}${route.path}`;

		if (route.path !== '*') {
			array.push(path);
		}

		if (route.children) {
			array.push(...getRoutesList(route.children, `${path}/`));
		}

		return array;
	}, []);
}


// getRoutesList(router.options.routes, 'https://zigamiklic.com');
// function getRoutesXML() {
// 	const list = getRoutesList(routes, 'https://www.koiios.net')
// 		.map(route => `<url><loc>${route}</loc></url>`)
// 		.join('\r\n');
// 	return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }

// console.log(getRoutesXML())
// console.log("111",routes)

const router = new VueRouter({
	mode: "history",
	routes
})

export default router