import store from '@/store'
import moment from 'moment'

/**
 * 字符权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
const checkPermi = (value) => {
	if (value && value instanceof Array && value.length > 0) {
		const permissions = JSON.parse(store.state.Layout.roleList) || null
		const permissionDatas = value
		const all_permission = "*:*:*";

		const hasPermission = permissions.some(permission => {
			return permissionDatas.includes(permission)
		})

		if (!hasPermission) {
			return false
		}
		return true
	} else {
		console.error(`need roles! Like checkPermi="['system:user:add']"`)
		return false
	}
}

const isTime = (date,type) => {
	// console.log("time",moment(date).format('YYYY-MM-DD'))
	if(date){
		if(type==true){
			return moment(date).format('YYYY-MM-DD');
		}else{
			return moment(date).format('YYYY-MM-DD HH:mm:ss');
		}
	}else{
		return '暂无数据'
	}
}

const randomColor = () => {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
	    color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

export default {
	checkPermi,
	isTime,
	randomColor
}