import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import directive from './directive' // directive全局指令
import './global.less' // global style
import permission from './utils/permission.js'

import echarts from "echarts";

import ElementUI from 'element-ui'
// fade/zoom 等
import 'element-ui/lib/theme-chalk/index.css';

import VDistpicker from 'v-distpicker'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/styles/antv-theme.less'
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
// 引入粒子特效
import VueParticles from 'vue-particles';
// import './plugins/element.js'

import plugins from './plugins' // plugins
import Pagination from "@/components/Pagination";

// axios请求数据
import axios from "axios"
// 导入lodash
import _ from 'lodash'
// 1.图片懒加载
// npm install vue-lazyload --save
import VueLazyLoad from 'vue-lazyload'

// 导入防止多次点击的节流方法
import preventReClick from './preventReClick'
Vue.use(preventReClick)

// 导入打印模板设计器
import KrPrintDesigner from "kr-print-designer";
import "kr-print-designer/lib/kr-print-designer.css";
Vue.use(KrPrintDesigner);

// 打印
import Print from 'vue-print-nb'
Vue.use(Print);

// i18n部分的配置
// 引入语言包，注意路径
import Chinese from './i18n/config/zh.js';
import English from './i18n/config/en.js';

// 引入并使用vue-i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
Vue.use(VueParticles)

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

const navLang = navigator.language || navigator.userLanguage
const lang = navLang == 'zh-CN' ? 'cn' : 'en'

// 构造i18n对象
const i18n = new VueI18n({
	// 默认语言，这里的local属性，对应message中的cn、en属性
	locale: lang,
	// 引入语言文件
	messages: {
		// 这里的属性名是任意的，您也可以把zh设置为cn等，只是后续切换语言时
		// 要标识这里的语言属性，如：this.$i18n.locale = zh|en|cn|xxx
		'cn': Chinese,
		'en': English,
	}
})

// 解决跨域问题
// import '../vue.config.js'

// 导入全局样式表
import './assets/css/global.css'
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'

import animated from "animate.css";
Vue.use(animated)
Vue.use(Antd);
Vue.use(directive)

import globalWebSocket from './global.js'
Vue.prototype.$globalWebSocket = globalWebSocket

// import NavBar from "./components/common/NavBar";
// import VFooter from "./components/common/footer/VFooter"

// 注入工具
Vue.prototype.$lodash = _;

import jquery from 'jquery'
window.$ = window.jQuery = jquery

Vue.prototype.$axiosbili = axios

//引入权限筛选
Vue.prototype.$permission = permission

// 事件总线 发射:this.$bus.$emit('aaaa')  接收:this.$bus.$on('aaaa',function)
Vue.prototype.$bus = new Vue()

Vue.prototype.$wow = wow

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts;

// 注册为全局组件
// Vue.component('nav-bar', NavBar)
// Vue.component('v-footer', VFooter)
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.component('v-distpicker', VDistpicker);
Vue.component('Pagination', Pagination)
Vue.use(plugins)

// 2.使用懒加载的插件   3.修改img :src -> v-lazy
Vue.use(VueLazyLoad, {
	// 占位图: 还没加载完，用这个图片代替
	// loading: require('./assets/img/bilibili.png')
})

Vue.use(ElementUI)

//路由操作 解决跨域问题
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面meta */
	document.body.scrollTop = 0;
	// firefox
	document.documentElement.scrollTop = 0;
	// safari
	window.pageYOffset = 0;
	if (to.meta.content) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
		meta.name = to.meta.name;
		meta.content = to.meta.content;
		head[0].appendChild(meta)
	}
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next()
});

Vue.prototype._i18n = i18n
import utils from './utils/simpleUtils.js' // utils
Vue.prototype.$utils = utils

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
	//这里与vue.config.js中的事件名相对应
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app')
