import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import selectForm from "./modules/selectForm.js"
import Layout from "./modules/layout.js"
import layoutErp from "./modules/layoutErp.js"
import getters from "./getters.js"

Vue.use(Vuex)

export default new Vuex.Store({
	//模块化处理
	modules: {
		selectForm,
		Layout,
		layoutErp
	},
	getters,
	
	//持久化处理
	plugins: [createPersistedState({
		storage: window.sessionStorage,
		reducer(val) {
			return { ...val }
		}
	})]
})
