const getters = {
	tabsIndex: state => state.selectForm.tabsIndex, //tabs下标位置
	language: state => state.selectForm.language, //语言
	shippingMethod: state => state.selectForm.shippingMethod, //出货方式
	boardLength: state => state.selectForm.boardLength, //板子长度
	boardWidth: state => state.selectForm.boardWidth, //板子宽度
	boardNumber: state => state.selectForm.boardNumber, //板子数量
	boardArea: state => state.selectForm.boardArea, //板子面积
	designDocuments: state => state.selectForm.designDocuments, //设计文件数
	boardType: state => state.selectForm.boardType, //板子类型
	boardNumberPliesIndex: state => state.selectForm.boardNumberPliesIndex, //板子层数
	TGindex: state => state.selectForm.TGindex, //板材/TG下标
	TGsub: state => state.selectForm.TGsub, //板材/TG
	boardThicknessIndex: state => state.selectForm.boardThicknessIndex, //板子厚度下标
	BoardThickness: state => state.selectForm.BoardThickness, //板子厚度选项位置
	copperThicknessIndex: state => state.selectForm.copperThicknessIndex, //铜箔厚度下标
	copperThickness: state => state.selectForm.copperThickness, //铜箔厚度
	minimumLineWidth: state => state.selectForm.minimumLineWidth, //最小线宽
	minimumThroughHole: state => state.selectForm.minimumThroughHole, //最小过孔
	resistanceWeldingColor: state => state.selectForm.resistanceWeldingColor, //焊接颜色
	characterColor: state => state.selectForm.characterColor, //字符颜色
	solderPadSprayCoating: state => state.selectForm.solderPadSprayCoating, //焊盘喷镀
	resistanceWeldingCoverage: state => state.selectForm.resistanceWeldingCoverage, //焊接盖盘
	goldthick: state => state.selectForm.goldthick,
	TestMethodRadio: state => state.selectForm.TestMethodRadio,
	checkList: state => state.selectForm.checkList, //特殊工艺所有选择后的选项
	checkListData: state => state.selectForm.checkListData, //特殊工艺选项字符串化用于传参
	packagingRequirements: state => state.selectForm.packagingRequirements, //包装要求
	addOrderNumber: state => state.selectForm.addOrderNumber, //增加订单编号
	dateManufacture: state => state.selectForm.dateManufacture, //增加或者更新生产日期
	ULidentification: state => state.selectForm.ULidentification, //ul标识
	productionDraft: state => state.selectForm.productionDraft, //确认生产稿
	invoiceType: state => state.selectForm.invoiceType, //发票类型
	invoicingMethod: state => state.selectForm.invoicingMethod, //发票方式
	contractTitle: state => state.selectForm.contractTitle, //收据以及合同抬头
	placingOrder: state => state.selectForm.placingOrder, //下单人
	placingOrderPhone: state => state.selectForm.placingOrderPhone, //下单人手机号
	technicians: state => state.selectForm.technicians, //技术人姓名
	techniciansPhone: state => state.selectForm.techniciansPhone, //技术人手机号
	shippingAddress: state => state.selectForm.shippingAddress, //收货地址
	calcPcbPriceData: state => state.selectForm.calcPcbPriceData, //calcPcbPrice接口返回的计价数据
}

export default getters