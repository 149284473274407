import {
    cg_dataState,
    cg_dataState_data,
    cg_dataState_data_o,
    xs_dataState,
    xs_dataState_data,
    xsxj_dataState_data,
    xsdd_dataState_data,
    xsxj_timeFrame_data,
    role_data
} from './constants'
import moment from 'moment'

export default {
    /**
     * 时间类
     */
    moment,
    /**
     * 字符串不为空
     * @returns {boolean}
     */
    isNotNullString: function (val) {
        if (val && val !== '') {
            return true
        }
        return false
    },
    cg_dataState,
    cg_dataState_data,
    cg_dataState_data_o,
    xs_dataState,
    xs_dataState_data,
    xsxj_dataState_data,
    xsdd_dataState_data,
    xsxj_timeFrame_data,
    role_data
}

