import store from '../../store/index.js'
export default {
	right:{
		qq:{
			text1:"Online consultation",
			text2:"Online communication, please click on me",
			button:"Online consultation"
		},
		phone:{
			text:"Contact Us"
		},
		opinion:{
			title:"Submit Feedback",
			text1:"Name",
			input1:'PPlease enter your namer',
			text2:"corporate name",
			input2:"Please enter the company name",
			text3:"Phone number",
			input3:"Please enter your phone number",
			text4:"Feedback Content",
			input4:"Please enter content",
			text5:"Submit Feedback",
			text6:"Email",
			input5:'Please enter your email address',
			text7:"WeChat mini program QR code:",
			text8:"QR code of official account:",
			text9:"Submitting feedback"
		}
	},
	home:{
		carousel:{
			selectTabs: [{
					id: 1,
					name: 'PCB Quote'
				},
				{
					id: 2,
					name: 'SMT Proofing'
				},
				{
					id: 3,
					name: 'Stencil Quote'
				},
				{
					id: 4,
					name: 'BOM order'
				}
			],
			dimensions:"Dimensions：",
			layers:"Layers：",
			quantity:"Quantity：",
			thickness:"Thickness：",
			length:"Length",
			width:"Width",
			please:"Please Choose Num(pcs)",
			please2:"Please Choose Layers",
			please3:"Please Choose Thickness",
			button:"Get a quote now"
		},
		catalogue:{
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/home/pcb.png',
				title:"PCB Prototype",
				text:"200，000m ² Factory, monthly shipment volume 80000 meters ²， Daily shipment of over 3000 items. PCB online ordering, convenient and fast",
				content:"Single and double-sided board, maximum 20 layers,Resistible control HDI(Buried/blind vias), aluminum substrate, FPC, high-frequency board, etc.",
				id:1
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/home/smt.png',
				title:"SMT patch",
				text:"One piece start sticking,No boot fee and engineering cost for prototype 8 hours for emergency order",
				content:"Starting from one piece, no start-up fee for sample making, engineering fee 8 hours expedited",
				id:2
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/home/BOM@img.png',
				title:"BOM sourcing",
				text:"Own resistance and capacitance sensing inventory, quick quotation, reply on the same day.",
				content:"Quotation within 3 hours,Shipment within 6 hours,Bulk shipment no need for whole package",
				id:3
			},
			// {
			// 	img:process.env.VUE_APP_OSS_URL + '/home/layout.png',
			// 	title:"Project Design",
			// 	text:"Hardware development, software development, cloud service development, industrial design",
			// 	content:"Wireless communication, smart home, smart city, consumer electronics, industrial Internet of Things, AI human-computer interaction",
			// 	id:4
			// },
			{
				img:process.env.VUE_APP_OSS_URL + '/home/layout.png',
				title:"Steel Mesh",
				text:"Hardware development, software development, cloud service development, industrial design",
				content:"The mesh surface is flat, with high tension and high hardness, which is resistant to high temperatures. It can be shipped on the same day, with a maximum delivery time of 4 hours",
				id:4
			}]
		},
		specialSale:{
			leftTitle:{
				title:"65w global customers choose Koiios",
				text:"Fast Turnaround As fast as 24 hours",
				button:"Quote Now",
			},
			rightContent:{
				labelList:['PCB Prototype','PCB Fabrication','SMT Patch','BOM Sourcing'],
				list:[{
					title:'Single&Double layer board',
					type:0,
					content:['Within 10x10cm, 5 pieces','24 hour shipment without expedited charges','free shipping nationally'],
					price:30
				},
				{
					title:'Four layer board',
					type:1,
					content:['Within 10x10cm, 5 pieces','Normal shipment for 4-5 days','Can be expedited 24/48 hours'],
					price:100
				},
				{
					title:'Six layer board',
					type:2,
					content:['Within 10x10cm, 5 pieces','Normal shipment within 5 days','Can be shipped quickly within 24 hours'],
					price:800
				}],
				button2:"Special Purchase",
				flowPath:{
					title:"Ordering process：",
					text1:"Register&Login",
					text2:"Online Quote",
					text3:"Order Confirmation",
					text4:"Online payment",
					text5:"Fabrication Delivery",
					text6:"Confirm Received",
					text7:"Shared"
				}
			},
		},

		srvice:{
			title:"Koiios Electronic Whole Industry Chain Intelligent Manufacturing Interconnection Platform",
			text:"Integrate the whole PCB industry supply chain, Internet plus industry collaboration, and achieve rapid engineering, delivery, and delivery",
			list:[{
				title:'Supply interconnection',
				image:require('@/assets/img/interconnection.png'),
				content:['A-grade board, Original factory direct supply','Strategic cooperation ample preparation','High precision equipment stable production capacity'],
			},
			{
				title:'Intelligent order allocation',
				image:require('@/assets/img/matchingOrder.png'),
				content:['Intelligent pricing system','Transparent quotation','1v1 Q&A'],
			},
			{
				title:'intelligent engineering',
				image:require('@/assets/img/engineering.png'),
				content:['Strong engineering processing power','Accelerate by 70%','Release the production line as fast as 30 minutes'],
			},
			{
				title:'Fast delivery',
				image:require('@/assets/img/logistics.png'),
				content:['Two major production bases','Six major service outlets','Improve logistics system'],
			},
			{
				title:'timely response',
				image:require('@/assets/img/respond.png'),
				content:['Rapid after-sales response','No excuses','99.9% positive review rate'],
			}]
		},

		accordion:{
			title:"Special process",
			text:"We can all do what other families cannot do!",
			button:"Immediate quotation",
			content1:{
				title:"FPC soft board",
				content:"Material structure: double-sided adhesive+low loss yellow covering film+(line copper+adhesive+high-frequency dielectric polyimide substrate+adhesive+line copper)+low loss yellow covering film.",
				label1:"Plate thickness: 0.13mm",
				label2:"Reinforcement: No reinforcement",
				label3:"Production process: Gold sinking process",
				label4:"Soldering resistance: yellow film with white characters",
				label5:"Copper thickness: 0.5oz",
				label6:"Line width and distance: ≥ 4mil"
			},
			content2:{
				title:"HDI blind buried hole",
				label1:"Number of layers/plate thickness: 4-20 layers/0.4-3.0MM",
				label2:"Aperture: ≥ 0.10mm-0.15mm",
				label3:"Surface treatment: tin spraying/gold precipitation/OSP/silver precipitation/tin precipitation/electro gold deposition",
				label4:"Blind burial order: 1-4 orders; Any order;",
				label5:"Hole filling method: resin filling (vacuum resin plugging)/electroplating filling (VCP automatic hole filling line, imported hole filling solution)",
				label6:"Product type: FR4 HDI blind burial/high-frequency board+FR4 HDI blind burial/pure high-frequency material HDI blind burial; Samples and batches can be accepted;"
			},
			content3:{
				title:"High precision impedance board",
				label1:"Number of layers/plate thickness/copper thickness: 2-20 layers/0.2-3.0MM/1-3oZ",
				label2:"Line width/line spacing: min 3/3mil",
				label3:"Aperture: ≥ 0.15mm",
				label4:"Resistance range: 50-125 Ω",
				label5:"Resistance tolerance:+/-10%",
				label6:"Product type: ordinary FR4 impedance board; High frequency impedance board; High frequency+FR4 mixed voltage impedance board;",
				label7:"Note: Our company can provide impedance test strips and impedance test reports;"
			},
			content4:{
				title:"Copper based/aluminum substrate",
				label1:"Number of layers/plate thickness/copper thickness: 1-2 layers/0.8-3.0MM/1-10oZ",
				label2:"Thermal conductivity: 1-3W (high thermal conductivity insulation adhesive)",
				label3:"Surface treatment: tin spraying/gold deposition/OSP",
				label4:"Product type: ordinary single sided copper/aluminum base; Double sided mixed pressing FR4+copper/aluminum base; Double sided sandwich aluminum base; Thermoelectric separation of copper/aluminum based materials;",
			}
		},

		aboutUs:{
			title:"Global Footprint",
			text:"Koiios Electronic Intelligent Hardware Intelligent Manufacturing Platform",
			content1:{
				title:"Serving countries and regions",
				text:"More than"
			},
			content2:{
				title:"Number of global customers",
				text:"Yu Family"
			},
			content3:{
				title:"Number of global customers",
				text:"Balance"
			},
			content4:{
				title:"Total number of employees",
				text:"Multiple people"
			},
			button:"Learn more"
		},

		suningSheet:{
			title:"Customer posting",
			label:"100% positive review rate",
			text:"Fast! Good service! Good quality! Five star review!",
			list:[{
				data:[{
					name: 'Mr./Ms. Li',
					value: 5,
					title: '10 * 10 * cm, 1.6mm, 2 layers, 10 pieces',
					label: ['PCB', '24H'],
					content: 'The quality of the board is good, the hole position is standard, the solder mask layer is uniform, and the quality of the board is also relatively good, which is worth recommending. I have worked on industrial grade boards several times at Koiios and have never had any problems. It is worth recommending, with a good service attitude and strong professional skills.',
					time: '2023-03-29',
					image:require('@/assets/img/sunning.jpg')
				}]
			}],
			list2:[{
				data:[{
					name: 'Mr./Ms. Li',
					value: 5,
					title: '10 * 10 * cm, 1.6mm, 2 layers, 10 pieces',
					label: ['PCB', '24H'],
					content: 'The quality of the board is good, the hole position is standard, the solder mask layer is uniform, and the quality of the board is also relatively good, which is worth recommending. I have worked on industrial grade boards several times at Koiios and have never had any problems. It is worth recommending, with a good service attitude and strong professional skills.',
					time: '2023-03-29',
					image:require('@/assets/img/sunning.jpg')
				}]
			}]
		},

		news:{
			title:"News and information",
			text:"Share ideas and focus with you",
			button:"See more",
			list:[{
				image:require('@/assets/img/newbg.png'),
				time:'2023.03.20',
				title:'2015 China (Chengdu) Electronics Exhibition',
				content:'On July 16-18, 2015, XxinTek participated in the 2015 China (Chengdu) Electronics Exhibition, with its booth located at Hall 3, C247, New International Convention and Exhibition Center, Century City, Chengdu. Xinhang International mainly uses the UK Optoelectronics brand PHOTO full line product authorization agency, Xinshang Mall (www.gchips. com) online trading platform, and overseas original factory agents to provide low prices and short lead times for first-hand goods, with advantages in military products'
			}]
		},
		bottom:{
			text1:"Home",
			text2:"Personal",
			text3:"order",
			text4:"Points Mall"
		}
	},

	valuation:{
		tabs:{
			text1:"PCB pricing",
			text2:"FPC flexible board",
			text3:"SMT pricing",
			text4:"Steel mesh pricing",
			text5:"BOM allocation"
		},
		order:{
			title1:"Invoice Type",
			title2:"Invoice method",
			title3:"Receipt and Contract Title",
			title4:"Place Order Contact",
			title5:"Technical Contact",
			title6:"Address",
			button1:"New Address",
			button2:"modify",
			input1:'full name',
			input2:"cell-phone number"
		},
		pcb:{
			title1:"PCB specification selection",
			text1:"HDI blind buried plate and impedance plate are fully open for order taking, and provide high-quality and high Tg plate selection, with the latest process capability",
			title2:"Personalized requirements",
			text2:"(Gold finger, half hole, customer requested lamination, metallized edge wrapping, product type)",
			title3:"Special process selection",
			text3:"(Gold finger, half hole, customer requested lamination, metallized edge wrapping)",
			text4:"Special processes such as blind hole embedding and impedance control are subject to manual pricing",
			title4:"Fill in the information of the person placing the order",
			text5:"Kind reminder: The order recipient information defaults to the relevant information at the time of user registration, and can be modified for this order.",
			boardElement:{
				title1:"Shipping method",
				content2:"For irregularly shaped or circular PCBs, or boards smaller than 10 * 10CM in size, whether they are manually welded or machine mounted, for orders made in small batches, we recommend assembling them for shipment. After assembling, welding efficiency can be improved and welding processing costs can be reduced. The specific assembly method can be determined according to the process requirements of the SMT processing plant. You can also consult the customer service personnel of Koiios, and we will provide the most reasonable suggestions.",
				title2:"Board size",
				content2:"The board size refers to the length and width of the board submitted by the customer. If it is a circular board, the length and width dimension is the diameter of the circle; If it is a circuit board with irregular appearance, the length and width dimensions shall be measured according to the maximum appearance range; Note: Multiple circuit boards are packed in a compressed package. If you don't know the size after assembly, you can freely fill in the length and width. The staff of Koiios will calculate based on the size of the assembled individual boards. The size of the board has a tolerance range, and the normal tolerance for the appearance is around ± 0.2mm; If there are other requirements for the appearance tolerance, please make a note in other requirements. If there is no note, it will be deemed to agree with the published tolerance size. The appearance must be designed on the same layer, namely the keep out layer or mechanical layer, and should not be designed separately on two layers, otherwise it is easy to make mistakes. If you are unsure, please consult with the staff.",
				input1:"length",
				input2:"width",
				title3:"Number of boards",
				content3:"Within an area of one square meter, if the quantity is within 50 pieces, it is a sample, and if it exceeds this range, it is a small batch.",
			},
			boardElementTwo:{
				title1:"Kind in one panel",
				content1:'The number of splices refers to the collection of collage fees when several different boards are included in the same file. If the same board is spliced, no collage fee will be charged. Assembly division: Punching stamp holes, slotting, V-cutting, and direct milling in the middle of the circuit board are all considered assembly.',
				input1:'Number of input files',
				title2:'Material Type',
				content2:'Refers to the sheet metal manufacturer and the highest grade sheet metal. The insulation, uniformity, impedance stability, and reliability of the copper sheet and sheet metal substrate of the sheet metal are much higher than the B and C grade materials in similar products, and can be used to produce military electronic products with absolutely strict requirements! Low grade board B and C grade materials: Board manufacturers produce board materials due to the low-cost needs of circuit board manufacturers. During the manufacturing process, board manufacturers substitute inferior materials and cut corners. Their performance stability is far inferior to military grade A grade materials, and their prices are far lower than A-grade materials.',
				title3:"Board layers",
				content3:"The selection of board layers provided by Koiios: 1 is a single panel, 2 is a double-sided board, 4 is a four layer board, and 6 is a six layer board. The larger the number of layers, the higher the corresponding cost. Note: For the sake of work efficiency, Koiios mainly produces templates by splicing. Some single panels will be produced together with double-sided panels, that is, the single panel will be produced using the double-sided panel process, and there will be copper in the holes but it will not affect performance, resulting in higher reliability and performance. If the design of the circuit board is a single sided circuit, the effect of copper in the holes should be achieved. When casting a single, choose a double-sided board. PS: The sampling cost for single panel and double panel is similar.",
				title4:"Plate/TG",
				text1:"Plate thickness<1.0 tolerance+/-0.1mm, plate thickness ≥ 1.0mm tolerance+/-10%",
				title5:"Thickness",
				content5:"Board thickness refers to the thickness of a circuit board, and here is the tolerance for board thickness. The company uses KB military grade A material, with a relatively small tolerance, and the normal board thickness is around 10%; 10% is mainly caused by resistance welding and copper deposition; If there are other requirements for plate thickness tolerance, please note in other requirements, otherwise it will be considered as normal plate thickness tolerance. Note: The selection of plate thickness is also related to the price, with prices ranging from 0.6 to 1.6 for normal plate thickness. The prices for 0.4 and 2.0 plate thicknesses are about 100 yuan higher than those for 0.6 to 1.6 normal plate thicknesses.",
				text2:"Plate thickness<1.0 tolerance+/-0.1mm, plate thickness ≥ 1.0mm tolerance+/-10%",
				title6:"Finished Copper",
				content6:"The thickness of ordinary copper foil is 1 OZ (35um), and the thickness of 2OZ (70um) copper foil can be selected for process requirements. It should be noted that the cost of 2 OZ copper thickness is definitely slightly higher than that of 1 OZ copper thickness.",
				title7:"Min Track/Spacing",
				title8:"Min Hole Size",
				title9:"Solder Mask",
				content7:"Some special colors are subject to additional color fees, such as matte green, matte black, and butter black characters or green oil black characters",
				title10:"Character Color",
				content8:"It refers to the character color of the circuit board, and we only provide white. If white is selected as the solder resist ink, the character will be black.",
				title11:"Solder coating",
				content9:"It refers to the surface treatment of circuit boards. 1. There is no charge for surface treatment with lead spray tin, and an additional 20 yuan will be charged for lead-free spray tin, and an additional 100 yuan will be charged for deposit gold; 2. In the actual operation process, when placing an order, choose to spray tin. For efficiency and delivery time, a small number of circuit boards may be produced in a sinking gold plate. The cost of sinking gold is much higher than that of spraying tin, and there are no performance issues. If you do not accept this option, please note in other requirements that the process cannot be changed.",
				title12:"Solder coverage",
				content10:"This item is often controversial and should be emphasized. There are currently three options available: (1) via cover oil, (2) via window opening, (3) gerber files are processed according to files, as gerber files cannot distinguish between via and keyhole, and can only be processed according to files; If the original file is provided, the vias have attributes, and the factory will pay attention when converting the film file. If there is any unclear information, please call the staff for consultation.",
				text3:"If it is a gerber file, it will be processed according to the file, and this option is invalid!",
				title13:"testing mode",
				content11:"There is no charge for spot testing, with a pass rate of over 95%. However, this is only for boards that are relatively simple, have large and sparse line widths and spacing, and have large holes. Choosing a spot testing order will allow for a certain proportion of defective products. Please choose carefully."
			}
		},
		FPC:{
			title1:"FPC specification selection",
			text1:"HDI blind buried plate and impedance plate are fully open for order taking, and provide high-quality and high Tg plate selection, with the latest process capability",
			title2:"Personalized requirements",
			text2:"（Gold finger, half hole, customer requested lamination, metallized edge wrapping, product type）",
			flexibleBoard:{
				tilte1:"Shipping method",
				content1:"For irregularly shaped or circular PCBs, or boards smaller than 10 * 10CM in size, whether they are manually welded or machine mounted, for orders made in small batches, we recommend assembling them for shipment. After assembling, welding efficiency can be improved and welding processing costs can be reduced. The specific assembly method can be determined according to the process requirements of the SMT processing plant. You can also consult the customer service personnel of Gewusi, and we will provide the most reasonable suggestions.",
				title2:"Board size",
				content2:"The board size refers to the length and width of the board submitted by the customer. If it is a circular board, the length and width dimension is the diameter of the circle; If it is a circuit board with irregular appearance, the length and width dimensions shall be measured according to the maximum appearance range; Note: Multiple circuit boards are packed in a compressed package. If you don't know the size after assembly, you can freely fill in the length and width. The staff of Gewusi will calculate based on the size of the assembled individual boards. The size of the board has a tolerance range, and the normal tolerance for the appearance is around ± 0.2mm; If there are other requirements for the appearance tolerance, please make a note in other requirements. If there is no note, it will be deemed to agree with the published tolerance size. The appearance must be designed on the same layer, namely the keep out layer or mechanical layer, and should not be designed separately on two layers, otherwise it is easy to make mistakes. If you are unsure, please consult with the staff.",
				title3:"Number boards",
				input1:"length",
				input2:"width",
				content3:"Within an area of one square meter, if the quantity is within 50 pieces, it is a sample, and if it exceeds this range, it is a small batch."
			},
			flexibleBoardTwo:{
				title1:"Kind in one panel",
				content1:"The number of splices refers to the collection of collage fees when several different boards are included in the same file. If the same board is spliced, no collage fee will be charged. Assembly division: Punching stamp holes, slotting, V-cutting, and direct milling in the middle of the circuit board are all considered assembly.",
				input1:"Please enter the number of files",
				title2:"Board layers",
				content2:"The selection of board layers provided by Koiios: 1 is a single panel, 2 is a double-sided board, 4 is a four layer board, and 6 is a six layer board. The larger the number of layers, the higher the corresponding cost. Note: For the sake of work efficiency, Gewusi mainly produces templates by splicing. Some single panels will be produced together with double-sided panels, that is, the single panel will be produced using the double-sided panel process, and there will be copper in the holes but it will not affect performance, resulting in higher reliability and performance. If the design of the circuit board is a single sided circuit, the effect of copper in the holes should be achieved. When casting a single, choose a double-sided board. PS: The sampling cost for single panel and double panel is similar.",
				title3:"Quality",
				title4:"Thickness",
				content3:"Board thickness refers to the thickness of a circuit board, and here is the tolerance for board thickness. The company uses KB military grade A material, with a relatively small tolerance, and the normal board thickness is around 10%; 10% is mainly caused by resistance welding and copper deposition; If there are other requirements for plate thickness tolerance, please note in other requirements, otherwise it will be considered as normal plate thickness tolerance. Note: The selection of plate thickness is also related to the price, with prices ranging from 0.6 to 1.6 for normal plate thickness. The prices for 0.4 and 2.0 plate thicknesses are about 100 yuan higher than those for 0.6 to 1.6 normal plate thicknesses.",
				title5:"Copper thickness",
				content4:"The thickness of copper foil for ordinary plates is 1 OZ (35um). When required by the process, a thickness of 2OZ (70um) copper foil can be selected. It should be noted that the cost of 2 OZ copper thickness is definitely slightly higher than that of 1 OZ copper thickness.",
				title6:"Solder",
				title7:"Character color",
				content5:"It refers to the character color of the circuit board, and we only provide white. If white is selected as the solder resist ink, the character will be black.",
				title8:"Solder spraying",
				title9:"Turmeric thickness",
				title10:"Reinforce",
				title11:"Adhesive backing",
				title12:"Electromagnetic film",
				title13:"Min Track/Spacing",
				title14:"Min Hole Size",
				title15:"testing mode",
				content6:"There is no charge for spot testing, with a pass rate of over 95%. However, this is only for boards that are relatively simple, have large and sparse line widths and spacing, and have large holes. Choosing a spot testing order will allow for a certain proportion of defective products. Please choose carefully."
			}
		},
		individualization:{
			title1:"Production draft",
			content1:"Koiios has passed UL certification and checked the required option. Koiios will add UL identification in the appropriate position of the top-level character"
		},
		SMT:{
			title1:"SMT specification filling",
			text1:"Special attention: If there is a PCB order, the PCB order number must be filled in to avoid errors and unnecessary misunderstandings.",
			specificationFilling:{
				title1:"Number of parts",
				content1:"Fill in the quantity as the number of single board components, non single board material type, if the quantity is unknown, please fill in 1",
				title2:"Number of PCB",
				content2:"Please fill in the number of PCS pieces",
				title3:"Steel mesh",
				content3:"Delivery of purchased components is not included in the SMD delivery period",
				title4:"Need stencil",
				content4:"Delivery of purchased components is not included in the SMD delivery period",
				title5:"Outsourced components",
				content5:"Delivery of purchased components is not included in the SMD delivery period",
				title6:"Conformal Coating",
				title7:"Component baking",
				title8:"Other requirements",
				title9:"Baking time"
			}
		},
		steel:{
			title:"Filling in steel mesh specifications",
			content1:"Special attention: If there is a PCB order, the PCB order number must be filled in to avoid errors and unnecessary misunderstandings.",
			steel:{
				title1:"Stencil type",
				title2:"Size (mm)",
				title3:"Nanoprocessing",
				title4:"Production method",
				title5:"Number of steel mesh",
				title6:"MARK requirements",
				content1:"MARK points are position recognition points on automatic placement machines used in PCB design, also known as reference points. The diameter is 1MM. MARK points are divided into two types: half cut and through hole. Half cut refers to cutting a groove on the steel sheet, while through hole refers to cutting through the steel sheet. The solder paste steel mesh of fully automatic printing machines mostly adopts MARK dot half engraving. Hand printed and semi-automatic printed solder paste steel mesh can be used without the need for MARK points. But the process of using red glue mesh and fully automatic printing machines all use half engraving. If it is manually printed, it is necessary to find some through holes as positioning holes for easy alignment. 1、 If the MARK point is selected and there is no MK on the board, this option is invalid (for large steel mesh, if through-holes or plug-in holes can be used instead, please explain.) (Attachment: Regardless of whether MK is selected or not, our company will make positioning holes for the glue mesh, and if there is no MK, through-holes or plug-in holes will be used as positioning holes.) Secondly, (1) If the file only has one layer of patches and no silk screen, and GTP (TP) is displayed, it defaults to the front side without mirror phase, and if the layer name displays GBP (BP), it is the back side, Mirror phase is required. If there is no layer name displayed, make it directly without mirror image (if the bottom layer is already mirror image, please explain) (2) If the file has silk screen on the same layer and has mirror image, the silk screen should be all positive (please note here that all files are already mirror image, please explain)",
				title7:"Steel mesh usage",
				title8:"Thickness",
				title9:"polish process",
				title10:"other requirements"
			}
		},
		quotation:{
			title:"PCB quotation and delivery time",
			text1:"Cost",
			text2:"Board fee",
			text3:"Film fee",
			text4:"Splicing fee",
			text5:"Color cost",
			text6:"Spray plating fee",
			text7:"test fee",
			text8:"expedite fee",
			text9:"taxation",
			text10:"Special process fee",
			text11:"Discount fees",
			text12:"other expenses",
			text13:"total",
			button:"submit order"
		},
		fileUpload:{
			text1:'Upload a BOM allocation order',
			text2:'(Only supports xls, xlsx, csv formats) File size<20MB',
			text3:'In order to provide you with a faster and more accurate quotation and production, please provide the following complete documents as much as possible!',
			title1:'BOM file upload',
			title2:'BOM specification filling',
			title3:'Please fill in the BOM order information and upload the BOM file',
			title4:'Purchase quantity',
			text4:'BOM sets',
			text5:'Quantity of order materials listed in BOM',
			title5:'Procurement remarks',
			text6:'File uploaded, file size',
			button1:'Delete',
			button2:'Select properties',
			price:'Quote after review'
		}
	},

	boardMaking:{
		pcbBoardMaking:{
			title:"PCB Fabrication and service",
			text:"Committed to providing PCB Assembly /small batch production services for global R&D and innovation enterprises, serving more than 200 countries and regions.",
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon.png',
				title:'PCB Prototype',
				lable1:'Floors 1-14',
				lable2:'FR-4 TG150/TG170',
				content1:'Can be expedited within 8 hours, place the order on the same day, and ship on the same day',
				content2:'Nationwide package shipping, saving you high shipping costs'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon2.png',
				title:'PCB small batch',
				lable1:'≥50 pcs',
				lable2:'FR-4 TG150 / TG170',
				content1:'The pass-through rate is more than 99%, and takes only 3 days to shipping  when less than 5㎡',
				content2:'10% discount for above 10 ㎡ '
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon3.png',
				title:'High precision PCB board',
				lable1:'Line width and line spacing ≥ 3/3mil',
				lable2:'Through hole ≥ 0.15mm',
				content1:'Raw materials are beneficial, and brands such as Taihong have guaranteed quality',
				content2:'Can be used for soft and hard combination, FPC multi-layer board, cable layout, etc'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon4.png',
				title:'FPC flexible board',
				lable1:'1st to 8th floors',
				lable2:'Calendered copper/electrolytic copper',
				content1:'With a direct delivery rate of over 99%, it only takes 3 days to ship within 5 square meters',
				content2:'10% off for fees above 10 square meters'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon5.png',
				title:'copper substrate',
				lable1:'Thick copper plate/bare copper plate',
				lable2:'Thermal conductivity 2-3W',
				content1:'Raw materials are beneficial, and brands such as Taihong have guaranteed quality',
				content2:'Can be used for soft and hard combination, FPC multi-layer board, cable layout, etc'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon6.png',
				title:'aluminum substrate',
				lable1:'Aluminum substrate/mixed pressure aluminum substrate',
				lable2:'Thermal conductivity coefficient 1-3W',
				content1:'Aluminum plate+high thermal insulation material, high thermal conductivity',
				content2:'Can be used as 8-layer mixed pressure aluminum substrate'
			}]
		},

		specialProcesses:{
			title1:"PCB special board",
			text1:"Customizable special processes/special plates - we have everything you want",
			title2:"01. Customizable special processes",
			text2:"All products of Koiios are above industry standards before leaving the factory",
			content1:"IMPEDANCE BOARD",
			content2:"Special ink (matte ink, purple, etc.)",
			content3:"Blind buried hole",
			content4:"Ultra long and ultra-thin board",
			content5:"Counter bore",
			content6:"OSP process",
			content7:"Thick copper plate, bare copper plate",
			content8:"High frequency and high-speed HDI",
			button:"Customize Now",
			title3:"02. Customizable special plates",
			text3:"Selecting high-quality raw materials and conducting strict testing to safeguard the first level of quality",
			tabs:[{
				name:"ROGERS",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example1.png',
				list:[{
					name:"High quality board",
					text:"The core raw material board of the circuit board is made of A-grade military materials, with very guaranteed quality"
				},
				{
					name:"Exquisite printing process",
					text:"Adopting Guangxin photosensitive ink, in compliance with environmental standards, after high-temperature baking, the ink color is bright and bright, and the characters are clear"
				},
				{
					name:"Refined production process",
					text:"Multiple surface treatment processes ensure that all products meet industry standards for quality before leaving the factory"
				},
				{
					name:"Faster and more timely delivery",
					text:"With the help of the domestic express delivery network, we will deliver the samples to you in a timely manner within the promised time"
				}],
			},
			{
				name:"copper substrate",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example2.png',
				list:[{
					name:"High quality board",
					text:"The core raw material board of the circuit board is made of A-grade military materials, with very guaranteed quality"
				},
				{
					name:"Exquisite printing process",
					text:"Adopting Guangxin photosensitive ink, in compliance with environmental standards, after high-temperature baking, the ink color is bright and bright, and the characters are clear"
				},
				{
					name:"Refined production process",
					text:"Multiple surface treatment processes ensure that all products meet industry standards for quality before leaving the factory"
				},
				{
					name:"Faster and more timely delivery",
					text:"With the help of the domestic express delivery network, we will deliver the samples to you in a timely manner within the promised time"
				}],
			},
			{
				name:"Double sided aluminum substrate",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example3.png',
				list:[{
					name:"High quality board",
					text:"The core raw material board of the circuit board is made of A-grade military materials, with very guaranteed quality"
				},
				{
					name:"Exquisite printing process",
					text:"Adopting Guangxin photosensitive ink, in compliance with environmental standards, after high-temperature baking, the ink color is bright and bright, and the characters are clear"
				},
				{
					name:"Refined production process",
					text:"Multiple surface treatment processes ensure that all products meet industry standards for quality before leaving the factory"
				},
				{
					name:"Faster and more timely delivery",
					text:"With the help of the domestic express delivery network, we will deliver the samples to you in a timely manner within the promised time"
				}],
			},
			{
				name:"FPC soft board",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example4.png',
				list:[{
					name:"High quality board",
					text:"The core raw material board of the circuit board is made of A-grade military materials, with very guaranteed quality"
				},
				{
					name:"Exquisite printing process",
					text:"Adopting Guangxin photosensitive ink, in compliance with environmental standards, after high-temperature baking, the ink color is bright and bright, and the characters are clear"
				},
				{
					name:"Refined production process",
					text:"Multiple surface treatment processes ensure that all products meet industry standards for quality before leaving the factory"
				},
				{
					name:"Faster and more timely delivery",
					text:"With the help of the domestic express delivery network, we will deliver the samples to you in a timely manner within the promised time"
				}],
			},
			{
				name:"Rigid Flex",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example5.png',
				list:[{
					name:"High quality board",
					text:"The core raw material board of the circuit board is made of A-grade military materials, with very guaranteed quality"
				},
				{
					name:"Exquisite printing process",
					text:"Adopting Guangxin photosensitive ink, in compliance with environmental standards, after high-temperature baking, the ink color is bright and bright, and the characters are clear"
				},
				{
					name:"Refined production process",
					text:"Multiple surface treatment processes ensure that all products meet industry standards for quality before leaving the factory"
				},
				{
					name:"Faster and more timely delivery",
					text:"With the help of the domestic express delivery network, we will deliver the samples to you in a timely manner within the promised time"
				}],
			}],
			text4:"Display of some special board products",
			text5:"plant environment",
			text6:"Capability",
			text7:"Product display",
			textImage1:process.env.VUE_APP_OSS_URL + '/boardMaking/point1.png',
			textImage2:process.env.VUE_APP_OSS_URL + '/boardMaking/point2.png',
			textImage3:process.env.VUE_APP_OSS_URL + '/boardMaking/point3.png',
		},

		selectKoiios:{
			title:"Why choose Koiios",
			text1:"Serving countries and regions",
			text2:"global customers number",
			text3:"Monthly shipment volume",
			text4:"Daily process orders",
			text5:"Provide customers with one-stop services such as intelligent solution design, PCB manufacturing and processing, SMT device installation, product debugging, assembly, etc., to create a value chain system that is closely related to customers and has a consistent interest orientation.",
			text6:'It has four major brands including "PCB", "PCBWAY", "PCBGOGO", and "Koiios", and uses multilingual internet sites such as English, Japanese, and Spanish to layout globally.',
			button:"Learn more"
		},

	},

	pcbBatch:{
		bulkJuhui:{
			title1:"One price to the bottom • Large batch of benefits",
			text1:"Based on craftsmanship and quality, renowned for its speed and one-on-one customer service, we provide you with a higher quality product and service experience!",
			title2:"Double sided board · Guoji AB0 board special offer",
			img:process.env.VUE_APP_OSS_URL + '/pcbBatch/special-offer4@img.png',
			list:[{
				name:"Plate thickness: 0.8-1.6mm",
				type:false
			},
			{
				name:"Aperture:>0.3mm (12mil)",
				type:false
			},
			{
				name:"Postal package for Jiangsu, Zhejiang, Shanghai, Anhui, Jiangxi, Guangdong, and 6 provinces",
				type:true
			},
			{
				name:"Pore density: 80000/m ² within",
				type:false
			},
			{
				name:"Large quantities of demand can be inquired for",
				type:true
			},
			{
				name:"Line width and distance: 6/6mil",
				type:false
			}],
			content:"PS: Applied to low voltage and low current toy boards, ordinary CTI household appliance boards, and P4-LED light boards. Suitable for circuits with plug hole spacing greater than 1.20mm, children's toys with low current and low voltage requirements, household remote control devices, and other fast moving consumer products with lower requirements.",
			priceTitle:'20m ² rise',
			price:'248 yuan/m ² rise',
			singlePanel:{
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/special-offer5@img.png',
				title:'Single panel',
				label:['Plate · National A01','Plate thickness 1.0-1.6mm'],
				list:[{
					name:'Silk screen stamping die',
					type:false
				},
				{
					name:'OSP',
					type:false
				},
				{
					name:'Green Oil White Characters',
					type:false
				},
				{
					name:'Free of testing fees',
					type:true
				},
				{
					name:'100m ² Starting from the above',
					type:true
				}],
				price:'140 yuan/m ² rise'
			},
			nonFlameRetardant:{
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/offer3@img.png',
				title:'Non flame retardant two board',
				label:['Plate thickness: 0.6-1.6mm'],
				list:[{
					name:'Lead sprayed tin',
					type:false
				},
				{
					name:'Copper sinking process',
					type:false
				},
				{
					name:'Fully connected LDI',
					type:false
				},
				{
					name:'Full line VCP electroplating',
					type:true
				}],
				price1:'190 yuan/m ² rise',
				price2:'170 yuan/m ² rise'
			},
			fourLayerBoard:{
				title:"Four layer board",
				label:"High cost-effectiveness",
				list:[{
					name:"Plate thickness: 0.8-1.6mm",
					type:false
				},
				{
					name:"Aperture:>0.3mm (12mil)",
					type:false
				},
				{
					name:"1OZ",
					type:false
				},
				{
					name:"Pore density: within 80000",
					type:false
				},
				{
					name:"Soldering resistance: green oil white font",
					type:false
				},
				{
					name:"Line width and distance: 5/5mil",
					type:false
				},
				{
					name:"5m ² Starting from the above",
					type:true
				}],
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/offer2.png',
				content:'PS: Widely used in computer and peripheral devices, communication devices, consumer electronics, automotive electronics, and industrial applications.',
				price:'498 yuan/m ² rise'
			}
		},

		pcbBothHave:{
			title:"PCB • We have everything you want",
			text:"Multiple boards/processes, specialized in making difficulty boards that others cannot handle!",
			list:[{
				title:'PCB High Multi Layer Board',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want1@img.jpg',
				lable:[{
					name:'1-3 floors',
					type:true
				},
				{
					name:'Mixed pressure/pure pressure multi-layer board',
					type:false
				},
				{
					name:'High TG, CAF resistant board',
					type:false
				},
				{
					name:'Line width/spacing: 3mil',
					type:false
				}]
			},
			{
				title:'HDI blind burial/impedance control',
				type:true,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want2@img.jpg',
				lable:[{
					name:'High precision impedance control',
					type:false
				},
				{
					name:'Common 1-3 orders',
					type:false
				},
				{
					name:'Can do any order',
					type:true
				}]
			},
			{
				title:'Aluminum substrate/mixed pressure aluminum substrate',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want3@img.jpg',
				lable:[{
					name:'1-4 layers of pure pressure/mixed pressure aluminum substrate',
					type:false
				},
				{
					name:'Can be used as double-sided mixed pressing/sandwich aluminum substrate',
					type:false
				},
				{
					name:'Thermal conductivity system 1-4w (customizable 4-8w)',
					type:true
				},
				{
					name:'Thermoelectric separation>100w',
					type:false
				}]
			},
			{
				title:'Copper substrate/thermoelectric separation copper substrate',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want4@img.jpg',
				lable:[{
					name:'Single and double sided copper substrate',
					type:false
				},
				{
					name:'Can be used as double-sided mixed pressing/sandwich aluminum substrate',
					type:false
				},
				{
					name:'Thermoelectric separation copper substrate>380w',
					type:true
				}]
			},
			{
				title:'Rogers/ceramic substrate',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want5@img.jpg',
				lable:[{
					name:'Rogers full range of materials',
					type:false
				},
				{
					name:'Substrate',
					type:false
				},
				{
					name:'High frequency high-speed board',
					type:true
				},
				{
					name:'Pure pressure/mixed pressure',
					type:false
				}]
			},
			{
				title:'Other categories',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want6@img.jpg',
				lable:[{
					name:'Can be made into thick copper power board and step board',
					type:false
				},
				{
					name:'Countersunk hole, depth control groove/hole',
					type:false
				},
				{
					name:'High frequency high-speed board',
					type:true
				},
				{
					name:'Pure pressure/mixed pressure',
					type:true
				}]
			}]
		},

		chooseUs:{
			title:"Why did you choose us?",
			text:"No minimum order quantity, high cost-effectiveness, and fast advantages, as well as guaranteed product quality, are all your trustworthy reasons!",
			img:process.env.VUE_APP_OSS_URL + '/pcbBatch/why-choose1@enimg.png',
			content1:{
				title:"Price advantage",
				text1:"Price advantage: 1 order, 1 price, available at a fixed price/agreement price",
				text2:"Larger quantity and better price"
			},
			content2:{
				title:"Yield rate",
				text1:"All products of Koiios are above industry standards before leaving the factory",
				text2:"Four line testing ensures a product pass through rate of over 99%"
			},
			content3:{
				title:"Diversified settlement methods",
				text:"Multiple payment and settlement methods - there is always one that meets your needs!",
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/system-supply1@enimg.png',
			}
		},
		certificate:{
			title:"Quality certification system",
			text:"Koiios has obtained over 60 design invention patents, utility model patents, and software copyrights",
			label:['Specialized, refined, and innovative enterprises','High and new technology enterprises'],
		},
		aboutUs:{
			title:"Entering Koiios",
			text:"Koiios, trusted by 650000 customers worldwide, can ship urgently within 12 hours. No matter how urgent it is, it can always be on time"
		}
	},

	fpcBatch:{
		fpcHots:{
			title1:"FPC flexible circuit board",
			title2:"Rush to buy popular products",
			text:"24-hour rapid sampling and 72 hour bulk shipment",
			warp:{
				title:"Real time orders",
				gaugeOutfit:{
					text1:"User",
					text2:"Time",
					text3:"Order",
					text4:"Price",
				},
				text1:"Board size (cm)",
				text2:"Number of layers",
				text3:"number",
				text4:"thickness",
				placeholder1:"length",
				placeholder2:"width",
				placeholder3:"Select the number of layers",
				placeholder4:"Select quantity",
				placeholder5:"Select thickness",
				rightTitle:"hot-sale product",
				content1:{
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-product1.png',
					title:"FPC single and double sided board",
					text:"5x5cm/5 tablets, delivery time 3-4 days",
					label1:"Plate thickness 0.13, yellow film with white characters, without reinforcement",
					label2:"Copper thickness 0.5oz; Gold sinking technology;",
					label3:"Line width and distance ≥ 4mil",
					text2:"Hot price",
					text3:"original price",
					button:"BUY NOW"
				},
				content2:{
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-product2.png',
					title:"FPC four layer board",
					text:"5x5cm/5 tablets, delivery time 7-8 days",
					label1:"Plate thickness 0.23, yellow film with white characters, without reinforcement,",
					label2:"Copper thickness 0.5oz; Gold sinking technology;",
					label3:"Line width and distance ≥ 4mil",
					text2:"Hot price",
					text3:"original price",
					button:"BUY NOW"
				},

				content3:{
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-product3.png',
					title:"Four layer soft and hard combination board",
					text:"Delivery time 10-12 days, can be done (1-8 floors)",
					label1:"Ordinary process, yellow film with white characters, soft board thickness 0.13",
					label2:"Copper thickness 0.5oz; Gold sinking technology; The thickness of the hard board is 0.6~1.6;",
					label3:"Line width and distance ≥ 4mil",
					text2:"Hot price",
					text3:"Information quotation",
					button:"BUY NOW"
				}

			}
		},

		service:{
			title:"Products and Services",
			text:"Koiios is the first to launch CRM online management system, which applies big data, cloud computing and other intelligent technologies, network technologies and automated manufacturing technologies to the whole process of sales services, factory management, production manufacturing and quality control, completely subverting the traditional order management and production model",
			text2:"Product parameters",
			text3:"Plate thickness",
			text4:"Resistance welding",
			text5:"reinforce",
			text6:"copper thickness",
			text7:"technology",
			text8:"Line width and line spacing",
			text9:"Material structure",
			text10:"Product Assurance",
			tabs:[{
				name:'Single panel layer gold',
				content:{
					plateThickness:"0.1mm",
					ResistanceWelding:"Yellow film white character",
					reinforce:"No reinforcement",
					copperThickness:"1/2OZ",
					technology:"Gold sinking process",
					lineWidth:"≥3mil",
					structure:"Double sided adhesive+low loss yellow covering film+(line copper+adhesive+high-frequency dielectric polyimide substrate+adhesive+line copper)+low loss yellow covering film",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer1.jpg',
				}
			},
			{
				name:'Double sided sinking gold',
				content:{
					plateThickness:"0.13mm",
					ResistanceWelding:"Black film white character",
					reinforce:"No reinforcement",
					copperThickness:"0.5OZ",
					technology:"Gold sinking process",
					lineWidth:"≥3mil",
					structure:"Double sided adhesive+low loss yellow covering film+(line copper+adhesive+high-frequency dielectric polyimide substrate+adhesive+line copper)+low loss yellow covering film",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer2.jpg',
				}
			},
			{
				name:'Four layers of sinking gold',
				content:{
					plateThickness:"0.23mm",
					ResistanceWelding:"Green film white character",
					reinforce:"No reinforcement",
					copperThickness:"0.5OZ",
					technology:"Gold sinking process",
					lineWidth:"≥3mil",
					structure:"Double sided adhesive+low loss yellow covering film+(line copper+adhesive+high-frequency dielectric polyimide substrate+adhesive+line copper)+low loss yellow covering film",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer3.jpg',
				}
			},
			{
				name:'Six layer soft board sinking gold',
				content:{
					plateThickness:"0.23mm",
					ResistanceWelding:"Green film white character",
					reinforce:"No reinforcement",
					copperThickness:"0.5OZ",
					technology:"Gold sinking process",
					lineWidth:"≥4mil",
					structure:"Double sided adhesive+low loss yellow covering film+(line copper+adhesive+high-frequency dielectric polyimide substrate+adhesive+line copper)+low loss yellow covering film",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer4.jpg',
				}
			}],

		},

		precisionEquipment:{
			title:"Precision equipment production line",
			text1:"Having imported high-precision CNC lathes and manufacturing platforms, achieving PCB board production with less space, faster speed, and higher performance, creating a first-class production line, and ensuring high reliability product delivery",
			content1:"High precision drilling rig",
			content2:"Copper plating",
			content3:"Gongs edge machine",
			content4:"AOI scanner"
		},

		advantage:{
			title:'Our Advantages',
			button:"Customize Now",
			text:"More than 3000 daily shipments of products, serving over 200 countries and regions, and serving over 400000 users",
			content1:{
				title:"Impedance board",
				text1:"Can be expedited 24 hours for shipment",
				text2:"Normal delivery time 3-4 days"
			},
			content2:{
				title:"Price cheap",
				text1:"Single and double sided panel sampling starting from 300 yuan",
				text2:"Cable board 9.9 yuan, national package shipping"
			},
			content3:{
				title:"High quality board",
				text1:"Plate/covering film: Shengyi, Taihong, etc",
				text2:"Brand materials with guaranteed quality"
			},
			content4:{
				title:"Exquisite workmanship",
				text1:"Can be made into 8-layer boards and soft hard combination boards",
				text2:"Line width, line spacing ≥ 3mil, aperture ≥ 0.15mm"
			},
			content5:{
				title:"Wide product line",
				text1:"FPC multi-layer board, capable of combining soft and hard",
				text2:"Dozens of products such as flat cables"
			},
			content6:{
				title:"High passthrough rate",
				text1:"The product has a pass-through rate of over 98%",
			}
		}
	},

	aluminumSubstrate:{
		experience:{
			title:"10 years of experience in producing high conductivity metal substrates",
			content1:{
				title:"aluminum substrate",
				text:"Within 10x10cm",
				label1:"Plate thickness: 1.0-1.6mm",
				label2:"Thermal conductivity: 1w",
				label3:"Soldering resistance: white oil with black lettering",
				label4:"Process: conventional process, tin spraying",
				button:"customized"
			},
			content2:{
				title:"Special aluminum substrate",
				label1:"Can be used for 1-4 layers",
				label2:"Thermal conductivity: 1-3w (4-8w can be customized)",
				label3:"Mixed pressure aluminum substrate/sandwich aluminum substrate",
				label4:"Thermoelectric separation ＞ 100w",
				text:"rise",
				button:"customized"
			}
		},

		optionalSheet:{
			title:"Optional sheet/brand",
			button:"Immediate pricing",
			content1:{
				img:process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/example1.png',
				title:'Optional sheet/brand',
				name1:"brand",
				text1:"Beggs, Laird, Tenghui, Juding, Huazheng, Qingqing, Boyu, Guoji, etc",
				name2:"plate",
				text2:"1 series aluminum/3 series aluminum/5 series aluminum/6 series aluminum, etc"
			},
			content2:{
				img:process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/example2.png',
				title:'Aluminum substrate type',
				name1:"Conventional - Aluminum Substrate",
				text1:"Single/double sided aluminum substrate, multi-layer aluminum substrate",
				name2:"Special - aluminum substrate",
				text2:"Single layer/double layer single-sided mixed pressure aluminum substrate, double-sided sandwich aluminum substrate, thermoelectric separation aluminum substrate"
			},
			list: [{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img1.png',
					diList: ['Substrate material: aluminum base', 'Plate thickness/copper thickness: 2.0mm/70um', 'Thermal conductivity coefficient: 1.0-5.0W/mk', 'Surface treatment: HAL/OSP/HASL (LF)',
						'Breakdown voltage: 1.5-3.0KV (DC)'
					],
					title: 'Car light panel'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img2.png',
					diList: ['Substrate material: aluminum base', 'Plate thickness/copper thickness: 2.0mm/70um', '导热系数：1.0 – 3.0W/m.k', '表面处理：HAL/OSP/HASL(LF)',
						'Breakdown voltage: 1.0-3.0KV (DC)'
					],
					title: '灯板'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img3.png',
					diList: ['Substrate material: aluminum base', 'Plate thickness/copper thickness: 2.0mm/70um', 'Thermal conductivity coefficient: 1.0-5.0W/mk', 'Surface treatment: HAL/OSP/HASL (LF)',
						'Breakdown voltage: 1.0-3.0KV (DC)'
					],
					title: '电源板'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img4.png',
					diList: ['Substrate material: aluminum base', 'Plate thickness/copper thickness: 2.0mm/70um', 'Thermal conductivity coefficient: 1.0-5.0W/mk', 'Surface treatment: HAL/OSP/HASL (LF)',
						'Breakdown voltage: 1.0-3.0KV (DC)'
					],
					title: 'Mixed pressure aluminum substrate'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img5.png',
					diList: ['Substrate material: aluminum base', 'Plate thickness/copper thickness: 2.0mm/70um', 'Thermal conductivity coefficient: 1.0-5.0W/mk', 'Surface treatment: HAL/OSP/HASL (LF)',
						'Breakdown voltage: 1.0-3.0KV (DC)'
					],
					title: 'Double sided sandwich aluminum substrate'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img6.png',
					diList: ['Substrate material: aluminum base', 'Substrate material: aluminum base', 'Thermal conductivity coefficient: 1.0-5.0W/mk', 'Surface treatment: HAL/OSP/HASL (LF)',
						'Breakdown voltage: 1.0-3.0KV (DC)'
					],
					title: 'Panel lights'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img7.png',
					diList: ['Substrate material: FR-4', 'Process characteristics: High density P1.25- P2.5', 'Number of layers: 2-8 layers', 'Plate thickness/copper thickness: 1.2-2.0mm/35um',
						'Surface treatment: OSP/sinking gold, etc'
					],
					title: 'LED high-precision light panel (P1.25)'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img8.png',
					diList: ['Substrate material: FR-4', 'Process characteristics: High density', 'Number of layers: 2-8 layers', 'Plate thickness/copper thickness: 1.2-2.0mm/35um', 'Surface treatment: OSP/sinking gold, etc'],
					title: 'Transparent screen light panel'
				}
			]
		}
	},

	pcbaMade:{
		banner:{
			title:"SMT mounting",
			text:"SMT small and medium-sized batch+Layout+PCB making+SMT sampling+material procurement comprehensive service for medical, automotive, industrial, consumer goods, etc",
			text2:"Over 10 years of installation experience",
			label1:"No threshold",
			label2:"No miscellaneous fees",
			label3:"No extension",
			button:"place order",
			content1:{
				title:"No threshold",
				text:"One piece of adhesive, capable of pasting loose materials, and weldable inserts"
			},
			content2:{
				title:"No miscellaneous fees",
				text:"No engineering fees, no start-up fees"
			},
			content3:{
				title:"No extension",
				text:"One piece of adhesive, capable of pasting loose materials, and weldable inserts"
			},
			content4:{
				title:"ONE STOP SERVICE",
				text:"Purchase BOM materials on behalf, own PCB factory, and develop steel mesh on behalf of others"
			},

		},

		pcbaAssemble:{
			title:"PCBA assembly one-stop service to meet diverse customer needs",
			text:"Your electronic product research and development partner! Relying on more than 10 years of experience in PCB design, PCB manufacturing, SMT mounting, and plug-in post welding services, we provide one-stop PCBA manufacturing to provide more efficient, fast, and comprehensive value-added services for research and development",
			tabs: [{
					name: "PCBA patch mounting"
				},
				{
					name: "PCB sampling/small batch production"
				},
				{
					name: "BOM allocation/component"
				},
				{
					name: "Scheme design"
				}
			],
			rightTitle:"Quality System Certification and Product Services",
			content1:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img1.png',
				title:"Koiios PCBA rapid sampling, with a delivery time of 12 hours",
				text:"SMT sampling/batch production, with a minimum of one piece, no threshold, and full process. SMT mounting, post welding DIP plug-in, three proof paint, testing, assembly, and packaging are all one-stop solutions.",
				label1:"Urgent can be expedited in 8 hours, and normal delivery takes 2-3 days",
				label2:"Yamaha automatic SMT machine, SMT 4 million points/day",
				label3:"National three proofing paint automatic spraying line, high-performance eight temperature zone reflow soldering welding",
				label4:"Lead-free wave soldering, fully automatic printing machine, X-RAY detection and other equipment",
				text2:"rise",
				button:"Customize Now"
			},
			content2:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img2.png',
				title:"Own factory, PCB sampling can be shipped quickly in 8 hours",
				text:"The factory covers an area of 200000 square meters, with a monthly PCB production shipment of 80000 square meters and a daily shipment of over 3000 products. The raw materials and products are guaranteed to be of high quality.",
				label1:"PCB sampling 8/12/24 hours expedited, nationwide package shipping",
				label2:"Small batch shipment in 3-4 days",
				label3:"Jiantao and Shengyi A-grade boards meet UL and ROHS standards.",
				label4:"Customizable special process gold fingers, half holes, metallized edges, impedance control, countersunk holes, blind buried holes, resin plug holes, high-frequency and high-speed HDI, etc",
				text2:"rise",
				button:"Customize Now"
			},
			content3:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img3.png',
				title:"3-hour quotation, 6-hour shipment, available for bulk shipment",
				text:"Bulk shipments do not require ordering in small packages. Massive spot inventory, precise manual quotation, and high completion rate. Compared to similar shopping malls, it is 15% lower, and the original factory and agent purchase goods",
				label1:"50000+spot models, quick quotation, reply on the same day",
				label2:"Bulk shipment, no need to order in small packaging",
				label3:'Support BOM full order "one-stop" procurement service to solve your multiple purchase troubles',
				label4:"Support multiple types and small batch component supply services to solve your prototype trial production, new product development, and emergency replenishment issues for project orders",
				button:"BOM allocation"
			},
			content4:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img4.png',
				title:"A one-stop solution provider for electronic intelligent products",
				text:"Wireless communication, smart home, smart city, consumer electronics, industrial internet of things, AI human-computer interaction, providing customers with vertically integrated solutions for product research and development and scientific and technological innovation",
				label1:"Senior PCB design engineer with over 10 years of experience per person",
				label2:"Provide multi person parallel 7 * 24 hour service, quick delivery in one day",
				label3:"PCBA one-stop service, saving you time, worry, and effort",
				label4:"A precise one-on-one service model with dedicated data protection personnel",
				button:"Detail"
			},
			rightContent:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/certification-img.png',
				title1:"Quality system",
				text1:"ISO9001",
				title2:"Satisfaction rate",
				text2:"Over 99.7%",
				title3:"Number of global customers",
				text3:"Over 400000",
				title4:"Daily average shipment volume",
				text4:"Over 250 models",
				title5:"Koiios PCBA mounting capacity",
				text5:"SMT mounting, BGA mounting, mixed mounting, FPC mounting, etc. All products are above industry standards before leaving the factory"
			}
		},

		smtPatch:{
			img:process.env.VUE_APP_OSS_URL + '/manufacture/patch-weld-bg.jpg',
			title:"SMT patches/inserts and post welding",
			text:"Own factory, operated by multiple assembly lines, with an 8-hour rapid delivery time, 8 million SMT points/day, 500000 post soldering points/day for plugins, and stable production capacity",
			text2:"Like the PCB business, our PCBA service also focuses on prototype production and small batch production, as well as a one-stop service platform for Koiios circuit board manufacturing and SMT mounting. Make your research and development work easy and time-saving. Our professional engineers and technicians will work closely with you through our customer service personnel to ensure product quality and fast delivery times. Completing thousands of orders from around the world every day, we have established our position as a leading supplier of PCB and PCBA services with consistent quality and excellent service",
			content1:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/path-img1.png',
				title:"SMT patch",
				label1:"8 hours of rapid delivery, with 8 million patches per day, available for three proofing paint spraying, double-sided patches, and FPC patches",
				label2:"≥ 01005 and 0.2mmBGA",
				label3:"≤1200mm*400mm"
			},
			content2:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/path-img2.png',
				title:"DIP plug-in",
				label1:"500000 points/day after plug-in soldering",
				label2:"Own factory with multiple assembly lines for operation",
				label3:"Professional DIP plugin team welding various irregular plugin materials",
				label4:"8-hour lead time, stable production capacity"
			}
		},

		processCapability:{
			title:"SMT mounting processing capability",
			text:"Comprehensive one-stop service - you only need to provide a BOM list, and leave the rest to us!",
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/make-bg.png',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/ability-img.png',
			list:[{
				name:"Regular delivery time",
				text:["Regular sampling (within 50 pieces): 3 days for complete materials","Small batch (50-500pcs): 3-5 days","Batch size (≥ 500pcs): 5-7 days"]
			},
			{
				name:"PCBA welding type",
				text:["Surface mount (PCBA)","Plug back welding (THT)"]
			},
			{
				name:"Daily production capacity",
				text:["PCBA patch 4 million points/day","500000 points/day for post welding of plugins"]
			},
			{
				name:"Ball shaped parts (bga) spacing",
				text:["Not less than 0.25mm","Three proofing paint spraying"]
			},
			{
				name:"Plate thickness",
				text:["Not greater than 3mm (sample has no thickness limit)"]
			},
			{
				name:"Minimum order quantity",
				text:["1 set of stickers"]
			},
			{
				name:"Component Services",
				text:["Full set of materials, partial materials, and only OEM"]
			},
			{
				name:"PCB size for PCBA",
				text:["Small board size: 45mm x 45mm (less than this size requires splicing)","Plate size: 400mm x 1200mm"]
			},
			{
				name:"Types of PCB boards that can be attached",
				text:["PCB hard board (FR-4, metal substrate)","PCB soft board (FPC)","Soft and hard combination PCB","aluminum substrate"]
			},
			{
				name:"element size",
				text:["0.80mm (mixed pressure min0.30mm)"]
			},
			{
				name:"Maximum/minimum finished product size",
				text:["Passive component: mounting British 01005 (0.4mm * 0.2mm), 0201","BGA and other high-precision ICs: Support X-ray detection of BGA components with Min0.25mm spacing"]
			}]
		},

		userInformation:{
			title:"User Information",
			content1:{
				title:"The information you need to provide",
				text1:"BOM List",
				prompt:"（Mandatory）",
				text2:"A detailed and accurate BOM list, and any changes must be indicated on the BOM;",
				text3:"gerber files",
				text4:"If you need to open a splicing steel mesh, please provide the splicing document;",
				text5:"Component tag diagram and coordinate file;",
				text6:"PCB file:",
				text7:"If coordinate files, tag maps, and Gerber files cannot be exported by the customer, PCB files can also be provided;",
				text8:"Template:",
				text9:"For production reference, available if conditions permit."
			},
			content2:{
				title:"Daikai Steel Mesh",
				text1:"To ensure the quality of our customers' products, our company temporarily does not accept external steel mesh;",
				text2:"The cost of opening the steel mesh on behalf of others starts at 100 yuan; To open a spliced steel mesh, please provide the spliced document, and the cost will be re determined based on different points;",
				text3:"The default size of the steel mesh is 37CM * 47CM, and other sizes need to be quoted separately;",
				text4:"PCB file:",
				text5:"If the steel mesh needs to be returned, it must be confirmed at the time of placing the order. Without a special statement, it is considered that the steel mesh does not need to be returned;",
				text6:"Template:",
				text7:"The customer's steel mesh is only retained for 3 months, and after 3 months, our company will handle it on its own."
			},
			content3:{
				title:"Material requirements",
				text1:"Customers provide their own materials, and for materials that are prone to wear and tear (samples: provide an additional 15 samples for resistance and capacitance components, and 1% of the spare parts are required for bulk production), a small amount of spare parts must be provided;",
				text2:"If a small amount of materials are not fully prepared and there is no need for welding, please provide details;",
				text3:"If there are special requirements and precautions, please explain them in advance;",
				text4:"We do not accept external steel mesh for the time being, and do not accept payment upon delivery of components or samples;",
				text5:"For purchasing resistance and capacitance components on behalf of others, complete models (packaging, accuracy, volts, etc.) must be provided. The resistance and capacitance components provided are of the “Samsung” brand (if the customer wants to specify a brand, a special declaration must be made)",
			}
		}

	},

	steelMesh:{
		banner:{
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/laser-title.png',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/laser-sm-title.png',
			img3:process.env.VUE_APP_OSS_URL + '/manufacture/banner-li.png',
			img4:process.env.VUE_APP_OSS_URL + '/manufacture/specialty1.png',
			img5:process.env.VUE_APP_OSS_URL + '/manufacture/specialty2.png',
			img6:process.env.VUE_APP_OSS_URL + '/manufacture/specialty3.png',
			img7:process.env.VUE_APP_OSS_URL + '/manufacture/specialty4.png',
			text1:"Smooth mesh surface with high tension",
			text2:"High hardness and high temperature resistance",
			text3:"Same day delivery, maximum 4 hours",
			text4:"Smooth mesh surface with high tension"
		},

		hotSales:{
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/hot-stencil.png',
			leftContent:{
				text1:"SMT",
				text2:"37.0cm×47.0cm",
				label1:"Strong hardness",
				label2:"Wear resistance",
				label3:"No impurities",
				label4:"High temperature without deformation",
				button:"Special Purchase",
			},
			rightContent1:{
				text:"Every penny, every penny, every penny. Persist in improving quality",
				label1:"Adopting 304 stainless steel spring steel sheets imported from Japan;",
				label2:"High hardness, high temperature resistance, easy to clean;",
				label3:"Purchase high tension aluminum frames, AB resin adhesive;",
				label4:"The steel mesh has precise opening, with an accuracy of ± 20um;",
				img1:process.env.VUE_APP_OSS_URL + '/manufacture/stencil1.png',
				img2:process.env.VUE_APP_OSS_URL + '/manufacture/stencil2.png',
				img3:process.env.VUE_APP_OSS_URL + '/manufacture/stencil3.png',
				img4:process.env.VUE_APP_OSS_URL + '/manufacture/stencil4.png',
			},
			rightContent2:{
				text:"Thickened tube aluminum alloy frame",
				label1:"High temperature without deformation;",
				label2:"Wear resistance, high gloss, and no impurities;",
				img1:process.env.VUE_APP_OSS_URL + '/manufacture/alloy1.png',
				img2:process.env.VUE_APP_OSS_URL + '/manufacture/alloy2.png',
			}
		},

		smtFactory:{
			title:"Professional SMT sampling factory",
			text1:"Fast steel mesh production in the industry",
			text2:"Same day shipment",
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing1.png',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing2.png',
			img3:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing3.png',
			img4:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing4.png',
		},

		chooseUs:{
			title:"WHY CHOOSE US",
			list:[{
				title:'High quality production raw materials',
				content:'Made of 304 stainless steel imported from Japan, with high hardness, high temperature resistance, and easy cleaning.',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright1.png',
			},
			{
				title:'Professional service team',
				content:'A team with rich technical experience provides the most suitable solution for engineering based on customer documents, and customer service follows up on orders in real-time to help solve various problems in the orders.',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright2.png',
			},
			{
				title:'Advanced laser processing equipment',
				content:'The advanced laser cutting equipment in China can achieve an accuracy of ± 20um. The laser is made of IPG imported from Germany, with a cutting thickness of 0.03-0.3mm. Ensure delivery time while also providing stable quality.',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright3.png',
			},
			{
				title:'Strict quality control',
				content:'After the engineering process is completed, QC checks the documents and prints the wax paper. Production compares the wax paper with the documents, steel mesh cutting, and inspection is carried out before shipment to ensure consistent quality and ensure customer satisfaction.',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright4.png',
			},
			{
				title:'Fast logistics methods',
				content:"Yunda, Tiantian, Su'er, Lianhao Tong, SF Express and other express delivery methods are available for selection.",
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright5.png',
			},
			{
				title:'4 hour fast delivery',
				content:'Two laser machines work 24 hours a day, and shipment will be arranged before 17:00 on the day of placing the order, and the latest shipment will be the next day.',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright6.png',
			}]
		},

		equipmentDisplay:{
			title:"Equipment display",
			text1:"Specification/CM",
			text2:"Effective area of hand brush",
			text3:"Effective area of machine brush",
			tableList:[{
				text:["30cm * 40cm laser steel mesh","13cm*23cm","/"]
			},
			{
				text:["37cm * 47cm laser steel mesh","20cm*30cm","20cm*24cm"]
			},
			{
				text:["42cm * 52cm laser steel mesh","24cm*34cm","24cm*27cm"]
			},
			{
				text:["45cm * 55cm laser steel mesh","27cm*37cm","26cm*30cm"]
			},
			{
				text:["58cm.4 * 58.4cm laser steel mesh","38cm*38cm","38cm*38cm"]
			},
			{
				text:["55cm * 65cm laser steel mesh","37cm*48cm","35cm*38cm"]
			},
			{
				text:["73.6cm * 73.6cm laser steel mesh","50cm*50cm","40cm*50cm"]
			},
			{
				text:["40cm * 60cm laser steel mesh","22cm*40cm","22cm*36cm"]
			},
			{
				text:["40cm * 70cm laser steel mesh","22cm*50cm","22cm*46cm"]
			},
			{
				text:["40cm * 80cm laser steel mesh","22cm*60cm","22cm*56cm"]
			},
			{
				text:["40cm * 100cm laser steel mesh","22cm*76cm","22cm*72cm"]
			},
			{
				text:["40cm * 120cm laser steel mesh","22cm*90cm","22cm*92cm"]
			},
			{
				text:["40cm * 140cm laser steel mesh","22cm*110cm","22cm*110cm"]
			},
			{
				text:["40cm * 150cm laser steel mesh","22cm*120cm","22cm*120cm"]
			},
			{
				text:["4 Production method","Top layer: Close the top and bottom layers together","Bottom layer: one for each of the top and bottom layers"]
			},
			{
				text:["Benchmark","Half a quarter","through-hole"]
			}]
		},

		userInformation:{
			title:"User Information",
			text1:"Thickness of rigid mesh:",
			text2:"Our company's steel mesh thickness includes: 0.08mm/0.1mm/0.12mm/0.15mm/0.2mm/0.3mm. The steel mesh thickness should meet the minimum spacing QFP and BGA, while also considering the minimum CHIP component. Please refer to the attached table for details:",
			tableGaugeOutfit:{
				text1:"Component type",
				text2:"Spacing",
				text3:"Stencil thickness",
			},
			text3:"If there are special requirements, please specify that production can be arranged according to the specified thickness.",
			text4:"Information required by the customer:",
			text5:"Either of the following is sufficient:",
			text6:"Please make sure to confirm that the provided file/PCB board is correct.",
			labelList:["GERBER file","PCB files","CAD files","GBR files","CAM files","DXF file or PCB physical blank board"],
		}
	},

	bomSupply:{
		swperList:{
			title:"One stop ordering service",
			text1:"Six major advantages/no tiered pricing/detachable retail sales/samples shipped at batch prices, etc",
			text2:"Support the supply of scarce, discontinued, discontinued, cold door, and controlled components to solve your material shortage problems. Support value-added services before and after sales, and serve as a personal consultant to solve various difficult problems in your work",
			text3:"Support remaining electronic material consignment and clearance services, help you reduce asset losses, support long-term supply and stocking services, and solve your concerns about stable supply of commonly used models",
			list: [{
					title: "One stop ordering service",
					text: "Support BOM full order “one-stop” procurement service to solve your multi-point purchasing troubles",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon1.png',
					content: "Simplify the complex inquiry processing process, just like equipping you with a professional procurement team; The material matching rate can achieve 98%, reducing inquiry costs; Operate 24 hours a day without interruption to ensure efficient demand feedback."
				},
				{
					title: "High product completion rate",
					text: "Support supply services for scarce, discontinued, out of gear, cold door, and controlled components to address your material shortage concerns",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon2.png',
					content: "There are almost no minimum order quantity and minimum order amount restrictions; Effectively providing cost control budgets for the research and development phase; Assist in investigating the market supply situation of various materials in subsequent mass production, and provide guarantees for stable production."
				},
				{
					title: "Multiple types of solutions",
					text: "Support for remaining electronic material consignment and clearance services, helping you reduce asset losses",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon3.png',
					content: "A large number of potential customer demands, improving the liquidation rate of dead materials, and activating the company's working capital; Quickly, simply, and efficiently solve remaining inventory pressure; Reduce inventory management labor costs and storage space, and control the risk of multiple inventory losses."
				},
				{
					title: "Pre sales and post sales",
					text: "Support value-added services before and after sales, serving as a personal advisor to solve various difficult problems in your work",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon4.png',
					content: "Provide technical support, search for document datasheets and materials; Assist in recommending alternative solutions for discontinued or discontinued devices; Provide samples as appropriate."
				},
				{
					title: "Material consignment",
					text: "Support for remaining electronic material consignment and clearance services, helping you reduce asset losses",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon5.png',
					content: "A large number of potential customer demands, improving the liquidation rate of dead materials, and activating the company's working capital; Quickly, simply, and efficiently solve remaining inventory pressure; Reduce inventory management labor costs and storage space, and control the risk of multiple inventory losses."
				}
			]
		},

		recommendation:{
			title:"Domestic recommendation",
			text1:"Koiios combines big data and artificial intelligence technology to provide electronic product BOM cost accounting, risk control, alternative material recommendation, and complete BOM component trade and other services.",
			text2:"The company has its own SMT factory and more than 30000 small and medium-sized terminal manufacturers. Through the intelligent connection between the large database of research and development procurement and the domestic electronic component database, the demand is met.",
			text3:"Koiios's self operated spot warehouse",
			text4:"SMT factory under Koiios",
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/stock.jpg',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/industrialAutomation.png',
			img3:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img1.png',
			img4:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img2.png',
			img5:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img3.png',
			img6:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img4.png',
			text5:"Recommended alternative materials",
			text6:"costing account",
			text7:"costing account",
			text8:"Risk warning"
		}
	},

	solution:{
		schemeDesign:{
			solutionProvider:{
				title:"One stop solution provider for electronic intelligent products",
				text:"Koiios is a product development engineer with over 10 years of experience, providing multi person parallel 7 * 24 hour rapid service with one day delivery",
				list:[{
					img1:'img1',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index1.png',
					name:"方案",
					title:"hardware development",
					textList:["Embedded Machine Design Scheme","Single board and customized solutions","Standardized Core Board Module"],
				},
				{
					img1:'img2',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index2.png',
					name:"方案",
					title:"Software Development",
					textList:["BSP board integration development","vI/O Processing","Linux/Android/WinCE customization"],
				},
				{
					img1:'img3',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index3.png',
					name:"方案",
					title:"Cloud Service Development",
					textList:["Multiple database and storage permission management","Realize complete IoT integration"],
				},
				{
					img1:'img4',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index4.png',
					name:"方案",
					title:"industrial design",
					textList:["Product (ID) Appearance Design","Product (MD) structural design","Palm production"],
				}]
			},

			serviceArea:{
				title:"Safe and stable product and service areas",
				tabsList: [{
						name: 'wireless',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon1.png',
					},
					{
						name: 'Smart Home',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon2.png',
					},
					{
						name: 'Smart city',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon3.png',
					},
					{
						name: 'Consumer Electronics',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon4.png',
					},
					{
						name: 'Internet',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon5.png',
					},
					{
						name: 'AI interaction',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon6.png',
					}
				],
				dataList:[{
					id:0,
					title:"What is the developmlent process for embedded systems?",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications1.jpg',
					content:"What is the development process for embedded systems? Next, let me give you a detailed introduction! Embedded system development process includes software development process and hardware development process Software development process: 1. Demand research and analysis 1. Systems analyst and users initially understand the requirements, confirm the developed functional modules, and list the major functional modules of the system to be developed, and what are the small functional modules under each major functional module; For those with clear requirements, a small number of interfaces can be preliminarily defined. 2. The Systems analyst deeply understands and analyzes the requirements, and prepares a Functional requirement document. This document clearly outlines the main functional modules of the system and the small functional modules under the main functional modules, as well as the relevant interfaces and interface functions. 3. The Systems analyst confirms the requirements with the user again."
				},
				{
					id:0,
					title:"One article provides you with a detailed explanation of industrial design",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications2.jpg',
					content:"Many people are confused about the term “industrial design” and do not know the difference between industrial design and ordinary design. Industrial design, also known as industrial product design, involves psychology, sociology, aesthetics, ergonomics, mechanical construction, photography, color science, and more. The industrial design brought about by industrial development and division of labor is significantly different from other arts, production activities, craft production, etc. It is a product of the intersection of various disciplines, technologies, and aesthetic concepts."
				},
				{
					id:0,
					title:"What basic skills should embedded hardware engineers possess?",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications3.jpg',
					content:"Design is the first step in product production, and those hardware engineers are both product designers and quality gatekeepers, playing a decisive role in the quality and performance of the product. So, what basic skills should embedded hardware engineers possess? 1、 Basic skills that hardware engineers should possess: 1. Ability to plan and create requirements analysis, overall plan, and detailed design; 2. Proficient in using design tools, proficient in designing schematic diagrams and PCB boards; 3. Proficient in using microcontroller, ARM, DSP, PLD, FPGA, etc. for software and hardware development and debugging; 4. Proficient in using simulation tools, oscilloscopes, signal generators, and other debugging hardware skills; 5. Master the design ability of commonly used standard circuits, such as reset circuits, commonly used filter circuits, matching circuits for high-speed signal transmission lines, etc;"
				},
				{
					id:0,
					title:"What principles should be followed when applying for electronic product patents?",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications4.jpg',
					content:"We all know that when electronic products are developed, they usually apply for various patents to prevent others from infringing. In China, the China National Intellectual Property Administration is responsible for managing patent work nationwide, uniformly accepting and reviewing patent applications, and granting patent rights according to law. According to the different types of patent applications, the Patent Law sets different requirements for the application documents that should be submitted; At the same time, different examination systems have been adopted for different types of patents. For invention patent applications, an early public delayed examination system is adopted, while for utility model and design patent applications, a formal examination system is adopted. So, what principles should electronic product patent applications follow?"
				},
				{
					id:0,
					title:"How to understand the 'derating' in the reliability design of electronic products?",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications5.jpg',
					content:"The reliability design of electronic products means that under the premise of not increasing or slightly increasing the cost, unexpected Product defect can be avoided, preset reliability requirements can be met, the time of failure can be reduced, the use cost of product life cycle can be reduced, and safety accidents can be more prevented. In the design phase of electronic products, avoiding device functional application defects, testing, and derating are the three basic means to improve reliability. So, how to understand the 'derating' in the reliability design of electronic products?"
				},
				{
					id:0,
					title:"What are the customization processes for smart electronic products in Shenzhen?",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications6.jpg',
					content:"Koiios is a leading 'electronic intelligent hardware manufacturing platform' in China, with multiple mature development solutions for intelligent electronic products. Next, let's introduce: What are the customization processes for electronic intelligent products in Shenzhen? 1、 Overall design 1. Requirements analysis: R&D engineers will understand and determine customer needs, such as determining product styles, functions, specifications, parameters, quantities, etc. 2. Plan determination: The R&D engineer determines the best way to solve the needs. In addition to considering the performance of the chip, economic factors and practical value should also be considered."
				},
				{
					id:0,
					title:"A Scheme for Detecting Transmitters Using Infrared Temperature Sensors",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications7.jpg',
					content:"During the operation of high-voltage transmission lines, infrared temperature sensors are usually used for detection to ensure a better understanding of the operating status of high-voltage transmission lines. Today, let the engineer explain to you the scheme of infrared temperature sensor detection transmitter: 1. The working principle of infrared temperature sensor: in nature, all objects whose temperature is higher than Absolute zero (-273 ℃) will continuously radiate infrared energy in the form of electromagnetic waves. The optical system in the infrared temperature sensor can collect the infrared radiation energy emitted by the measured target, and then concentrate it on the photosensitive surface of the photodetector to convert it into corresponding electrical signals; After the signal is processed by the amplifier, it is corrected according to the algorithm developed by the instrument and the target Emissivity, and converted into the temperature value of the measured target."
				},
				{
					id:1,
					title:"What factors should be considered when choosing a Shenzhen mini program development company?",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome1.jpg',
					content:"Nowadays, small program development companies are mixed, and they may accidentally find unqualified companies to collaborate with, wasting their time and energy. So, what factors should be considered when choosing a Shenzhen mini program development company? 1. Although the duration of a company's establishment cannot be a key factor, and it cannot be denied that young companies may have a higher level of expertise than companies that have been established for a long time, it can still be helpful in terms of credibility, after all, experience can only be achieved through the accumulation of time."
				},
				{
					id:1,
					title:"What are the functions of developing beauty mini programs?",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome2.jpg',
					content:"The beauty mini program is a small program that uses mobile clients to help customers provide beauty information push, service reservation, and other related services. The beauty mini program will also develop with online service platforms and offline stores, completely transforming the operation and management mode of the beauty industry into the O2O model. So, what are the functions of developing beauty mini programs? 1. The online reservation service supports users to make online appointments for beauty services, allowing users to make flexible reservations and reducing the possibility of wasting time waiting for services."
				},
				{
					id:1,
					title:"What are the basic functions of developing an intelligent light control app?",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome3.jpg',
					content:"With the development of mobile internet technology, various intelligent light control apps have sprung up like mushrooms after rain. The intelligent light control app mainly installs LED driver power and Bluetooth module/Wifi module/ZigBee module into the light bulb, and uses mobile software and scene controller to control the light switch and different dimming needs, meeting the diverse lighting needs of users! So, what are the basic functions of developing an intelligent light control app?"
				},
				{
					id:1,
					title:"What are the basic elements that software developers should possess?",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome4.jpg',
					content:"Nowadays, software development is a popular profession. So, what are the basic elements that software developers should possess? 1、 Software developers with educational qualifications are more likely to find corresponding jobs, and most companies have such requirements in their job requirements. If you work in a region where software developers are concentrated, having a master's degree can give you an advantage in the competition. 2、 Continuation of extracurricular learning and further education"
				},
				{
					id:1,
					title:"What are the functions of the IoT microcontroller for electric irons?",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome5.jpg',
					content:"Electric irons are commonly used household appliances. If intelligent technology is given to electric irons, this device becomes an IoT microcontroller for electric irons. So, what are the functions of the IoT microcontroller for electric irons? 1、 The intelligent control device used in this electric iron is programmed with an 8-bit microcontroller, which has the A.S.O protection function. It can provide timed power outage protection according to the placement status of the iron, avoiding human errors that can cause the heating wire to heat for a long time. It provides better protection against clothes being damaged by the iron, thus preventing the occurrence of fire incidents."
				},
				{
					id:1,
					title:"What are the basic principles and ideas for the development of smart home products?",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome6.jpg',
					content:"What are the basic principles and ideas for the development of smart home products? Let's take a look together: 1. Fully understand the design needs of all parties and determine the appropriate solution. Starting a product development project, the original driving force will come from many aspects, such as market needs, based on the needs of the entire system architecture, the functional implementation needs of the application software department, and the need to improve certain aspects of the system's capabilities. Therefore, as a hardware system designer, it is necessary to proactively understand the needs of various aspects and integrate them to propose the most suitable hardware solution."
				},
				{
					id:1,
					title:"Raise the Mysterious Veil of Smart Home APP Development",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome7.jpg',
					content:"With the continuous development of technology, many smart devices are constantly emerging, and smart homes are one of the representatives; People can achieve a new 'people-oriented' home life experience by developing smart home apps. Below, let engineers lift the mysterious veil of smart home app development for you: 1. Characteristics of smart home app development: 1. Practicality: With the continuous development of the Internet, people's demand for smart homes is also increasing. Starting from the actual needs of users, we can try our best to meet their needs for smart home system operation, which is more intelligent and humanized, and has strong practicality, Bringing certain convenience to people's lives."
				},
				{
					id:2,
					title:"Beverage Machine Solutions",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity1.jpg',
					content:"New retail is a stage of development and change in the retail industry in the process of realizing social informatization and digitization through the Internet. It is a data-driven pan retail form centered on consumer experience, which can achieve comprehensive connectivity of membership, payment, inventory, services, and other aspects of data. The beverage machine adopts IoT+platform+patented brewing technology"
				},
				{
					id:2,
					title:"Clean House Overall Solution",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity2.jpg',
					content:"1、 The project background clean cabin (tentative name) provides 1.5 square meters of personal safety and private space in public places, where users can work, rest, and dine. After preliminary demand discussion and on-site project research, based on the problems encountered in the production and application of the first generation product, requirements have been put forward for the second generation product in terms of sealing design, airflow stability, and structural aesthetics."
				},
				{
					id:2,
					title:"What are the advantages and disadvantages of mini program applications?",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity3.jpg',
					content:"With the rapid development of mobile internet, apps have improved users' living habits and service efficiency. However, some mobile apps have low usage rates, occupy phone memory, waste a lot of resources, and have traffic requirements. So, mini programs emerged. So, what are the advantages and disadvantages of mini program applications? 1. The advantages of small program applications: (1) Small programs are lightweight applications with small size and multiple functions; It has the advantages of convenience, convenience, Controllability and strong interaction."
				},
				{
					id:2,
					title:"What is the difference between PC end products and mobile end products?",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity4.jpg',
					content:"With the popularization of mobile Internet, the number of people and traffic on the mobile end have exceeded that of the PC end. In the mobile The Internet Age, everyone chooses the mobile end as a breakthrough. In order to occupy a certain market on the mobile end, traditional PC end products also need to expand the mobile end. So, what is the difference between PC end products and mobile end products? Let's analyze from the following three aspects: 1. Product attributes. Some products or services are only suitable for the PC side, such as complex operating systems that require large computations; And some content is born for mobility, such as WeChat IM communication software."
				},
				{
					id:2,
					title:"What is a clear and reasonable development process for an APP project?",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity5.jpg',
					content:"A mobile app project can have a large or small research and development scale, but it cannot be separated from product managers, ui designers, front-end development, back-end development, testing, and other tasks. So, what is a clear and reasonable development process for an APP project? 1、 Demand stage 1. Demand discussion: the product manager communicates with the customer, including: development type, platform to be developed, specific product Functional requirement, specific product design requirements, expected completion time of the project, development budget, etc; Through repeated research, discussion, and output of interaction plans."
				},
				{
					id:2,
					title:"What are the common development models for sports and fitness apps?",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity6.jpg',
					content:"Nowadays, various sports and fitness apps are emerging one after another, deeply loved by young people. So, what are the common development models for sports and fitness apps? Let's take a look together: Mode 1: Video based exercise and fitness apps. The basic logic of this type of app is to start with high-quality video course content, and ensure the effectiveness of user weight loss and shaping through the professionalism of the tutorial content. Mode 2: Community based sports and fitness apps can be integrated into any mobile app product in the community, and their social attributes cannot be ignored. Hot fitness and Fittime are clear examples of this trend."
				},
				{
					id:2,
					title:"What are the principles of intelligent product design?",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity7.jpg',
					content:"Designers have unique thinking paths and principles that must be followed when designing a smart product that meets their needs. So what are the principles of intelligent product design? 1. The core principle first requires customers to provide a series of requirements for product design at the beginning of design, and we need to analyze these requirements. All designs are designed to meet the needs of users, whether it is spiritual or material requirements, which remains unchanged and is also the core principle of product design."
				},
				{
					id:2,
					title:"What technologies need to be learned for Big data development?",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity8.jpg',
					content:"Big data, as a hot word at the moment, the following data development, data warehouse, data security, data analysis, data mining and other business uses around Big data have gradually become the profit focus that industry professionals are chasing. With the advent of the Big data era, Big data development has also emerged. So, what technologies need to be learned for Big data development? The learning roadmap is as follows: Phase I: Big data Fundamentals - Java Language Fundamentals (1) Java Language Fundamentals include Java development introduction, familiarity with Eclipse development tools, Java Language Fundamentals, Java process control, Java strings, Java arrays and classes and objects, digital processing classes and core technologies, I/O and reflection, multithreading, Swing programs and collection classes, etc"
				},
				{
					id:3,
					title:"What is the significance and role of developing medical mini programs?",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics1.jpg',
					content:"Medical mini programs can solve the shortage of medical professionals in China and provide convenient services to the public, showcasing our professional medical information to the general public. So, what is the significance and role of developing medical mini programs? 1. The significance of developing medical mini programs (1) is to assist enterprises in transmitting patient appointment information to medical institutions through mini programs, which can enable institutions to allocate medical resources in advance and arrange medical services reasonably; Through the online consultation function of the mini program, medical institutions can remotely monitor patient conditions and improve service quality. For medical product sellers, increase customer acquisition channels through mini programs."
				},
				{
					id:3,
					title:"What are the main functions of developing medical mini programs?",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics2.jpg',
					content:"Medical mini programs mainly help users provide medical and health services for mobile terminal systems, or help medical and health institutions provide high-quality service platforms and high-value information display platforms, so that medical resources can be used in an orderly and reasonable manner. So, what are the main functions of developing medical mini programs? 1. Health testing users test their own health status through mobile software, and then register for medical treatment or targeted physical conditioning based on the test results."
				},
				{
					id:3,
					title:"What are the functions of the intelligent Electric toothbrush?",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics3.jpg',
					content:"Intelligent Electric toothbrush is a new intelligent product emerging in recent years. The survey shows that Electric toothbrush is more scientific and effective than ordinary toothbrush, and can reduce gingivitis and gingival bleeding; Through rapid rotation, the brush head produces high-frequency vibration, instantly decomposes the toothpaste into fine foam, and thoroughly cleans the gap between teeth; At the same time, the shaking of the brush promotes oral blood circulation, which is beneficial for oral health. Smart toothbrush is a new type of high-tech toothbrush that analyzes users' brushing habits, records tooth information, and displays data to smartphones in mobile applications."
				},
				{
					id:3,
					title:"What are the impacts of mobile intelligent hardware on daily life?",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics4.jpg',
					content:"With the development of science and technology, intelligent hardware combines software and hardware to make traditional devices more intelligent. Intelligent hardware can make people's lives more convenient and healthy. So, what are the impacts of mobile intelligent hardware on daily life? 1. Intelligent Health: By using intelligent hardware devices such as smartwatches and intelligent air quality detectors to record data, people's lives can become healthier. 2. Intelligent sports: Stimulate people's Motor nerve and make people love sports through intelligent sports equipment such as intelligent bracelets and intelligent badminton monitoring."
				},
				{
					id:3,
					title:"To explain the intelligent bracelet solution for you in detail",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics5.jpg',
					content:"A smart wristband is a watch product with a built-in intelligent operating system and multiple functions achieved by connecting to the network. It can generally synchronize phone calls, text messages, emails, photos, music, and more. Next, let experts explain the intelligent bracelet solution for you in detail. Bracelet products were born earlier than watch products, but due to their early practicality, watches were widely used, and bracelets have always been in an optional state; With the development of Internet of Things technology, watch products have been updated and replaced, with the emergence of smart watches. Bracelets have become a vertical field in the form chain of smart watches, and smart bracelets have emerged."
				},
				{
					id:3,
					title:"How to create a creative product appearance design?",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics6.jpg',
					content:"Whether a product can stimulate people's purchasing desire, win people's likes and social praise, and the appearance design is crucial. So, how to create a creative product appearance design? 1. A survey report on the market information of competitive products, stating that 'troops and horses have not moved, and grain and grass have taken the lead'. Through careful market research, we can understand consumers' opinions and requirements on product variety, specifications, quality, performance, and price, understand what kind of product appearance, color matching, etc. consumers tend to prefer, grasp the market demand and sales trends for a certain product, grasp the market share of the product and the market share of competitive enterprises, and understand the changes in purchasing power and available supply of social goods."
				},
				{
					id:3,
					title:"What information does medical product design need to clarify?",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics7.jpg',
					content:"What information does medical product design need to clarify? Usually, when designers communicate with clients, they receive scattered and fragmented information that needs to be further organized and analyzed to extract concise and clear requirements. These requirements include: 1. Collection and organization of basic information. What is the positioning of the device? For example, using electronic technology, digital signal processing technology, and image processing technology, using the principle of ultrasound Doppler, combined with medical clinical practice, carefully designed equipment for diagnosing certain diseases."
				},
				{
					id:3,
					title:"Which types of Consumer electronics need PCB?",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics8.jpg',
					content:"Basically, all electronic products require the use of PCB boards, but not every electrical appliance requires a circuit board. Simple appliances may not require a circuit, such as electric motors. So, which types of Consumer electronics need to use PCB? 1. Consumer electronics that require integrated circuits: These electronics are designed to save space and make products lighter and more compact; Durable and to achieve good performance, PCB boards can meet the requirements of space, performance, and reliability for these products. 2. Main board for household appliances: digital TV amplifier, mobile phone, washing machine, electronic scale, telephone, LED light, air conditioning, refrigerator, stereo, MP3, etc."
				},
				{
					id:4,
					title:"What are the advantages of enterprise software development customization?",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet1.jpg',
					content:"With the increasing demand for information technology in enterprises, the pace of enterprise information construction is gradually accelerating. Some advanced enterprises have begun to implement enterprise information management, hoping to improve their work efficiency and enhance their comprehensive competitiveness in the market through the implementation of information technology. The implementation of enterprise information management cannot be separated from software development customization. So, what are the advantages of enterprise software development customization? 1. The information system implemented by enterprises with strong pertinence and adaptability is customized by specialized software companies, fully leveraging the advantages of enterprise personalization. Therefore, the customized software has strong operability and application specificity."
				},
				{
					id:4,
					title:"What are the challenges faced by cloud service application development?",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet2.jpg',
					content:"Cloud services have developed rapidly over the years and have gradually gained recognition in enterprises. So, what are the challenges faced by cloud service application development? 1. Private and secure cloud architectures do not automatically grant security compliance to end user data or applications on them, so applications written for cloud computing must themselves comply with relevant security provisions. Although cloud providers have this responsibility, the most important consideration is still within the scope of application designers."
				},
				{
					id:4,
					title:"Five steps to monitor Microservices",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet3.jpg',
					content:"Microservices is a business centric unit that can be deployed independently. Not all deployment artifacts are separate services. For Microservices, we want to treat them as independent services, but we also need to evaluate their interrelationship. Next, let's take a look at the five steps that need to be executed to monitor Microservices. 1. Confirm that only a few services need to be started"
				},
				{
					id:4,
					title:"How to do a good job in industrial product design?",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet4.jpg',
					content:"Completing an industrial product design relies on work experience, accumulated technology, and timely updating of product knowledge. So, how to do a good job in industrial product design? Let's take a look together: 1. Definition of Industrial Product Design 1. Industrial Product Design is a marginal discipline of Industrial Design Engineering, whose main purpose is to help engineers design more reasonable product appearance designs."
				},
				{
					id:4,
					title:"What are the design and development processes for medical device products?",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet5.jpg',
					content:"The design and development of medical device products have strict quality control requirements, as this directly affects the efficiency and quality of product design and development. So, what are the design and development processes for medical device products? 1. The confirmation and approval of a project usually require the design company to issue a project task letter after receiving the customer's product design requirements, officially initiate the project, and proceed with the design and development work."
				},
				{
					id:4,
					title:"What are cloud services and cloud server development?",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet6.jpg',
					content:"When it comes to cloud services, we immediately think of Alibaba Cloud, Baidu Cloud, Tencent Cloud, etc. So, what are cloud services and cloud server development? Cloud service is a simple, efficient, secure and reliable computing service with Autoscaling processing capacity. It is one of the important services of cloud computing services, and has a wide range of uses. The implementation of cloud services involves customers accessing the server deployed on the cloud through the internet."
				},
				{
					id:4,
					title:"What should be noted in industrial product design?",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet7.jpg',
					content:"Completing an industrial product design relies on work experience, accumulated technology, and timely updating of product knowledge. So, what should be paid attention to in industrial product design? 1、 Analysis before product design: 1. Clarify audience goals. Designers are the bridge between manufacturers and consumers. They should not only ensure consumers' requirements for product performance or aesthetics, but also achieve economic benefits to make businesses profitable. Therefore, before designing, it is important to understand the audience's goals;"
				},
				{
					id:4,
					title:"Engineer's Explanation: 8 Core Technologies of Cloud Computing",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet8.jpg',
					content:"Cloud computing is an intensive computing model centered on data and processing capabilities, which integrates multiple ICT technologies; Among them, eight key technologies are virtualization technology, distributed data storage technology, programming models, large-scale data management technology, distributed resource management, information security, cloud computing platform management technology, and green energy-saving technology. Today, engineers will explain in detail the 8 core technologies of cloud computing: 1. Virtualization technology. Virtualization is one of the most important core technologies of cloud computing, providing infrastructure support for cloud computing services and the main driving force for ICT services to quickly move towards cloud computing."
				},
				{
					id:5,
					title:"What are the main steps in cloud server development?",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI1.jpg',
					content:"With the development of cloud computing, cloud servers have become the preferred choice for enterprise users to choose servers. Migrating businesses to cloud servers can reduce costs, simplify workflow, and eliminate the need for IT hardware. So, what are the main steps in cloud server development? 1、 After the cloud server receives the collected data and establishes the cloud server, the next step is how to transfer the collected data to the cloud server. This involves two tasks: one is to establish a receive processing program on the cloud server, which comes in various forms as long as it can provide a connection to receive data. The following methods can usually be used:"
				},
				{
					id:5,
					title:"Why does the retail industry need to utilize edge computing?",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI2.jpg',
					content:"Edge computing is a computing process conducted at the same location or the location closest to the data source. Retailers believe that this technology can optimize and bring value to customers and their businesses. Why does the retail industry need to utilize edge computing? 1. Improving operational efficiency. Traditional retail enterprises use specialized applications as solutions for retail management (such as inventory management, pricing, and scanning), which cannot keep up with the dynamic nature of the retail industry. With edge computing, retailers can maximize the use of IoT devices and their massive data that can be transmitted in real time."
				},
				{
					id:5,
					title:"What are the criteria for selecting cloud servers?",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI3.jpg',
					content:"With the rapid development of the internet, cloud servers have become one of the most widely deployed and applied products in IT projects. So, what are the criteria for selecting cloud servers? 1. The credit and qualifications of cloud service providers are closely related to the website servers, whether a website operates for a long time. So when purchasing a cloud host, the first thing to consider is the credit and qualifications of the service provider, which can greatly protect one's own interests. 2. Stability and speed testing experience. As customer visits and services change, servers need to continuously increase hardware resources to improve service levels and capabilities. Therefore, before renting a server, it is necessary to try it out and make sure there are no issues before purchasing."
				},
				{
					id:5,
					title:"What are the problems and solutions in mini program development?",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI4.jpg',
					content:"As an auxiliary service account, mini programs can help businesses or brands increase their business volume. So, what are the problems and solutions for small program development? 1. The first challenge encountered in developing small programs is to determine the requirements: does the enterprise need small programs, and what type of small programs do they need? When developing mini programs, the brand should consider their positioning and requirements clearly. Applets are more suitable for brands that need to be closely integrated online and offline, and more suitable for brands that have high frequency, run out of time, and Functional requirement that are not very complex, such as Mobike, McDonald's, KFC, and Toutiao today."
				},
				{
					id:5,
					title:"Why does the traditional hardware industry need to develop intelligent apps?",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI5.jpg',
					content:"The development of smart hardware apps is a hub for connecting smart hardware and users. When a company develops a smart hardware, it needs to display its functions to users through a mobile client, which is called a smart hardware app. So, why does the traditional hardware industry need to develop intelligent apps? 1. People have that demand: with the rise of people's living standards and the development of science and technology, it has caused a significant impact on traditional hardware. The development of science has made people's laziness increasingly strong, and they no longer want to put in effort to solve things that can be solved with their phones. For people, intelligent hardware has liberated their hands and made life more convenient."
				},
				{
					id:5,
					title:"What is the design and development process for the Shenzhen app?",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI6.jpg',
					content:"With the popularization of smartphones, the development of mobile application apps has become increasingly popular. So, what is the process of app design and development? Let's introduce it below. 1、 The development engineer will determine the customer's demand for app development, position the app, refine the overall situation of the project, and ensure that the app can be completed within the specified time limit. 2、 It is a good development to determine the product prototype diagram. If the customer has an approximate Business Model Canvas, it will be easy to make the product prototype diagram at this time. The content included in the prototype diagram is as follows:"
				},
				{
					id:5,
					title:"What opportunities can cloud computing bring to enterprises?",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI7.jpg',
					content:"For enterprises, as their business scale continues to expand, they will face challenges in data analysis and processing, and cloud computing can effectively cope and help enterprises achieve better development. So, what opportunities can cloud computing bring to enterprises? 1. It is beneficial for enterprises to have accurate market positioning in order to achieve success in their brands and products. Good market positioning requires a large amount of research costs and rich experience accumulation, which poses certain difficulties for small and medium-sized enterprises. But through cloud computing and Big data technology, SMEs can also easily achieve precise positioning."
				}],
				button:"More Cases",
				button2:"Details"
			},

			electronicDesign:{
				title:"Electronic Design and Simulation",
				text:"SI, PI, EMC, CAE, DFM, comprehensive simulation and design",
				icon1:{
					title:"Signal integrity",
					text1:"Design rules, topology simulation, reflection, crosstalk, ",
					text2:"Time series simulation analysis, multi board joint system analysis"
				},
				icon2:{
					title:"Power Integrity",
					text1:"DC voltage drop analysis and planar resonance analysis",
					text2:"PDN impedance analysis, decoupling capacitor optimization"
				},
				icon3:{
					title:"electromagnetic compatibility",
					text1:"EMC design, EMC rectification",
					text2:"EMC Testing"
				},
				icon4:{
					title:"manufacturability",
					text1:"DFF manufacturability analysis, DFA assemblability analysis",
					text2:"DFT testable analysis"
				},
				leftContent:{
					title:"Service advantages",
					text1:"Technical hard",
					text2:"Senior PCB design engineers with over 10 years of experience per person",
					text3:"Fast speed",
					text4:"Provide high-quality service with multiple people running 7 * 24 hours in parallel, allowing for 24-hour rapid delivery",
					text5:"Low cost",
					text6:"PCBA one-stop service, saving you time, worry, and effort",
					text7:"Strict confidentiality",
					text8:"A precise one-on-one service model with dedicated data protection personnel",
				},
				rightContent:{
					title:"PCBA one-stop service - we have everything you want",
					text1:"PCB board making",
					text2:"Can be expedited in 8 hours, nationwide package shipping",
					text3:"SMT mounting",
					text4:"One piece of adhesive can be used for pasting loose materials",
					text5:"component",
					text6:"Accurate manual quotation with high completion rate"
				}
			}
		},

		layoutDesign:{
			designCapacity:{
				title:"More than 10 years of design ability",
				label1:"low cost",
				label2:"Fast speed",
				label3:"Excellent quality",
				label4:"Good service",
				text:"Customers only need to provide basic schematic and specification information, from PCB design to board manufacturing and mounting, we provide a one-stop solution. Accelerate your product research and development, promote the rapid launch of new products, and fully seize market opportunities to win greater economic benefits. Professional PCB design philosophy, excellent PCB layout team, and strict design process provide efficient and high-quality services to customers.",
				img1:process.env.VUE_APP_OSS_URL + '/solution/computer.png',
				icon1:{
					text1:"Within 50PIN",
					label1:"￥200",
					text2:"Within 100PIN",
					label2:"￥200",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability1.png',
				},
				icon2:{
					text1:"Within 100PIN",
					label1:"24 Hours",
					text2:"Within 500PIN",
					label2:"48 hours",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability2.png',
				},
				icon3:{
					text1:"Senior PCB Design Engineer",
					text2:"Per capita employment for 10 years",
					text3:"Ensure the design quality of PCB",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability3.png',
				},
				icon4:{
					text1:"A service team of dozens of people",
					text2:"One on one service model",
					text3:"24/7 service",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability3.png',
				}
			},

			designServices:{
				title:"Design Services",
				list: [{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service1.png',
							text: 'Provide PCB packaging construction for customers for free (customers provide device Datasheets)'
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service2.png',
							text: 'Provide free PCB impedance calculation, stack design, QA inspection, process inspection, and EMC inspection for customers.'
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service3.png',
							text: 'The design includes high-speed, high-frequency, high-power, analog, digital analog hybrid, HDI, FPC and other design types.'
						}
					]
				}, {
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service4.png',
							text: 'Support schematic and PCB software formats: Cadence, Mentoree, Mentor pads, Altium, AD.'
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service5.png',
							text: "The company's strong R&D engineer team provides technical support, assisting clients and layout engineers in schematic analysis and PCB design, eliminating electrical schematic design errors, while ensuring the quality and reliability of the design."
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service6.png',
							text: 'On site service, we provide talent dispatch services based on the confidentiality of customer products and when human resources are scarce. Our engineers can provide design services on the client side, which is personalized and efficient.'
						}
					]
				}],
				list2: [{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service1.png',
							text: 'Provide PCB packaging construction for customers for free (customers provide device Datasheets)'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service2.png',
							text: 'Provide free PCB impedance calculation, stack design, QA inspection, process inspection, and EMC inspection for customers.'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service3.png',
							text: 'The design includes high-speed, high-frequency, high-power, analog, digital analog hybrid, HDI, FPC and other design types.'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service4.png',
							text: 'Support schematic and PCB software formats: Cadence, Mentoree, Mentor pads, Altium, AD.'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service5.png',
							text: "The company's strong R&D engineer team provides technical support, assisting clients and layout engineers in schematic analysis and PCB design, eliminating electrical schematic design errors, while ensuring the quality and reliability of the design."
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service6.png',
							text: 'On site service, we provide talent dispatch services based on the confidentiality of customer products and when human resources are scarce. Our engineers can provide design services on the client side, which is personalized and efficient.'
						}
					]
				}]
			},

			ourAdvantages:{
				title:"Our Advantages",
				label1:"Senior PCB design engineer with over 80 precision board design experience",
				label2:"Collaborating with over 800 clients, providing one-on-one precision services",
				label3:"Provide multi person parallel 7 * 24 hour service for quick delivery in one day",
				label4:"Packaging engineers, Layout engineers, EMI engineers, EMC engineers, the cornerstone of professionalism",
				leftContent:{
					title:'pcb design software',
				},
				rightContent:{
					title:"PCB design capability",
					list:[{
						name:'The number of layers can reach',
						text:'40 floors'
					},
					{
						name:'line width',
						text:'≥2mil（HDI）'
					},
					{
						name:'Number of PINs',
						text:'≤60000'
					},
					{
						name:'Line spacing',
						text:'≥2mil（HDI）'
					},
					{
						name:'BGA spacing',
						text:'≥0.20mm'
					},
					{
						name:'Number of BGA PINs',
						text:'≤2500'
					},
					{
						name:'Speed signal',
						text:'≤ 12G (differential signal)'
					},
					{
						name:'Price HDI',
						text:'Any layer mutual chat (ELIC)'
					}]
				}
			},

			designProcess:{
				title:"Design process",
				designProcessTitle1:{
					text1:"pricing",
					text2:"release"
				},
				designProcessTitle2:{
					text1:"examine",
					text2:"quotation"
				},
				designProcessTitle3:{
					text1:"payment",
					text2:"confirm"
				},
				designProcessTitle4:{
					text1:"start",
					text2:"design"
				},
				designProcessTitle5:{
					text1:"many times",
					text2:"confirm"
				},
				designProcessTitle6:{
					text1:"complete",
					text2:"Upload"
				},
				designProcessTitle7:{
					text1:"custom",
					text2:"download"
				},
				designProcessTitle8:{
					text1:"feedback",
					text2:"examine"
				},
				designProcessContent1:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case1.png',
					title:"Customer needs to provide information",
					text:"Schematic diagram (DSN or powerlogic), network table, packaging, structural diagram (DXF), device information that needs to be newly built, design requirements, etc"
				},
				designProcessContent2:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case2.png',
					title:"Layout and wiring review",
					text:"According to design specifications, design guidelines, customer design requirements, and relevant CHECKLIST. After the project was launched, our engineers conducted electrical design requirements such as schematic DRC inspection and structural verification. If there were any issues, they immediately reported them to the customer through EQ records."
				},
				designProcessContent3:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case3.png',
					title:"Customer layout confirmation",
					text:"Provide layout files and structural files for customers to conduct layout reviews; The customer confirms the rationality of the layout, stacking scheme, impedance scheme, structure, packaging, and confirms the wiring parameters"
				},
				designProcessContent4:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case4.png',
					title:"Design data output",
					text:"PCB source files, Gerber archives, assembly archives, steel mesh archives, structural archives, etc. After the PCB layout design is completed, our engineers conduct mutual inspections, including DFM inspection, QA inspection, EMC inspection. After the customer confirms that it is OK, Gerber and other production documents are issued"
				}
			},

			designCycle:{
				title:"Design cycle",
				text1:'Number of board PINs',
				text2:"Design lead time (working days)",
				tableList:[{
					text:["Within 1000","3-5 Days"]
				},
				{
					text:["2000-3000","5-7 Days"]
				},
				{
					text:["4000-5000","8-12 Days"]
				},
				{
					text:["6000-7000","12-15 Days"]
				},
				{
					text:["8000-9000","15-18 Days"]
				},
				{
					text:["10000-13000","18-20 Days"]
				},
				{
					text:["14000-15000","20-22 Days"]
				},
				{
					text:["16000-20000","22-30 Days"]
				}]
			},

			designShow:{
				title:"Design Exhibition"
			}
		}
	},

	factoryDisplay:{
		displayFactory:{
			title:"KOIIOSCO.,LTD Headquarters",
			text:"Koiios Global Electronic Intelligent Hardware Intelligent Manufacturing Platform",
			text2:"introduce",

			workshop:{
				title:"PCB production workshop",
				list:[{
					title:"DRILL HOLE",
					text:"Taiwan Dongtai high-precision drilling machine, with a positioning accuracy of ± 0.05mm and a self drilling accuracy of ± 0.018mm, automatically detects tool diameter, breaks, and checks holes after drilling, completely eliminating slot hole skewness and leakage.",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process1@icon.png',
					imgList:[{
						name:"Double head drilling machine",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk1.jpg',
					},
					{
						name:"CNC gong edge machine",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk2.jpg',
					},
					{
						name:"High speed six axis drilling machine",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk3.jpg',
					},
					{
						name:"Drilling production line",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk4.jpg',
					}]
				},
				{
					title:"COPPER PLATING",
					text:"Fully automatic copper plating equipment, 70 minute diagram electricity, after multiple processes of tempering, the thickness of hole copper and surface copper far exceeds industry standards.",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process2@icon.png',
					imgList:[{
						name:"Sunk copper wire",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct1.jpg',
					},
					{
						name:"Electroplating Line Details",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct2.jpg',
					},
					{
						name:"VCP electroplating",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct3.jpg',
					},
					{
						name:"VCP Plating Details",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct4.jpg',
					}]
				},
				{
					title:"LINE MAKING",
					text:"LDI laser exposure, integrated production equipment effectively controls line length, micro short, and unclean etching, with a minimum line width and distance of up to 4mil. AOI optical detection eliminates all hidden quality issues",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process3@icon.png',
					imgList:[{
						name:"LDI burst",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl1.jpg',
					},
					{
						name:"line",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl2.jpg',
					},
					{
						name:"Large scale fully automatic etching",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl3.jpg',
					},
					{
						name:"AOI optical detection",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl4.jpg',
					}]
				},
				{
					title:"SOLDERING CHARACTER",
					text:"Fully automatic solder mask printing, with 80 minutes of multi temperature zone baking, ensures solder mask uniformity and adhesion, comprehensively solving the risk of red and exposed copper in the hole ring.",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process4@icon.png',
					imgList:[{
						name:"Resistance welding",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh1.jpg',
					},
					{
						name:"Resistance welding",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh2.jpg',
					},
					{
						name:"char",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh3.jpg',
					},
					{
						name:"char",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh4.jpg',
					}]
				},
				{
					title:"FORMING",
					text:"The newly printed PCB board is continuous, and in order to meet the customer's specifications and dimensions, it is necessary to perform CNC edge cutting or V-cutting on the PCB board.",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process5@icon.png',
					imgList:[{
						name:"Gong edge",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx1.jpg',
					},
					{
						name:"Gong edge",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx2.jpg',
					},
					{
						name:"Gong edge",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx3.jpg',
					},
					{
						name:"V-cut",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx4.jpg',
					}]
				},
				{
					title:"CIRCUIT BOARD TEST",
					text:"Before shipment, use automatic electrical testing or flying needle testing to eliminate all open circuits and short circuits. Ensure that every board in the customer's hands is a qualified product.",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process6@icon.png',
					imgList:[{
						name:"Flying Needle Test",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs1.jpg',
					},
					{
						name:"Test details",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs2.jpg',
					},
					{
						name:"Automatic on-off testing machine",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs3.jpg',
					},
					{
						name:"Automatic testing machine",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs4.jpg',
					}]
				}]
			}
		},

		banner:{
			title:"Remarks: 1. Special laminations include - customer specified/blind burial/high-frequency mixed voltage/impedance/thick copper plate, etc. 2. If the thickness exceeds 2.4mm, a special quotation is required. Our company can achieve a conventional plate thickness of 4mm, with a maximum of 5mm;"
		},

		iaminatedStructure:{
			list: [{
				title: "Four layer laminated structure diagram",
				notes: "（excluding electroplating/solder mask/character/surface process thickness）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "0.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.08 (1080)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.08 (1080)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.4 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.13mm",
					name: "0.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.60 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.33mm",
					name: "0.8mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.80 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.53mm",
					name: "1.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.00 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.73mm",
					name: "1.2mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.73",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.20 +/-10%",
							type: true
						}
					]
				}, {
					core: "1.13mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "1.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						}
					]
				}, {
					core: "1.53mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "1.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						}
					]
				}, {
					core: "1.96mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "1.96",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						}
					]
				}]
			},
			{
				title: "Six-layer laminated structure diagram",
				notes: "（Excluding plating/solder mask/character/surface process thickness）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "0.8mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.80 +/-0.13mm",
							type: true
						}
					]
				},
				{
					core: "0.23mm",
					name: "1.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.00 +/-0.13mm",
							type: true
						}
					]
				},
				{
					core: "0.33mm",
					name: "1.2mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.20 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.53mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.73mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.73",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.73",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.93mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.93",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.93",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						}
					]
				}]
			},
			{
				title: "Eight-layer laminated structure diagram",
				notes: "（Excluding plating/solder mask/character/surface process thickness）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "1.2mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.20 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.23mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.23mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.53mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						}
					]
				}]
			},
			{
				title: "Ten layer laminated structure diagram",
				notes: "（Excluding plating/solder mask/character/surface process thickness）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L9-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L10-Cu:",
							text: "0.175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						},

					]
				},
				{
					core: "0.23mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L9-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L10-Cu:",
							text: "0.175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						},

					]
				},
				{
					core: "0.33mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L9-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L10-Cu:",
							text: "0.175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						},

					]
				}]
			}]
		},

		processShow:{
			gaugeOutfit1:"Project",
			gaugeOutfit2:"Project name",
			gaugeOutfit3:"Process capability",
			list:[{
				title:'Overall process capability',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Layers',
				text:'1-30 floors',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'High frequency mixed pressure HDI',
				text:'Ceramic materials and PTFE materials can only be mechanically drilled blind buried holes or controlled depth drilling, back drilling, etc. (laser drilling is not allowed, and copper foil cannot be directly pressed)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'High speed HDI',
				text:'Produced by conventional HDI',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Laser order',
				text:'Level 1-5 (level ≥ 6 requires review)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Plate thickness range',
				text:'0.1-5.0mm (less than 0.2mm, greater than 6.5mm needs to be reviewed,)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Plate thickness range',
				text:'0.1-5.0mm (less than 0.2mm, greater than 6.5mm needs to be reviewed,)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Minimum finished size',
				text:'Single board 5*5mm (less than 3mm needs review)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Maximum finished size',
				text:'2-20 layers 21*33inch; Remarks: The short side of the board exceeds 21inch and needs to be reviewed.',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Finished Copper Thickness',
				text:'The outer layer is 8OZ (more than 8OZ needs to be reviewed), the inner layer is 6OZ (more than 6OZ needs to be reviewed)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Min Finished Copper Thickness',
				text:'1/2oz',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Alignment between layers',
				text:'≤3mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Via Fill Range',
				text:'Plate thickness≤0.6mm, hole diameter≤0.2mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Resin plug orifice plate thickness range',
				text:'0.254-6.0mm, PTFE plate resin plug holes need review',
				list:[]
			},
			{
				title:'',
				textType:true,
				lengthIndex:18,
				towLengthIndex:2,
				name:'Plate Thickness Tolerance',
				text:'Plate thickness≤1.0mm; ±0.1mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'',
				text:'Plate thickness＞1.0mm; ±10%',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Impedance tolerance',
				text:'±5Ω (<50Ω), ±10% (≥50Ω); ±8% (≥50Ω, subject to review)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Warpage',
				text:'Routine: 0.75%, limit 0.5% (subject to review) maximum 2.0%',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'Press times',
				text:'Lamination of the same core board ≤ 5 times (more than 3 times need to be reviewed)',
				list:[]
			},
			{
				title:'material type',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'Ordinary Tg FR4',
				text:'Shengyi S1141, Kingboard KB6160A, Guoji GF212',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'Medium Tg FR4',
				text:'Shengyi S1150G (medium Tg plate), Kingboard KB6165F, Kingboard KB6165G (halogen-free)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'High Tg FR4',
				text:'Shengyi S1165 (halogen-free), Kingboard KB6167G (halogen-free), Kingboard KB6167F',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'Aluminum plate',
				text:'Guoji GL12, Clear CS-AL-88/89 AD2, Juqin JQ-143 1-8 layer mixed pressure FR-4',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'HDI board material type',
				text:'LDPP (IT-180A 1037 and 1086), common 106 and 1080',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'High CTI',
				text:'Shengyi S1600',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'High Tg FR4',
				text:'Isola: FR408, FR408HR, IS410, FR406, GETEK, PCL-370HR; ​​Lianmao: IT-180A,IT-150DA; Nelco: N4000-13, N4000-13EP, N4000-13SI, N4000-13EP;SI; Panasonic: R- 5775K (Megtron6), R-5725 (Megtron4); Kingboard: KB6167F;Taiguang: EM-827; Hongren: GA-170; South Asia: NP-180; Taiyao: TU-752, TU-662; Hitachi:MCL -BE-67G(H), MCL-E-679(W), MCL-E-679F(J); Tenghui: VT-47;',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'Ceramic powder filled high frequency material',
				text:'Rogers: Rogers4350, Rogers4003; Arlon: 25FR, 25N;',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'PTFE high frequency material',
				text:'Rogers series, Taconic series, Arlon series, Nelco series, TP series',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'PTFE prepreg',
				text:'Taconic: TP series, TPN series, HT1.5(1.5mil), Fastrise series',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'Material mixed pressure',
				text:'Rogers, Taconic, Arlon, Nelco and FR-4',
				list:[]
			},
			{
				title:'metal substrate',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Layers',
				text:'Aluminum substrate, copper substrate: 1-8 layers; cold plate, sintered plate, buried metal plate: 2-24 layers; ceramic plate: 1-2 layers;',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Finished product size (aluminum base plate, copper base plate, cold plate, sintered plate, buried metal plate)',
				text:'MAX:610*610mm, MIN:5*5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Largest production size (ceramic plate)',
				text:'100*100mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Finished plate thickness',
				text:'0.5-5.0mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Copper thickness',
				text:'0.5-10 OZ',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Metal base thickness',
				text:'0.5-4.5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Metal base material',
				text:'AL: 1100/1050/2124/5052/6061; Copper: copper pure iron',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Minimum finished hole diameter and tolerance',
				text:'NPTH: 0.5±0.05mm; PTH (aluminum substrate, copper substrate): 0.3±0.1mm; PTH (cold plate, sintered plate, buried metal plate): 0.2±0.10mm;',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Shape machining accuracy',
				text:'±0.2mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'PCB part surface treatment process',
				text:'With/without lead HASL; OSP; immersion nickel (palladium) gold; electric (nickel) soft/hard gold; electroplating tin; nickel-free electroplating soft and hard gold; thick gold production',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Metal surface treatment',
				text:'Copper: nickel-plated gold; aluminum: anodic oxidation, hard oxidation, chemical passivation; mechanical treatment: dry sandblasting, wire drawing',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Metal based material',
				text:'Quanbao aluminum substrate (T-110, T-111); Tenghui aluminum substrate (VT-4A1, VT-4A2, VT-4A3); Laird aluminum substrate (1KA04, 1KA06); Beggs metal substrate (MP06503 , HT04503); TACONIC metal substrate (TLY-5, TLY-5F);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Thermally conductive adhesive thickness (dielectric layer)',
				text:'75-150um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Buried copper block size',
				text:'3*3mm—70*80mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Flatness of buried copper block (drop accuracy)',
				text:'±40um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Buried copper block to hole wall distance',
				text:'≥12mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'Thermal Conductivity',
				text:'0.3-3W/mk (aluminum base plate, copper base plate, cold plate); 8.33W/mk (sintered plate); 0.35-30W/mk (buried metal plate); 24-180W/mk (ceramic plate);',
				list:[]
			},
			{
				title:'product type',
				textType:false,
				lengthIndex:1,
				towLengthIndex:0,
				name:'Rigid plate',
				text:'Backplane, HDI, multi-layer buried blind via, thick copper board, power thick copper, semiconductor test board',
				list:[]
			},
			{
				title:'Lamination method',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'Multi-press blind buried plate',
				text:'Lamination on the same side≤3',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'HDI board type',
				text:'1+n+1, 1+1+n+1+1, 2+n+2, 3+n+3 (buried holes in n≤0.3mm), laser blind holes can be plated and filled',
				list:[]
			},
			{
				title:'partial mixed pressure',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'Minimum distance from mechanical drilling to conductor in local mixed pressure area',
				text:'≤10 layers: 14mil; 12 layers: 15mil; >12 layers: 18mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'The minimum distance from the local mixed pressure junction to the borehole',
				text:'≤12 layers: 12mil; >12 layers: 15mil',
				list:[]
			},
			{
				title:'surface treatment',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Lead-free',
				text:'Copper nickel gold plating, immersion gold, hard gold plating (with/without nickel), gold plated fingers, lead-free HASL, OSP, chemical nickel palladium gold, soft gold plating (with/without nickel), immersion silver, immersion tin, ENIG +OSP, ENIG+G/F, full board gold plating+G/F, immersion silver+G/F, immersion tin+G/F',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Lead',
				text:'lead HASL',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Thickness ratio',
				text:'10:1 (lead/lead-free HASL, chemical immersion nickel gold, immersion silver, immersion tin, chemical nickel palladium gold); 8:1 (OSP)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Processing size (MAX)',
				text:'Immersion gold: 520*800mm, vertical immersion tin: 500*600mm, horizontal immersion tin: less than 500mm on one side; horizontal immersion silver: less than 500mm on one side; lead/lead-free HASL: 520*650mm; OSP: less than 500mm; electroplating hard gold: 450*500mm; single side is not allowed to exceed 520mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Processing size (MIN)',
				text:'Immersion tin: 60*80mm; Immersion silver: 60*80mm; Lead/lead-free HASL: 150*230mm; OSP: 60*80mm;',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Processing thickness',
				text:'Immersion gold: 0.2-7.0mm, Immersion tin: 0.3-7.0mm (vertical immersion tin line), 0.3-3.0mm (horizontal line); Immersion silver: 0.3-3.0mm; Lead/lead-free HASL: 0.6-3.5mm ; HASL below 0.4mm needs to be reviewed and produced; OSP: 0.3-3.0mm; electroplating hard gold: 0.3-5.0mm (thickness ratio 10:1)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Immersion gold board IC minimum spacing or PAD to line minimum spacing',
				text:'3mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Golden Finger Height Max',
				text:'1.5inch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Minimum spacing between gold fingers',
				text:'6mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'Segmented gold finger minimum segment spacing',
				text:'7.5mil',
				list:[]
			},
			{
				title:'Surface coating thickness',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'HASL',
				text:'2-40um (the thinnest thickness of the large tin surface with lead HASL is 0.4um, and the thinnest thickness of the lead-free HASL large tin surface is 1.5um)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'OSP',
				text:'Film thickness: 0.2-0.4um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Electroless Nickel Gold',
				text:'Nickel thickness: 3-5um; gold thickness: 1-3uinch, ≥3uinch needs review',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Chemical immersion silver',
				text:'6-12uinch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Chemical immersion tin',
				text:'Tin thickness ≥ 1um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Electroplated hard gold',
				text:'2-50uinch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Electroplated soft gold',
				text:'Gold thickness 0.10-1.5um (dry film pattern plating process), gold thickness 0.10-4.0um (non-dry film pattern plating process)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Chemical Nickel Palladium Gold',
				text:'NI: 3-5um, Pd: 1-6uinch, Au: 1-4uinch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Electroplated Copper Nickel Gold',
				text:'Gold thickness 0.025-0.10um, nickel thickness ≥ 3um, base copper thickness up to 1OZ',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Gold finger nickel-plated gold',
				text:'Gold thickness 1-50uinch (required value refers to the thinnest point), nickel thickness ≥ 3um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Carbon oil',
				text:'10-50μm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Green oil',
				text:'Copper surface cover oil (10-18um), via hole cover oil (5-8um), line corner ≥5um (one-time printing, copper thickness below 48um)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'Blue glue',
				text:'0.20-0.80mm',
				list:[]
			},
			{
				title:'drilling',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'0.1/0.15/0.2mm mechanical drilling maximum plate thickness',
				text:'0.8mm/1.5mm/2.5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Laser drilled holes with the smallest diameter',
				text:'0.1mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Laser drilled hole diameter max.',
				text:'0.15mm',
				list:[]
			},
			{
				title:'',
				textType:true,
				lengthIndex:25,
				towLengthIndex:6,
				name:'Mechanical hole diameter (finished product)',
				text:'0.10-6.2mm (corresponding to drill 0.15-6.3mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'The minimum finished hole diameter of PTFE material (including mixed pressure) plate is 0.25mm (corresponding to drill bit 0.35mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'Mechanical buried blind hole diameter ≤ 0.3mm (corresponding to drill 0.4mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'Drilling diameter of the green oil plug hole in the plate hole is ≤0.45mm (corresponding to the drill bit 0.55mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'The minimum diameter of the continuous hole is 0.35mm (corresponding to the drill bit 0.45mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'Metallized semi-hole diameter minimum 0.30mm (corresponding to drill 0.4mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Maximum thickness-to-diameter ratio of through-hole plate',
				text:'20:1 (not including ≤0.2mm tool diameter; >12:1 to be evaluated)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Laser Drilling Depth Aperture Ratio Maximum',
				text:'1：1',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Mechanically controlled deep drilling of blind holes with the largest depth-to-aperture ratio',
				text:'1.3:1 (aperture ≤0.20mm), 1.15:1 (aperture ≥0.25mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'The minimum depth of mechanically controlled deep drilling (back drilling)',
				text:'0.2mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - minimum distance from mechanical drilling to conductor (non-buried blind hole plate and first-order laser blind hole)',
				text:'5.5mil (≤8 layers); 6.5mil (10-14); 7mil (>14 layers)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - minimum distance from mechanical drilling to conductor (mechanical buried blind hole plate and second-order laser buried blind hole)',
				text:'7mil (one-time pressing); 8mil (secondary pressing); 9mil (three-time pressing)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - minimum distance from mechanical drilling to conductor (laser blind buried via)',
				text:'7mil (1+N+1); 8mil (1+1+N+1+1 or 2+N+2)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - Minimum body distance from laser drilling to conductor (1st and 2nd order HDI boards)',
				text:'5mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - minimum distance between different network hole walls (after compensation)',
				text:'10mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - the minimum distance between the walls of the same network holes (after compensation)',
				text:'6mil (through hole; laser blind hole); 10mil (mechanical blind buried hole)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'The distance between the drilling hole and the non-metallic hole wall is the smallest (after compensation)',
				text:'8mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - hole position tolerance (compared with CAD data)',
				text:'±2mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - NPTH hole diameter tolerance is the smallest',
				text:'±2mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - hole diameter accuracy of solderless components',
				text:'±2mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - Tapered Hole Depth Tolerance',
				text:'±0.15mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'Drilling - Tapered Hole Diameter Tolerance',
				text:'±0.15mm',
				list:[]
			},
			{
				title:'pad (ring)',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'The inner and outer pads of the laser hole are the smallest in size',
				text:'10mil (4mil laser hole), 11mil (5mil laser hole)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'The size of the inner and outer pads of the mechanical via is the smallest',
				text:'16mil (8mil aperture)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'BGA pad diameter minimum',
				text:'Leaded HASL process 10mil, lead-free HASL process 12mil, other processes 7mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'Land Tolerance (BGA)',
				text:'+/-1.2mil (pad<12mil); +/-10% (pad≥12mil)',
				list:[]
			},
			{
				title:'Line Width/Space',
				textType:true,
				lengthIndex:13,
				towLengthIndex:11,
				name:'The limit line width corresponding to the copper thickness',
				text:'1/2OZ：3/3mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'1OZ: 3/4mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'2OZ: 5/5mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'3OZ: 7/7mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'4OZ: 12/12mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'5OZ: 16/16mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'6OZ: 20/20mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'7OZ: 24/24mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'8OZ: 28/28mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'9OZ: 30/30mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'10OZ: 32/30mil',
				list:[]
			},
			{
				title:'',
				textType:true,
				lengthIndex:13,
				towLengthIndex:2,
				name:'Line Width Tolerance',
				text:'≤10mil：+/-1.0mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:2,
				name:'',
				text:'＞10mil：+/-1.5mil',
				list:[]
			},
			{
				title:'Solder mask characters',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Maximum drilling diameter of solder mask plug hole (both sides covered with oil',
				text:'0.5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Solder resist ink color',
				text:'Green, yellow, black, blue, red, white, purple, matt green, matte black, high refraction white oil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Character ink color',
				text:'white, yellow, black',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'The maximum diameter of blue glue aluminum sheet plug hole',
				text:'5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Resin plug hole drilling diameter range',
				text:'0.1-1.0mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Resin plug hole maximum thickness-to-diameter ratio',
				text:'12：1',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Minimum Width of Solder Bridge',
				text:'Green oil 4mil, variegated 6mil control solder mask bridge needs special requirements',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Minimum character line width',
				text:'White characters 3mil high 24mil; black characters 5mil high 32mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Hollow character minimum spacing',
				text:'Hollow width 8mil high 40mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'Solder mask hollow words',
				text:'Hollow width 8mil high 40mil',
				list:[]
			},
			{
				title:'shape',
				textType:true,
				lengthIndex:18,
				towLengthIndex:4,
				name:'The distance from the center line of V-CUT without copper leakage to the figure',
				text:'H≤1.0mm: 0.3mm (20° refers to V-CUT angle), 0.33mm (30°), 0.37mm (45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'',
				text:'1.0＜H≤1.6mm:0.36mm(20°)、0.4mm(30°)、0.5mm(45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'',
				text:'1.6＜H≤2.4mm:0.42mm(20°)、0.51mm(30°)、0.64mm(45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'',
				text:'2.4＜H≤3.2mm:0.47mm(20°)、0.59mm(30°)、0.77mm(45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT Symmetry Tolerance',
				text:'±4mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'The largest number of V-CUT lines',
				text:'100 pieces',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT angle tolerance',
				text:'±5 degrees',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT Angle Specifications',
				text:'20, 30, 45 degrees',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Gold finger chamfer angle',
				text:'20, 30, 45 degrees',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Gold finger chamfer angle tolerance',
				text:'±5 degrees',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'The minimum distance between the TAB next to the gold finger without falling down',
				text:'6mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'The minimum distance between the side of the gold finger and the edge of the shape',
				text:'8mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Controlled depth milling groove (edge) depth accuracy (NPTH)',
				text:'±0.10mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Dimensional accuracy (edge ​​to edge)',
				text:'±8mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Milling slot minimum tolerance (PTH)',
				text:'Both slot width and slot length are ±0.15mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Milling slot minimum tolerance (NPTH)',
				text:'Both slot width and slot length direction are ±0.10mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Drilled Slot Hole Minimum Tolerance (PTH)',
				text:'Groove width direction ±0.075mm; groove length/groove width<2: groove length direction +/-0.1mm; groove length/groove width≥2: groove length direction+/-0.075mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'Drilled slot minimum tolerance (NPTH)',
				text:'Groove width direction ±0.05mm; groove length/groove width<2: groove length direction +/-0.075mm; groove length/groove width≥2: groove length direction +/-0.05mm',
				list:[]
			}]
		},

		productList:{
			title:"Global Electronic Intelligent Hardware Intelligent Manufacturing Platform - ",
			text:"No matter how urgent it is, it can always be on time",
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr1.jpg',
				title:"Multilayer blind hole high-frequency PCB board",
				text:"Blind hole high-frequency circuit board",
				time:"month ago"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr2.jpg',
				title:"Microwave RF board",
				text:"4-layer sinking gold high-frequency board",
				time:"month ago"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr3.jpg',
				title:"Four layer mixed pressure high-frequency board",
				text:"Rogers high-frequency board",
				time:"month ago"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr4.jpg',
				title:"6-layer high-frequency PCB",
				text:"6-layer sinking gold circuit board",
				time:"month ago"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr5.jpg',
				title:"Car camera PCB",
				text:"Rigid flexible combination circuit board",
				time:"month ago"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr6.jpg',
				title:"Communication 4-layer PCB board",
				text:"Lead free tin sprayed circuit board",
				time:"month ago"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr7.jpg',
				title:"Automotive 4-layer PCB board",
				text:"Four layer sinking gold circuit board",
				time:"month ago"
			}]
		},

		activity:{
			title1:"Global",
			title2:"Electronic intelligent hardware intelligent manufacturing ",
			title3:"brand",
			text1:"Brand, derived from thoughtful service",
			text2:"Quality originates from continuous innovation",
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz1.JPG',
				title:"The 10th China Electronic Information Expo 2022",
				text:"The 10th China Electronic Information Expo concluded smoothly in 2022. In order to participate in this exhibition, the company actively organized an elite team led by senior engineers and business leaders. As a benchmark enterprise in the circuit board industry, it showcased advanced technology and innovative solutions in the industry, attracting the attention and visits of numerous domestic and foreign visitors.",
				time:"2022-09-22",
				imgList:[{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz1.JPG'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz2.JPG'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz3.JPG'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz4.JPG'
				}]
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz5.jpg',
				title:"The 11th China Electronic Information Expo 2023",
				text:"On April 9, 2023, the 11th China Electronic Information Expo (CITE2023) successfully concluded at the Shenzhen Convention and Exhibition Center. As a top-level platform to showcase the latest products and technologies in the global electronic information industry, the China Electronic Information Expo has witnessed the magnificent development of the industry through eleven years of refinement since its first held in 2013. It has become a participant in promoting technological innovation and a leading indicator of industrial development.",
				time:"2022-04-09",
				imgList:[{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz5.jpg'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz6.jpg'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz7.jpg'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz8.jpg'
				}]
			}]
		},

		sunbathing:{
			title1:"650000 globally",
			title2:"Customer chooses Koiios",
			text:"PCBA one-stop service with a positive review rate of 99%"
		}
	},

	aboutUs:{
		aboutUs:{
			// title:"Beijing Koiios Electronics Co., Ltd",
			title:"About KOIIOS",
			content1:{
				title:"Global user count",
				text:"Yu Family"
			},
			content2:{
				title:"Daily process orders",
				text:"Yu Dan"
			},
			content3:{
				title:"staff total",
				text:"People"
			},
			content4:{
				title:"Monthly shipment volume",
				text:"square metre"
			},
			content5:{
				title:"Covering countries",
				text:"individual"
			},
			content6:"KOIIOS was established in 2009 and currently has multiple branches in China (Shenzhen Xinhang International Electronics Co., Ltd., Wuhan KOIIOS Information Technology Co., Ltd., etc.), as well as warehousing and logistics centers in Hong Kong, Shenzhen, Amsterdam (The Netherlands), the United States, and other places; There are two major production bases in China: Anhui Guangde Industrial Base and Zhejiang Zhuji Visual Intelligence Industrial Park. Currently, we have provided one-stop PCBA services for customers in Europe and Asia, covering components, PCBs, SMTs, and more. Utilizing the Internet, Internet of Things, and cloud technology to deeply transform the entire value chain of segmented industries in the electronic manufacturing industry, adopting a stocking method of analyzing common industry needs, centralized procurement, and pull replenishment, helping factory customers produce according to customer needs, and achieving a true VMI model.",
			content7:'KOIIOS takes the meaning of "Gewuzhizhi" in its name as its fundamental purpose, and uses a scientific and rigorous approach to find the pain points at the bottom of the industry. It pursues "fast": fast service, fast response, fast delivery time, fast development, and "good": good service attitude, good quality, and good experience. Aspiring to become the most worry free assistant in the electronic manufacturing industry!'
		},

		honor:{
			title:"Certificates"
		},

		coreConcepts:{
			title:'Core concepts',
			content:'Service, trust, win-win situation, innovation',
			list:[{
				id:1,
				title:'Our Vision',
				content:'Become the most trusted IC component technology trade service provider in China'
			},
			{
				id:2,
				title:'Our Mission',
				content:'Achieve Customers, Achieve Brands, Achieve Employees'
			},
			{
				id:4,
				title:'Corporate Values',
				content:'Customer first, respect for individuals, integrity, teamwork, pursuit of excellence'
			}]
		},

		contactUs:{
			title1:"strengthen communication ",
			title2:"Create a win-win situation together ",
			text1:"Koiios ● Global Electronic Intelligent Hardware Intelligent Manufacturing Platform",
			title3:"Contact Us",
			text2:"Landline",
			text3:"mailbox",
			text4:"address",
			list:[{
				name:'Beijing Koiios Electronics Co., Ltd',
				phone:'010-86229430',
				email:'info@koiios.net',
				address:'No. 8 Fuze Road, Vanke Central City (1511, Building 2, No. 8 Fuze Road, Vanke Central City, Fangshan District, Beijing)'
			},{
				// name:'Xxintek Co., Ltd. (Shenzhen Headquarters)',
				name:'Shenzhen Koiios Information Technology Co., Ltd',
				phone:'0755-82056199、82056299、82056399、82056499',
				email:'info@koiios.net',
				address:'1506-1508, Caiwuwei Jinlong Building, No. 139, Hongbao Road, Guiyuan Street, Luohu District, Shenzhen'
			},
			{
				name:'WUHAN branch',
				phone:'027-63497638  63498598  63497068',
				email:'info@koiios.net',
				address:'Room 1304, Building 1, Optical Valley Smart Park, No. 7, Financial Port 1st Road, Donghu New Technology Development Zone, Wuhan City'
			},
			{
				name:'Hong Kong Branch',
				phone:'00852-37052636',
				email:'info@koiios.net',
				address:'Room J, 1st Floor, Fengying Industry and Trade Center, 33 Safety Street, Fanling, New Territories'
			},
			{
            	name:'The Netherlands Branch',
            	//phone:'00852-37052636',
            	email:'info@koiios.net',
            	address:'Kraijenhoffstraat 137A, 1018RG Amsterdam, Netherlands'
            }
			],
			text5:"News and information",
			text6:"Koiios ● Global Electronic Intelligent Hardware Intelligent Manufacturing Platform"
		}
	},

	top:{
		content1:"Order management",
		text1:"Login",
		text2:"Register",
		text3:"Login out",
		text4:"Hello",
		tabsList:[{
			id:1,
			name:'Home',
			list:[]
		},
		{
			id:2,
			name:'Smart Quotes',
			list:[{
				id:18,
				name:"PCB Pricing"
			},
			{
				id:19,
				name:"SMT Pricing"
			},
			{
				id:20,
				name:"Steel Pricing"
			},
			{
				id:21,
				name:"BOM Allocation"
			}]
		},
		{
			id:3,
			name:'PCB boarding',
			list:[{
				id:1,
				name:"PCB board making"
			},
			{
				id:2,
				name:"PCB batch"
			},
			// {
			// 	id:3,
			// 	name:"FPC batch"
			// },
			{
				id:4,
				name:"aluminum substrate"
			}]
		},
		{
			id:4,
			name:'PCB Assembly',
			list:[{
				id:5,
				name:"SMT patch"
			},
			{
				id:6,
				name:"Steel sheet"
			}]
		},
		{
			id:5,
			name:'Components Supply',
			list:[]
		},
		{
			id:6,
			name:'Solutions',
			list:[{
				id:7,
				name:"scheme design"
			},
			{
				id:8,
				name:"Layout Design"
			}]
		},
		{
			id:7,
			name:'Factory Display',
			list:[{
				id:9,
				name:"Factory Display"
			},
			{
				id:10,
				name:"laminated structure"
			},
			{
				id:11,
				name:"Capability"
			},
			{
				id:12,
				name:"Product display"
			},
			{
				id:13,
				name:"Activity Topic"
			},
			{
				id:14,
				name:"Customer posting"
			}]
		},
		{
			id:8,
			name:'About KOIIOS',
			list:[{
				id:15,
				name:"About KOIIOS"
			},
			{
				id:16,
				name:"Contact Us"
			},{
				id:17,
				name:"News"
			}]
		}],
	},

	login:{
		content:{
			title:"Welcome to the official platform of KOIIOS",
			title2:"Welcome to register on the official platform of KOIIOS",
			text1:"Overseas original factory inventory, major agent spot goods, support online and offline transactions, and provide settlement methods in RMB and USD.",
			text2:"Based on customer and market needs, we have a regular stock of general materials in stock, commonly used brands such as Tl, ADI, MAXIM, MINI, and ATMEL, to provide a one-stop solution to customer BOM needs.",
			text3:"Gathering global spot and futures resources from Europe, America, and Asia, we will search for scarce and unpopular discontinued components to solve your research and production shortages and small batch material problems.",
			text4:"Adopting professional electronic microscopy equipment and professional inspection personnel to ensure the quality of components.",
			text5:"By using a large-scale centralized procurement method and comparing prices through multiple channels, we ensure the optimal price!",
			text6:"Advanced ERP push system continuously optimizes and compresses response time to ensure fast response!",
			button:"See more"
		},
		right:{
			title:"Welcome login",
			mobile:"Please enter an account",
			pword:"Please enter the password",
			remember:"remember pword",
			button1:"Login",
			button2:'Logging in',
			text1:"Don't have an account yet?",
			text2:"Register Now",
			text3:"Account login",
			text4:"Mobile login",
			text5:"Manage backend login",
			text6:"Return"
		}
	},
	register:{
		right:{
			title:"Register Now",
			phone:"Please enter your phone number",
			qq:"Please enter the QQ number",
			pword:"Please enter the password",
			confirmpword:"Please enter the password again",
			smscode:"Code",
			smscodeButton1:"Send code",
			smscodeButton2:"Resend",
			button1:"Register Now",
			button2:"Registering",
			button3:"Return to login",
			message:"Failed to obtain verification code"
		}
	}
}
