/**
 * 销售数据状态
 */
export const xs_dataState = {
  DFPXS: { code: 0, name: '待分配销售' },
  DFPCG: { code: 1, name: '待分配采购' },
  DCGBJ: { code: 2, name: '待采购报价' },
  DXSBJ: { code: 3, name: '待销售报价' },
  DXSXD: { code: 4, name: '待销售下单' },
  DHTSH: { code: 5, name: '待合同审核' },
  DCGXD: { code: 6, name: '待采购下单' },
  DCGSH: { code: 7, name: '待采购审核' },
  DRK: { code: 8, name: '待入库' },
  DCK: { code: 9, name: '待出库' },
  DQRWC: { code: 10, name: '待确认完成' },
  YWC: { code: 11, name: '已完成' },
  YTH: { code: 12, name: '已退货' },
  YQX: { code: 99, name: '已取消' }
}

export const xs_dataState_data = [
	{ code: 0, name: '待分配销售' },
	{ code: 1, name: '待分配采购' },
	{ code: 2, name: '待采购报价' },
	{ code: 3, name: '待销售报价' },
	{ code: 4, name: '待销售下单' },
	{ code: 5, name: '待合同审核' },
	{ code: 6, name: '待采购下单' },
	{ code: 7, name: '待采购审核' },
	{ code: 8, name: '待入库' },
	{ code: 9, name: '待出库' },
	{ code: 10, name: '待确认完成' },
	{ code: 11, name: '已完成' }
]

export const xsxj_dataState_data = [
	{ code: null, name: '全部' },
	{ code: 0, name: '待分配销售' },
	{ code: 1, name: '待分配采购' },
	{ code: 2, name: '待采购报价' },
	{ code: 3, name: '待销售报价' },
	{ code: 4, name: '待销售下单' },
	{ code: 5, name: '待合同审核' },
	{ code: 6, name: '已审核' }
]

export const xsxj_orderType_data = [
	{ code: '4', name: 'BOM' },
	{ code: '2', name: 'SMT' },
	{ code: '3', name: '钢网' },
	{ code: '1', name: 'PCB' },
]

export const xsdd_dataState_data = [
	{ code: null, name: '全部' },
	{ code: 6, name: '待采购下单' },
	{ code: 7, name: '待采购审核' },
	{ code: 8, name: '待入库' },
	{ code: 9, name: '待出库' },
	{ code: 10, name: '待确认完成' },
	{ code: 11, name: '已完成' }
	
]

export const xsxj_timeFrame_data = [
	{ code: 0, name: '全部' },
	{ code: 1, name: '最近一周' },
	{ code: 2, name: '最近一个月' },
]

/**
 * 销售数据状态
 */
export const role_data = {
    XTGLY: { id: 1, name: '系统管理员' },
    XSZG: { id: 11, name: '销售主管' },
    XSJL: { id: 14, name: '销售经理' },
    XSZJ: { id: 15, name: '销售总监' },
    CGJL: { id: 12, name: '采购经理' },
    GG: { id: 16, name: '高管' }

}
