import store from '../../store/index.js'
export default {
	right:{
		qq:{
			text1:"在线咨询",
			text2:"在线沟通，请点击我",
			button:"在线咨询"
		},
		phone:{
			text:"联系我们"
		},
		opinion:{
			title:"提交反馈",
			text1:"姓名",
			input1:'请输入姓名',
			text2:"公司名称",
			input2:"请输入公司名称",
			text3:"手机号码",
			input3:"请输入手机号码",
			text4:"反馈内容",
			input4:"请输入内容",
			text5:"提交反馈",
			text6:"邮箱",
			input5:'请输入邮箱',
			text7:"微信小程序二维码：",
			text8:"公众号二维码：",
			text9:"提交反馈中"
		}
	},
	home:{
		carousel:{
			selectTabs: [{
					id: 1,
					name: 'PCB计价'
				},
				{
					id: 2,
					name: 'SMT打样'
				},
				{
					id: 3,
					name: '钢网下单'
				},
				{
					id: 4,
					name: 'BOM配单'
				}
			],
			dimensions:"板子尺寸：",
			layers:"层数：",
			quantity:"数量：",
			thickness:"厚度：",
			length:"长/y",
			width:"宽/x",
			please:"请选择板子数量",
			please2:"请选择板子层数",
			please3:"请选择板子厚度",
			button:"立即报价"
		},
		catalogue:{
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/home/pcb.png',
				title:"PCB打样",
				text:"200，000m²工厂，月出货量8万m²，日出货3000余款。PCB在线下单，方便快捷",
				content:"单双面，层数可做至20层，可阻控HDI盲埋、铝基板、FPC、高频板等",
				id:1
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/home/smt.png',
				title:"SMT贴片",
				text:"自有SMT工厂，数十台雅马哈，高速贴片机，可贴01005，日出货250款。",
				content:"一片起贴，打样不收开机费工程费8小时加急",
				id:2
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/home/BOM@img.png',
				title:"BOM配单",
				text:"自有阻容感库存，快速报价，当天回复。",
				content:"3小时报价，6小时出货，散料出货无须按小包装定货",
				id:3
			},
			// {
			// 	img:process.env.VUE_APP_OSS_URL + '/home/layout.png',
			// 	title:"方案设计",
			// 	text:"硬件开发，软件开发，云服务开发，工业设计",
			// 	content:"无线通信，智能家居，智慧城市、消费电子、工业物联网、AI人机交互",
			// 	id:4
			// },
			{
				img:process.env.VUE_APP_OSS_URL + '/home/layout.png',
				title:"钢网",
				text:"从PCB到钢网，元器件，一站式搞定",
				content:"网面平整，张力高，硬度搞耐高温，当天发货，最快4小时",
				id:4
			}
			]
		},
		specialSale:{
			leftTitle:{
				title:"全球65万家客户选择格物思",
				text:"自有工厂，可12小时急速出货。无论多急，都能如期。",
				button:"立即报价",
			},
			rightContent:{
				labelList:['可PCB打样','可中小批量生产','可SMT贴装','可BOM配单'],
				list:[{
					title:'单双面板',
					type:0,
					content:['10x10cm内,5片','24小时出货无加急费','全国包邮'],
					price:30
				},
				{
					title:'四层板',
					type:1,
					content:['10x10cm内,5片','正常出货4~5天','可24/48小时加急'],
					price:100
				},
				{
					title:'六层板',
					type:2,
					content:['10x10cm内,5片','正常出货5天','可24小时急速出货'],
					price:800
				}],
				button2:"特价购买",
				flowPath:{
					title:"下单流程：",
					text1:"注册登录",
					text2:"在线计价",
					text3:"审核确认",
					text4:"在线付款",
					text5:"加工制造",
					text6:"收货评价",
					text7:"交易完成"
				}
			}
		},

		srvice:{
			title:"格物思电子全产业链智造互联平台",
			text:"整合PCB全产业供应链,互联网+工业上下协同,工程极速、交付极速、送达极速",
			list:[{
				title:'供应互联',
				image:require('@/assets/img/interconnection.png'),
				content:['A级板材 , 原厂直供','战略合作 , 准备充沛','高精设备 , 产能稳定'],
			},
			{
				title:'智能配单',
				image:require('@/assets/img/matchingOrder.png'),
				content:['智能计价系统','报价透明','1v1疑问解答'],
			},
			{
				title:'智能工程',
				image:require('@/assets/img/engineering.png'),
				content:['强大工程处理力','提速70%','下达产线快至30分钟'],
			},
			{
				title:'极速送达',
				image:require('@/assets/img/logistics.png'),
				content:['两大生产基地','六大服务网点','完善物流体系'],
			},
			{
				title:'及时响应',
				image:require('@/assets/img/respond.png'),
				content:['售后快速响应','无推诿','99.9%好评率'],
			}]
		},

		accordion:{
			title:"特殊工艺",
			text:"别家做不了的，我们都可以做！",
			button:"立即报价",
			content1:{
				title:"FPC软板",
				content:"材料结构: 双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜。",
				label1:"板厚：0.13mm",
				label2:"补强: 无补强",
				label3:"制作工艺: 沉金工艺",
				label4:"阻焊: 黄膜白字",
				label5:"铜厚: 0.5oz",
				label6:"线宽线距: ≥4mil"
			},
			content2:{
				title:"HDI盲埋孔",
				label1:"层数/板厚: 4-20层/0.4-3.0MM",
				label2:"孔径: ≥0.10mm-0.15mm",
				label3:"表面处理: 喷锡/沉金/OSP/沉银/沉锡/电金",
				label4:"盲埋阶数: 1-4阶；任意阶；",
				label5:"填孔方式: 树脂填孔(真空树脂塞孔)/电镀填孔(VCP自动填孔线，进口填孔药水)",
				label6:"产品类型: FR4 HDI盲埋/高频板材+FR4 HDI盲埋/纯高频材料HDI盲埋 ；样板、批量均可接；"
			},
			content3:{
				title:"高精密阻抗板",
				label1:"层数/板厚/铜厚： 2-20层/0.2-3.0MM/1-3oZ",
				label2:"线宽/线距： min 3/3mil",
				label3:"孔径： ≥0.15mm",
				label4:"阻值范围： 50-125Ω",
				label5:"阻值公差： +/-10%",
				label6:"产品类型： 普通FR4阻抗板；高频阻抗板；高频+FR4混压阻抗板；",
				label7:"备注：我司可提供阻抗测试条及阻抗测试报告；"
			},
			content4:{
				title:"铜基/铝基板",
				label1:"层数/板厚/铜厚: 1-2层/0.8-3.0MM/1-10oZ",
				label2:"导热系数: 1-3W(高导热绝缘胶)",
				label3:"表面处理: 喷锡/沉金/OSP",
				label4:"产品类型: 普通单面铜/铝基；双面混压FR4+铜/铝基；双面夹芯铝基；热电分离铜/铝基；",
			}
		},

		aboutUs:{
			title:"全球足迹",
			text:"格物思电子智能硬件智造平台",
			content1:{
				title:"服务国家和地区",
				text:"余个"
			},
			content2:{
				title:"全球客户数",
				text:"余家"
			},
			content3:{
				title:"日常出货数",
				text:"余款"
			},
			content4:{
				title:"员工总数",
				text:"余人"
			},
			button:"了解更多"
		},

		suningSheet:{
			title:"客户晒单",
			label:"好评率100%",
			text:"速度快! 服务好! 品质不错! 五星好评!",
			list:[{
				data:[{
					name: '李先生/女士',
					value: 5,
					title: '10*10*cm ,1.6mm,2层,10片',
					label: ['PCB', '24H'],
					content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在格物思打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
					time: '2023-03-29',
					image:require('@/assets/img/sunning.jpg')
				}]
			}],
			list2:[{
				data:[{
					name: '李先生/女士',
					value: 5,
					title: '10*10*cm ,1.6mm,2层,10片',
					label: ['PCB', '24H'],
					content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在格物思打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
					time: '2023-03-29',
					image:require('@/assets/img/sunning.jpg')
				}]
			}]
		},

		news:{
			title:"新闻资讯",
			text:"将想法与焦点与您一起共享",
			button:"查看更多",
			list:[{
				image:require('@/assets/img/newbg.png'),
				time:'2023.03.20',
				title:'2015中国（成都）电子展',
				content:'2015年7月16日-18日，芯航国际（XxinTek）参加了2015中国（成都）电子展，芯航国际展位设在成都世纪城新国际会展中心3号馆C247。芯航国际主要以英国光电厂牌PHOTE全线产品授权代理、芯商城（www.gchips.com）线上交易平台、海外原厂代理一手货源价格低货期短、军品优势'
			}]
		},

		bottom:{
			text1:"首页",
			text2:"个人中心",
			text3:"订单",
			text4:"积分商城"
		}
	},

	valuation:{
		tabs:{
			text1:"PCB计价",
			text2:"FPC柔性板",
			text3:"SMT计价",
			text4:"钢网计价",
			text5:"BOM配单"
		},
		order:{
			title1:"发票类型",
			title2:"发票方式",
			title3:"收据及合同抬头",
			title4:"下单联系人",
			title5:"技术联系人",
			title6:"收货地址",
			button1:"新增地址",
			button2:"修改",
			input1:'姓名',
			input2:"手机号"
		},
		pcb:{
			title1:"PCB规格选择",
			text1:"HDI盲埋板、阻抗板全面开放接单，且提供优质高Tg板材选择，最新制程能力",
			title2:"个性化要求",
			text2:"（金手指、半孔、客户要求叠层、金属化包边，产品类型）",
			title3:"特殊工艺选择",
			text3:"（金手指、半孔、客户要求叠层、金属化包边）",
			text4:"盲埋孔，阻抗控制等特殊工艺以人工核价为准",
			title4:"填写下单人信息",
			text5:"温馨提示：定单收货人信息默认为用户注册时的相关信息，可针对此订单进行修改。",
			boardElement:{
				title1:"出货方式",
				content2:"异形、圆形的PCB，或者尺寸小于10*10CM的板子，不论是手工焊接还是上机贴片，小批量制作的订单，我们是建议拼版出货，拼版后可提高焊接效率和减少焊接加工费用。具体的拼版方式可以根据SMT加工厂的制程要求来确定。也可以咨询格物思客服人员，我们会给出最合理的建议。",
				title2:"板子尺寸",
				content2:"板子大小指的是客户投单板子的长宽，如果是圆形板，长宽尺寸就是圆的直径；如果是外形不规则的电路板，长宽尺寸则按最大的外形范围测量；注：多款电路板打一个压缩包，不知道拼版后尺寸多少，则长宽可随意填写一个大小，格物思工作人员会按下单板子拼版后的尺寸进行计算。板子的大小都有公差范围，外形的正常公差是±0.2mm左右；如果对外形公差另有要求时，请在其它要求中备注，没有备注的则视为认同公示的公差大小。外形必须设计在同一层，即keep out层或机械层，不要在2个层分别设计，否则容易出错。如有不清楚的，请找工作人员咨询。",
				input1:"长/y",
				input2:"宽/x",
				title3:"板子数量",
				content3:"面积一平米内，数量50pcs内为样板，超出此范围则为小批量。",
			},
			boardElementTwo:{
				title1:"设计文件数",
				content1:'拼版个数指的是同一个文件中包含几个不同的板子时，收取合拼费，如果是同一款板拼版则不收合拼费。拼版划分：电路板中间打邮票孔、开槽、V割、直接铣开都算拼版。',
				input1:'请输入文件数',
				title2:'板材类型',
				content2:'指的是板材厂商及档次最高的板料，板材的绝缘性、均匀性、阻抗稳定性及铜皮跟板料基材的可靠性，远远高于同类产品中的B、C级料，可用于生产要求绝对苛刻的军工电子用品！低档次板材B、C级料：板材制造商，因为电路板厂商低成本的需求而生产的板料，板材厂商在制造的过程中，以次充好，偷工减料，其性能稳定性远远不如军工级的A级料，并且价格远远低于A级料。',
				title3:"板子层数",
				content3:"格物思提供的板子层数选择：1为单面板，2为双面板，4为四层板，6为六层板，层数越大相应费用越高。注：格物思为了工作效率，样板基本上是拼版生产，有一部分单面板会跟双面板拼在一起生产，即单面板按双面板工艺生产，孔内会有铜但不会影响性能，可靠性能更高。如果电路板的设计是单面线路，要做孔内有铜的效果，投单时选双面板即可。PS：单面板和双面板的打样费用差不多。",
				title4:"板材/TG",
				text1:"板厚<1.0公差+/-0.1mm，板厚≥1.0mm公差+/-10%",
				title5:"板子厚度",
				content5:"板子厚度是指电路板的厚度，在这说明一下板厚的公差，公司采用的是KB军工A级料，公差相对较小，正常板厚在10%左右；10%主要是因为印阻焊和沉铜引起的；如果对板厚公差另有要求，请在其它要求项备注，否则视为按正常板厚公差。注：选板厚跟价格也有关联，0.6-1.6为正常板厚的价格，0.4、2.0板厚价格相对0.6-1.6正常板厚高100元左右。",
				text2:"板厚<1.0公差+/-0.1mm，板厚≥1.0mm公差+/-10%",
				title6:"铜箔厚度",
				content6:"普通板铜箔厚度为1 OZ（35um），工艺需求时可选2OZ（70um）铜箔厚度，在此需注意：2 OZ铜厚的费用肯定要比1 OZ铜厚稍贵些。",
				title7:"最小线宽/线距",
				title8:"最小过孔",
				title9:"阻焊颜色",
				content7:"部分特殊颜色加收颜色费,如哑光绿、哑光黑以及黄油黑字或绿油黑字等",
				title10:"字符颜色",
				content8:"指的是电路板字符色，我们只提供白色，如果阻焊油墨选白色时，字符为黑色。",
				title11:"焊盘喷镀",
				content9:"指的是电路板的表面处理，1、表面处理有铅喷锡不收费，无铅喷锡加收20元，沉金加收100元；2、在实际操作过程中，下单时选喷锡，为了效率及交期，有少部分电路板可能会拼在沉金板中生产，沉金的成本大大高于喷锡，且性能上是没有问题的，如果你不接受此项，请在其它要求备注不能更改工艺。",
				title12:"阻焊覆盖",
				content10:"此项经常存在争议，重点说明。现在提供三种：（1）过孔盖油 （2）过孔开窗 （3）gerber文件按文件加工，因为gerber文件中，没法分清过孔和插键孔，只能按文件加工；如果提供的是原文件，则过孔有属性，工厂在转菲林文件时会注意。如有此类不清楚的，可致电工作人员咨询。",
				text3:"如是gerber文件，一律按文件加工，此选项无效！",
				title13:"测试方式",
				content11:"抽测试不收取费用，直通率95%以上，但只是针对比较简单，线宽线距比较大且稀疏、孔也比较大的板子，选择抽测试的订单，将允许存在一定比例的不良品，请慎重选择。"
			}
		},
		FPC:{
			title1:"FPC规格选择",
			text1:"HDI盲埋板、阻抗板全面开放接单，且提供优质高Tg板材选择，最新制程能力",
			title2:"个性化要求",
			text2:"（金手指、半孔、客户要求叠层、金属化包边，产品类型）",
			flexibleBoard:{
				tilte1:"出货方式",
				content1:"异形、圆形的PCB，或者尺寸小于10*10CM的板子，不论是手工焊接还是上机贴片，小批量制作的订单，我们是建议拼版出货，拼版后可提高焊接效率和减少焊接加工费用。具体的拼版方式可以根据SMT加工厂的制程要求来确定。也可以咨询格物思客服人员，我们会给出最合理的建议。",
				title2:"板子尺寸",
				content2:"板子大小指的是客户投单板子的长宽，如果是圆形板，长宽尺寸就是圆的直径；如果是外形不规则的电路板，长宽尺寸则按最大的外形范围测量；注：多款电路板打一个压缩包，不知道拼版后尺寸多少，则长宽可随意填写一个大小，格物思工作人员会按下单板子拼版后的尺寸进行计算。板子的大小都有公差范围，外形的正常公差是±0.2mm左右；如果对外形公差另有要求时，请在其它要求中备注，没有备注的则视为认同公示的公差大小。外形必须设计在同一层，即keep out层或机械层，不要在2个层分别设计，否则容易出错。如有不清楚的，请找工作人员咨询。",
				title3:"板子数量",
				input1:"长/y",
				input2:"宽/x",
				content3:"面积一平米内，数量50pcs内为样板，超出此范围则为小批量。"
			},
			flexibleBoardTwo:{
				title1:"设计文件数",
				content1:"拼版个数指的是同一个文件中包含几个不同的板子时，收取合拼费，如果是同一款板拼版则不收合拼费。拼版划分：电路板中间打邮票孔、开槽、V割、直接铣开都算拼版。",
				input1:"请输入文件数",
				title2:"板子层数",
				content2:"格物思提供的板子层数选择：1为单面板，2为双面板，4为四层板，6为六层板，层数越大相应费用越高。注：格物思为了工作效率，样板基本上是拼版生产，有一部分单面板会跟双面板拼在一起生产，即单面板按双面板工艺生产，孔内会有铜但不会影响性能，可靠性能更高。如果电路板的设计是单面线路，要做孔内有铜的效果，投单时选双面板即可。PS：单面板和双面板的打样费用差不多。",
				title3:"材质",
				title4:"板子厚度",
				content3:"板子厚度是指电路板的厚度，在这说明一下板厚的公差，公司采用的是KB军工A级料，公差相对较小，正常板厚在10%左右；10%主要是因为印阻焊和沉铜引起的；如果对板厚公差另有要求，请在其它要求项备注，否则视为按正常板厚公差。注：选板厚跟价格也有关联，0.6-1.6为正常板厚的价格，0.4、2.0板厚价格相对0.6-1.6正常板厚高100元左右。",
				title5:"铜箔厚度",
				content4:"普通板铜箔厚度为1 OZ（35um），工艺需求时可选2OZ（70um）铜箔厚度，在此需注意：2 OZ铜厚的费用肯定要比1 OZ铜厚稍贵些。",
				title6:"阻焊/覆盖膜",
				title7:"字符颜色",
				content5:"指的是电路板字符色，我们只提供白色，如果阻焊油墨选白色时，字符为黑色。",
				title8:"焊盘喷镀",
				title9:"沉金厚度",
				title10:"补强",
				title11:"背胶",
				title12:"电磁膜",
				title13:"最小线宽/线距",
				title14:"最小过孔",
				title15:"测试方式",
				content6:"抽测试不收取费用，直通率95%以上，但只是针对比较简单，线宽线距比较大且稀疏、孔也比较大的板子，选择抽测试的订单，将允许存在一定比例的不良品，请慎重选择。"
			}
		},
		individualization:{
			title1:"确认生产稿",
			content1:"格物思已通过UL认证，勾选了需要选项，格物思将在顶层字符的合适的位置添加UL标识"
		},
		SMT:{
			title1:"SMT规格填写",
			text1:"特别注意：如果有PCB订单，必须填写PCB订单号，以免出错，产生不必要的误会。",
			specificationFilling:{
				title1:"元器件数量",
				content1:"填写数量为单板元件数量,非单板物料种类、若不知数量，请填写1",
				title2:"电路板数量",
				content2:"请填写单片(PCS)数量",
				title3:"工艺需求",
				content3:"代购元件交齐不包含在贴片交期内",
				title4:"是否开钢网",
				content4:"代购元件交齐不包含在贴片交期内",
				title5:"代购元件",
				content5:"代购元件交齐不包含在贴片交期内",
				title6:"是否喷三防漆",
				title7:"元器件是否需要烘烤",
				title8:"其它需求",
				title9:"烘烤时间"
			}
		},
		steel:{
			title:"钢网规格填写",
			content1:"特别注意：如果有PCB订单，必须填写PCB订单号，以免出错，产生不必要的误会。",
			steel:{
				title1:"钢网类型",
				title2:"钢网规格",
				title3:"纳米处理",
				title4:"制作方式",
				title5:"开钢网数量",
				title6:"MARK需求",
				content1:"MARK点是PCB应用于设计中的自动贴片机上的位置识别点，也被称为基准点。直径为1MM。MARK点又分半刻和通孔两种，半刻是在钢片上切了一个凹槽，而通孔是刻穿了钢片。 全自动印刷机的锡膏钢网大都采用MARK点半刻。手工印刷及半自动印刷的锡膏钢网,不需要MARK点都可以使用。 但红胶网工艺,全自动印刷机的,都是用半刻。如果是手工印刷的, 都是找些通孔做定位孔,方便对位。 一、若选了MARK点而板上无MK的，此选项无效（大钢网，若可以用通孔或插件孔代替的，请说明。） （附：胶水网无论选取MK与否，我司都会做定位孔，无MK的，会用通孔或插件孔作为定位孔） 二、（1）若文件只有一层贴片而没丝印，显示GTP（TP）的，默认为正面，不镜相，层名显示GBP（BP）的，为反面，则需要镜相。没有层名显示的，直接制作，不镜相（若底层已镜相，请说明） （2）若文件在同一层有丝印且已镜相的，丝印应该全部为正（此处请注意，文件全部都已镜相的，请说明）",
				title7:"钢网用途",
				title8:"厚度",
				title9:"抛光工艺",
				title10:"其它需求"
			}
		},
		quotation:{
			title:"PCB报价和交期",
			text1:"工程费用",
			text2:"板费",
			text3:"菲林费",
			text4:"拼板费",
			text5:"颜色费用",
			text6:"喷镀费",
			text7:"测试费",
			text8:"加急费",
			text9:"税费",
			text10:"特殊工艺费",
			text11:"优惠费用",
			text12:"其他费用",
			text13:"总计",
			button:"提交订单"
		},
		fileUpload:{
			text1:'上传一个BOM配单',
			text2:'（仅支持xls、xlsx、csv格式）文件大小 < 20MB',
			text3:'为能更快、更准确的给您报价及生产，请您尽可能的提供以下要求的完整文件!',
			title1:'BOM文件上传',
			title2:'BOM规格填写',
			title3:'请填写BOM订单信息，上传BOM文件',
			title4:'采购数量',
			text4:'请填写BOM套数',
			text5:'以BOM列出的订单物料数量',
			title5:'采购备注',
			text6:'文件已上传，文件大小',
			button1:'删除',
			button2:'选择属性',
			price:'审核后报价'
		}
	},

	boardMaking:{
		pcbBoardMaking:{
			title:"PCB制板和服务",
			text:"致力于为全球研发创新企业提供PCBA打样/小批量生产服务，是全国首批专业PCB打样厂商，并拥有国内知名的海外PCB销售平台，同时为200多个国家和地区服务。",
			button:"立即定制",
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon.png',
				title:'PCB打样',
				lable1:'1~14层',
				lable2:'FR-4 TG150/TG170',
				content1:'可8小时加急，当日下单，当日发货',
				content2:'全国包邮，为您节省高额运费'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon2.png',
				title:'PCB小批量',
				lable1:'线宽线距≥3/3mil',
				lable2:'过孔≥0.15mm',
				content1:'直通率达99%以上，5㎡内仅需3天发货',
				content2:'10平米以上费用全部9折优惠'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon3.png',
				title:'高精密PCB板',
				lable1:'线宽线距≥3/3mil',
				lable2:'过孔≥0.15mm',
				content1:'原材料生益，台鸿等品牌质量有保证',
				content2:'可做软硬结合、FPC多层板、排线等'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon4.png',
				title:'FPC柔性板',
				lable1:'1~8层',
				lable2:'压延铜/电解铜',
				content1:'直通率达99%以上，5㎡内仅需3天发货',
				content2:'10平米以上费用全部9折优惠'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon5.png',
				title:'铜基板',
				lable1:'厚铜板/裸铜板',
				lable2:'导热系数2-3W',
				content1:'原材料生益，台鸿等品牌质量有保证',
				content2:'可做软硬结合、FPC多层板、排线等'
			},{
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/pcb-icon6.png',
				title:'铝基板',
				lable1:'铝基板/混压铝基板',
				lable2:'导热系数1-3W',
				content1:'铝板+高导热绝缘材料，高导热性能',
				content2:'可做8层混压铝基板'
			}]
		},

		specialProcesses:{
			title1:"PCB特殊板",
			text1:"可定制特殊工艺/特殊板材—您想要的我们都有",
			title2:"01.可定做特殊工艺",
			text2:"格物思所有产品出厂前高于行业的标准",
			content1:"阻抗板",
			content2:"特殊油墨 （哑光油墨，紫色等）",
			content3:"盲埋孔",
			content4:"超长、超薄板",
			content5:"沉头孔",
			content6:"OSP工艺",
			content7:"厚铜板、裸铜板",
			content8:"高频高速HDI",
			button:"立即定制",
			title3:"02.可定做特殊板材",
			text3:"甄选优质原材料严格检测捍卫品质第一关",
			tabs:[{
				name:"罗杰斯",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example1.png',
				list:[{
					name:"优质的板材",
					text:"电路板核心原材料板材，采用A级军工料，质量非常有保证"
				},
				{
					name:"精致的印刷工艺",
					text:"采用广信感光油墨，符合环保标准，经高温烘烤，油墨色泽光鲜 艳丽，字符清晰"
				},
				{
					name:"精细化的制作过程",
					text:"多种表面处理工艺，所有产品出厂前，品质高于行业的标准"
				},
				{
					name:"快捷更准时的配送",
					text:"借助于国内快捷的速递网络，我们将在承诺的时间内，及时将样 板送达您的手中"
				}],
			},
			{
				name:"铜基板",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example2.png',
				list:[{
					name:"优质的板材",
					text:"电路板核心原材料板材，采用A级军工料，质量非常有保证"
				},
				{
					name:"精致的印刷工艺",
					text:"采用广信感光油墨，符合环保标准，经高温烘烤，油墨色泽光鲜 艳丽，字符清晰"
				},
				{
					name:"精细化的制作过程",
					text:"多种表面处理工艺，所有产品出厂前，品质高于行业的标准"
				},
				{
					name:"快捷更准时的配送",
					text:"借助于国内快捷的速递网络，我们将在承诺的时间内，及时将样 板送达您的手中"
				}],
			},
			{
				name:"双面铝基板",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example3.png',
				list:[{
					name:"优质的板材",
					text:"电路板核心原材料板材，采用A级军工料，质量非常有保证"
				},
				{
					name:"精致的印刷工艺",
					text:"采用广信感光油墨，符合环保标准，经高温烘烤，油墨色泽光鲜 艳丽，字符清晰"
				},
				{
					name:"精细化的制作过程",
					text:"多种表面处理工艺，所有产品出厂前，品质高于行业的标准"
				},
				{
					name:"快捷更准时的配送",
					text:"借助于国内快捷的速递网络，我们将在承诺的时间内，及时将样 板送达您的手中"
				}],
			},
			{
				name:"FPC软板",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example4.png',
				list:[{
					name:"优质的板材",
					text:"电路板核心原材料板材，采用A级军工料，质量非常有保证"
				},
				{
					name:"精致的印刷工艺",
					text:"采用广信感光油墨，符合环保标准，经高温烘烤，油墨色泽光鲜 艳丽，字符清晰"
				},
				{
					name:"精细化的制作过程",
					text:"多种表面处理工艺，所有产品出厂前，品质高于行业的标准"
				},
				{
					name:"快捷更准时的配送",
					text:"借助于国内快捷的速递网络，我们将在承诺的时间内，及时将样 板送达您的手中"
				}],
			},
			{
				name:"软硬结合板",
				img:process.env.VUE_APP_OSS_URL + '/boardMaking/example5.png',
				list:[{
					name:"优质的板材",
					text:"电路板核心原材料板材，采用A级军工料，质量非常有保证"
				},
				{
					name:"精致的印刷工艺",
					text:"采用广信感光油墨，符合环保标准，经高温烘烤，油墨色泽光鲜 艳丽，字符清晰"
				},
				{
					name:"精细化的制作过程",
					text:"多种表面处理工艺，所有产品出厂前，品质高于行业的标准"
				},
				{
					name:"快捷更准时的配送",
					text:"借助于国内快捷的速递网络，我们将在承诺的时间内，及时将样 板送达您的手中"
				}],
			}],
			text4:"部分特殊板材产品展示",
			text5:"工厂环境",
			text6:"工艺能力",
			text7:"产品展示",
			textImage1:process.env.VUE_APP_OSS_URL + '/boardMaking/point1.png',
			textImage2:process.env.VUE_APP_OSS_URL + '/boardMaking/point2.png',
			textImage3:process.env.VUE_APP_OSS_URL + '/boardMaking/point3.png',
		},

		selectKoiios:{
			title:"为什么选择格物思",
			text1:"服务国家和地区",
			text2:"全球客户数",
			text3:"月出货量",
			text4:"日常流程订单",
			text5:"为客户提供智能方案设计、PCB制造加工、SMT器件贴装、产品调试、组装等一站 式服务，打造一条与客户关系紧密， 利益取向一致的价值链体系。",
			text6:"旗下拥有“PCB”/“PCBWAY”/“PCBGOGO”/“格物思”等四大品牌，采用英语、日语、西班牙语等多语种互联网站点布局全球。",
			button:"了解更多"
		},
	},

	pcbBatch:{
		bulkJuhui:{
			title1:"一价到底 • 批量钜惠",
			text1:"以匠心品质为基石，以快捷而闻名，一对一的客户服务，为您提供更加优质的产品和服务体验！",
			title2:"双面板 · 国纪AB0板材特惠",
			img:process.env.VUE_APP_OSS_URL + '/pcbBatch/special-offer4@img.png',
			list:[{
				name:"板厚:0.8—1.6mm",
				type:false
			},
			{
				name:"孔径:>0.3mm(12mil)",
				type:false
			},
			{
				name:"江浙沪皖赣粤6省包邮",
				type:true
			},
			{
				name:"孔密度:8万/m²内",
				type:false
			},
			{
				name:"大批量需求可以询价",
				type:true
			},
			{
				name:"线宽线距: 6/6mil",
				type:false
			}],
			content:'PS:应用于低电压低电流的玩具板、普通CTI家用电器板及P4-LED灯板。适用插件孔径孔距大于1.20mm的线路，低电流低电压要求的儿童玩具、家用遥控嚣等要求较低的快速消费产品。',
			priceTitle:'20m²起',
			price:'248元/m²起',
			singlePanel:{
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/special-offer5@img.png',
				title:'单面板',
				label:['板材·国纪A01','板厚· 1.0——1.6mm'],
				list:[{
					name:'丝印冲模',
					type:false
				},
				{
					name:'OSP',
					type:false
				},
				{
					name:'绿油白字',
					type:false
				},
				{
					name:'免测试费',
					type:true
				},
				{
					name:'100m²以上起定',
					type:true
				}],
				price:'140元/m²起'
			},
			nonFlameRetardant:{
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/offer3@img.png',
				title:'非阻燃双面板',
				label:['板厚· 0.6——1.6mm'],
				list:[{
					name:'有铅喷锡',
					type:false
				},
				{
					name:'沉铜工艺',
					type:false
				},
				{
					name:'全连线LDI',
					type:false
				},
				{
					name:'全线VCP电镀',
					type:true
				}],
				price1:'190元/m²起',
				price2:'170元/m²起'
			},
			fourLayerBoard:{
				title:"四层板",
				label:"高性价比",
				list:[{
					name:"板厚:0.8—1.6mm",
					type:false
				},
				{
					name:"孔径:>0.3mm(12mil)",
					type:false
				},
				{
					name:"1OZ",
					type:false
				},
				{
					name:"孔密度:8万以内",
					type:false
				},
				{
					name:"阻焊：绿油白字",
					type:false
				},
				{
					name:"线宽线距: 5/5mil",
					type:false
				},
				{
					name:"5m²以上起定",
					type:true
				}],
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/offer2.png',
				content:'PS:广泛应用于计算机及外围设备、通讯设备、消费电子、汽车电子及工业应用领域等。',
				price:'498元/m²起'
			}
		},

		pcbBothHave:{
			title:"PCB • 你想要的 我们都有",
			text:"多种板材/工艺，专做别人搞不定的难度板！",
			list:[{
				title:'PCB高多层板',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want1@img.jpg',
				lable:[{
					name:'1~30层',
					type:true
				},
				{
					name:'混压/纯压多层板',
					type:false
				},
				{
					name:'高TG、耐CAF板材',
					type:false
				},
				{
					name:'线宽/距：3mil',
					type:false
				}]
			},
			{
				title:'HDI盲埋/阻抗控制',
				type:true,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want2@img.jpg',
				lable:[{
					name:'高精密阻抗控制',
					type:false
				},
				{
					name:'常见1-3阶',
					type:false
				},
				{
					name:'可做任意阶',
					type:true
				}]
			},
			{
				title:'铝基板/混压铝基板',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want3@img.jpg',
				lable:[{
					name:'1-4层纯压/混压铝基板',
					type:false
				},
				{
					name:'可做双面混压/夹芯铝基板',
					type:false
				},
				{
					name:'导热系1-4w（可定制4-8w）',
					type:true
				},
				{
					name:'热电分离>100w',
					type:false
				}]
			},
			{
				title:'铜基板/热电分离铜基板',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want4@img.jpg',
				lable:[{
					name:'单双面铜基板',
					type:false
				},
				{
					name:'可做双面混压/夹芯铝基板',
					type:false
				},
				{
					name:'热电分离铜基板>380w',
					type:true
				}]
			},
			{
				title:'罗杰斯Rogers/陶瓷基板',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want5@img.jpg',
				lable:[{
					name:'Rogers全系材料',
					type:false
				},
				{
					name:'陶瓷基板',
					type:false
				},
				{
					name:'高频高速板',
					type:true
				},
				{
					name:'纯压/混压',
					type:false
				}]
			},
			{
				title:'其它类',
				type:false,
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/want6@img.jpg',
				lable:[{
					name:'可做厚铜板电源板、台阶板',
					type:false
				},
				{
					name:'沉头孔、控深槽/孔',
					type:false
				},
				{
					name:'高频高速板',
					type:true
				},
				{
					name:'纯压/混压',
					type:true
				}]
			}]
		},

		chooseUs:{
			title:"为什么选择我们？",
			text:"无起订量，高性价比和快速优势，产品质量有保障，都是您值得信赖的理由！",
			img:process.env.VUE_APP_OSS_URL + '/pcbBatch/why-choose1@chimg.png',
			content1:{
				title:"价格优势",
				text1:"价格优势：1单1价，可一口价/协议价",
				text2:"量大价更优"
			},
			content2:{
				title:"良品率",
				text1:"格物思所有产品出厂前高于行业的标准",
				text2:"四线测试保证产品直通率达99%以上"
			},
			content3:{
				title:"结算方式多样化",
				text:"多种付款结算方式————总有一个满足您！",
				img:process.env.VUE_APP_OSS_URL + '/pcbBatch/system-supply1@chimg.png',
			}
		},

		certificate:{
			title:"质量认证体系",
			text:"格物思获得设计发明类、实用新型类专利和软件著作权共计60余项",
			label:['"专精特新"企业','高新技术企业','IATF 16949汽车质量管理体系认证'],
		},
		aboutUs:{
			title:"走进格物思",
			text:"全球65万客户信赖的格物思，可12小时加急出货。无论多急，都能如期"
		}
	},

	fpcBatch:{
		fpcHots:{
			title1:"FPC柔性电路板",
			title2:"爆款抢购",
			text:"24小时急速打样，72小时批量出货",
			warp:{
				title:"实时订单",
				gaugeOutfit:{
					text1:"用户",
					text2:"时间",
					text3:"订单号",
					text4:"金额",
				},
				text1:"板子尺寸（cm）",
				text2:"层数",
				text3:"数量",
				text4:"厚度",
				placeholder1:"长/y",
				placeholder2:"宽/x",
				placeholder3:"选择层数",
				placeholder4:"选择数量",
				placeholder5:"选择厚度",
				rightTitle:"热销产品",
				content1:{
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-product1.png',
					title:"FPC单双面板",
					text:"5x5cm/5片，交期3~4天",
					label1:"板厚0.13，黄膜白字，无补强",
					label2:"铜厚0.5oz；沉金工艺；",
					label3:"线宽线距≥4mil",
					text2:"热销价",
					text3:"原价",
					button:"立即购买"
				},
				content2:{
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-product2.png',
					title:"FPC四层板",
					text:"5x5cm/5片，交期7~8天",
					label1:"板厚0.23，黄膜白字，无补强，",
					label2:"铜厚0.5oz；沉金工艺；",
					label3:"线宽线距≥4mil",
					text2:"热销价",
					text3:"原价",
					button:"立即购买"
				},

				content3:{
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-product3.png',
					title:"四层软硬结合板",
					text:"交期10~12天，可做（1~8层）",
					label1:"普通工艺，黄膜白字，软板板厚0.13",
					label2:"铜厚0.5oz；沉金工艺；硬板板厚0.6~1.6；",
					label3:"线宽线距≥4mil",
					text2:"热销价",
					text3:"资讯报价",
					button:"立即购买"
				}

			}
		},

		service:{
			title:"产品和服务",
			text:"格物思首推CRM在线管理系统，将大数据、云计算等智能技术、网络技术和自动化制造技术等应用于销售服务、工厂管理、生产制造、品质控制的全过程中，彻底颠覆传统订单管理和生产模式",
			text2:"产品参数",
			text3:"板厚",
			text4:"阻焊",
			text5:"补强",
			text6:"铜厚",
			text7:"制作工艺",
			text8:"线宽线距",
			text9:"材料结构",
			text10:"产品保障",
			tabs:[{
				name:'单面板层金',
				content:{
					plateThickness:"0.1mm",
					ResistanceWelding:"黄膜白字",
					reinforce:"无补强",
					copperThickness:"1/2OZ",
					technology:"沉金工艺",
					lineWidth:"≥3mil",
					structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer1.jpg',
				}
			},
			{
				name:'双面沉金',
				content:{
					plateThickness:"0.13mm",
					ResistanceWelding:"黑膜白字",
					reinforce:"无补强",
					copperThickness:"0.5OZ",
					technology:"沉金工艺",
					lineWidth:"≥3mil",
					structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer2.jpg',
				}
			},
			{
				name:'四层沉金',
				content:{
					plateThickness:"0.23mm",
					ResistanceWelding:"绿膜白字",
					reinforce:"无补强",
					copperThickness:"0.5OZ",
					technology:"沉金工艺",
					lineWidth:"≥3mil",
					structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer3.jpg',
				}
			},
			{
				name:'六层软板沉金',
				content:{
					plateThickness:"0.23mm",
					ResistanceWelding:"绿膜白字",
					reinforce:"无补强",
					copperThickness:"0.5OZ",
					technology:"沉金工艺",
					lineWidth:"≥4mil",
					structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
					img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer4.jpg',
				}
			}],

		},

		precisionEquipment:{
			title:"精密设备生产线",
			text1:"拥有进口高精度数控车床和制造平台，实现更少空间、更快速度、更高性能的PCB板生产，打造一流生产线，确保高可靠性产品交付",
			content1:"高精密钻机",
			content2:"沉铜电镀",
			content3:"锣边机",
			content4:"AOI扫描机"
		},

		advantage:{
			title:'我们的优势',
			button:"立即定制",
			text:"日均出货产品3000余款， 服务国家和地区200多个，服务用户超40万家",
			content1:{
				title:"阻抗板",
				text1:"可24小时加急出货",
				text2:"普通交期3~4天"
			},
			content2:{
				title:"价格便宜",
				text1:"单双面板打样300元起",
				text2:"排线板9.9元，全国包邮"
			},
			content3:{
				title:"板材优质",
				text1:"板材/覆盖膜：生益，台鸿等",
				text2:"品牌材料，质量有保证"
			},
			content4:{
				title:"工艺精湛",
				text1:"可做8层板和软硬结合板",
				text2:"线宽线距≥3mil，孔径≥0.15mm"
			},
			content5:{
				title:"产品线广泛",
				text1:"FPC多层板，可做软硬结合",
				text2:"排线等几十种产品"
			},
			content6:{
				title:"直通率高",
				text1:"产品直通率达98%以上",
			}
		}
	},

	aluminumSubstrate:{
		experience:{
			title:"10年高导金属基板生产经验",
			content1:{
				title:"铝基板",
				text:"10x10cm内",
				label1:"板厚：1.0 - 1.6mm",
				label2:"导热系数：1w",
				label3:"阻焊：白油黑字",
				label4:"工艺：常规工艺、喷锡",
				button:"我要定制"
			},
			content2:{
				title:"特种铝基板",
				label1:"可做1- 4层",
				label2:"导热系数：1-3w ( 4-8w可定制)",
				label3:"混压铝基板 / 夹芯铝基板",
				label4:"热电分离＞100w",
				text:"起",
				button:"我要定制"
			}
		},

		optionalSheet:{
			title:"可选板材/品牌",
			button:"立即计价",
			content1:{
				img:process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/example1.png',
				title:'可选板材/品牌',
				name1:"品牌",
				text1:"贝格斯、莱尔德、腾辉、聚鼎、华正、清晰、博钰、国纪等",
				name2:"板材",
				text2:"1系铝 / 3系铝 / 5系铝 / 6系铝 等"
			},
			content2:{
				img:process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/example2.png',
				title:'铝基板类型',
				name1:"常规-铝基板",
				text1:"单/双面铝基板、多层铝基板",
				name2:"特种-铝基板",
				text2:"单层/双层单面混压铝基板、双面夹芯铝基板、热电分离铝基板"
			},
			list: [{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img1.png',
					diList: ['基板材料：铝基', '板厚/铜厚：2.0mm/70um', '导热系数：1.0 - 5.0W/m.k', '表面处理：HAL/OSP/HASL(LF)',
						'击穿电压：1.5-3.0KV(DC)'
					],
					title: '汽车灯板'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img2.png',
					diList: ['基板材料：铝基', '板厚/铜厚：2.0mm/70um', '导热系数：1.0 – 3.0W/m.k', '表面处理：HAL/OSP/HASL(LF)',
						'击穿电压：1.0-3.0KV(DC)'
					],
					title: '灯板'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img3.png',
					diList: ['基板材料：铝基', '板厚/铜厚：2.0mm/70um', '导热系数：1.0 – 5.0W/m.k', '表面处理：HAL/OSP/HASL(LF)',
						'击穿电压：1.0-3.0KV(DC)'
					],
					title: '电源板'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img4.png',
					diList: ['基板材料：铝基', '板厚/铜厚：2.0mm/70um', '导热系数：1.0 – 5.0W/m.k', '表面处理：HAL/OSP/HASL(LF)',
						'击穿电压：1.0-3.0KV(DC)'
					],
					title: '混压铝基板'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img5.png',
					diList: ['基板材料：铝基', '板厚/铜厚：2.0mm/70um', '导热系数：1.0 – 5.0W/m.k', '表面处理：HAL/OSP/HASL(LF)',
						'击穿电压：1.0-3.0KV(DC)'
					],
					title: '双面夹芯铝基板'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img6.png',
					diList: ['基板材料：铝基', '板厚/铜厚：2.0mm/70um', '导热系数：1.0 – 5.0W/m.k', '表面处理：HAL/OSP/HASL(LF)',
						'击穿电压：1.0-3.0KV(DC)'
					],
					title: '面板灯'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img7.png',
					diList: ['基板材料：FR-4', '工艺特点：高密度P1.25 - P2.5', '层 数：2-8层', '板厚/铜厚：1.2-2.0mm/35um',
						'表面处理：OSP / 沉金 等'
					],
					title: 'LED高精密灯板(P1.25)'
				},
				{
					img: process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/view-img8.png',
					diList: ['基板材料：FR-4', '工艺特点：高密度', '层 数：2-8层', '板厚/铜厚：1.2-2.0mm/35um', '表面处理：OSP / 沉金 等'],
					title: '透明屏灯板'
				}
			]
		}
	},

	pcbaMade:{
		banner:{
			title:"SMT贴装",
			text:"SMT中小批量+Layout+PCB制板+SMT打样+物料代购全方位服务 服务医疗、汽车、工业、消费品等等",
			text2:"10多年贴装经验",
			label1:"无门槛",
			label2:"无杂费",
			label3:"无延期",
			button:"立即下单",
			content1:{
				title:"无门槛",
				text:"一片起贴，可贴散料，可焊插件"
			},
			content2:{
				title:"无杂费",
				text:"无工程费，无开机费"
			},
			content3:{
				title:"无延期",
				text:"一片起贴，可贴散料，可焊插件"
			},
			content4:{
				title:"一站式服务",
				text:"代购BOM物料、自有PCB板厂，代开钢网"
			},

		},

		pcbaAssemble:{
			title:"PCBA组装一站式服务，满足客户多元化需求",
			text:"您的电子产品研发合作伙伴！依靠10多年PCB设计，PCB制造，SMT贴装，插件后焊等服务经验，提供一站式PCBA制造为研发提供更高效、更快捷、更完善的增值服务",
			tabs: [{
					name: "PCBA贴片贴装"
				},
				{
					name: "PCB打样/小批量"
				},
				{
					name: "BOM配单/元件器"
				},
				{
					name: "方案设计"
				}
			],
			rightTitle:"质量体系认证与产品服务",
			content1:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img1.png',
				title:"格物思PCBA急速打样，可急速12小时交期",
				text:"SMT打样/批量，一片起贴，无门槛，全流程。SMT贴装， 后焊DIP插件，三防漆，测试，组装，包装一站式解决。",
				label1:"急速可8小时加急，正常2-3天交货",
				label2:"雅马哈自动贴片机，贴片400万点/天",
				label3:"全国产条三防漆自动喷涂线、高性能8温区回流焊",
				label4:"无铅波峰焊、全自动印刷机、X-RAY检测等设备",
				text2:"起",
				button:"立即定制"
			},
			content2:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img2.png',
				title:"自有工厂，PCB打样可8小时急速出货",
				text:"工厂占地200,000平米，PCB生产月出货量8万平米，日出货量3000余款， 产品原材料、产品高质量保障。",
				label1:"PCB打样8/12/24小时加急，全国包邮",
				label2:"小批量3~4天出货",
				label3:"建滔、生益A级板材，符合UL和ROHS标准。",
				label4:"可定制特殊工艺金手指、半孔、金属化包边，阻抗控制、沉头孔、盲埋孔、树脂塞孔，高频高速HDI等",
				text2:"起",
				button:"立即定制"
			},
			content3:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img3.png',
				title:"3小时报价，6小时出货，可散料出货",
				text:"散料出货，无须按小包装订货。海量现货库存，人工精准报价，配齐率高。相比同类目商城低15%，原厂和代理进货",
				label1:"50000+现货型号，快速报价，当天回复",
				label2:"散料出货，无须按小包装订货",
				label3:"支持BOM整单“ 一站式”采购服务，解决您多点购货烦恼",
				label4:"支持多种类，小批量元器件供应服务，解决您的样机试产、新品研发、项目订单追加应急补料问题",
				button:"立即BOM配单"
			},
			content4:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/muli-img4.png',
				title:"电子智能产品“一站式”方案解决商",
				text:"无线通信，智能家居，智慧城市、消费电子、工业物联网、AI人机交互，为客户产品研发和科技创新提供垂直整合的解决方案",
				label1:"资深PCB设计工程师，人均从业10年以上",
				label2:"提供多人并行7*24小时的服务，急速可一天交货",
				label3:"PCBA一站式服务，为您省时省心又省力",
				label4:"一对一的精准服务模式，专人专项数据保护",
				button:"查看详情"
			},
			rightContent:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/certification-img.png',
				title1:"质量体系",
				text1:"ISO9001",
				title2:"满意率",
				text2:"超过99.7%",
				title3:"全球客户数",
				text3:"超过40万",
				title4:"日均出货量",
				text4:"超过250款",
				title5:"格物思PCBA贴装能力",
				text5:"SMT贴装、BGA贴装、混合贴装， FPC贴装等，所有产品出厂前高于行业的标准"
			}
		},

		smtPatch:{
			img:process.env.VUE_APP_OSS_URL + '/manufacture/patch-weld-bg.jpg',
			title:"SMT贴片/插件与后焊",
			text:"自有工厂，多条流水线操作，8小时急速交期，贴片800万点/天，插件后焊50万点/天，产能稳定",
			text2:"SMT贴装与PCB业务一样，我们的PCBA服务也专注于原型制作和小批量生产，格物思线路板板制造和SMT贴装的一站式服务平台。让您的研发工作轻松省时。我们的专业工程师和技术人员将通过我们的客户服务人员与您密切合作，以确保产品的质量和急速的交货时间。每天完成来自世界各地的数千个订单，我们凭借始终如一的质量和卓越的服务，确立了我们作为PCB和PCBA服务的领先供应商的地位",
			content1:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/path-img1.png',
				title:"SMT贴片",
				label1:"8小时急速交期，贴片800万点/天， 可三防漆喷涂、双面贴、FPC贴片",
				label2:"≥01005及0.2mmBGA",
				label3:"≤1200mm*400mm"
			},
			content2:{
				img:process.env.VUE_APP_OSS_URL + '/manufacture/path-img2.png',
				title:"DIP插件/后焊",
				label1:"插件后焊50万点/天",
				label2:"自有工厂，多条流水线操作",
				label3:"专业DIP插件团队焊各种异型插件料",
				label4:"8小时交期，产能稳定"
			}
		},

		processCapability:{
			title:"SMT贴装加工能力",
			text:"全方位一站式服务—您只需要提供一份BOM清单，剩下的全交给我们！",
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/make-bg.png',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/ability-img.png',
			list:[{
				name:"常规交期",
				text:["常规打样（50pcs内)：齐料3天","小批量（50-500pcs)：3-5天","批量(≥500pcs)：5-7天"]
			},
			{
				name:"PCBA焊接类型",
				text:["表面贴片（PCBA）","插件后焊（THT）"]
			},
			{
				name:"日常产能",
				text:["PCBA贴片400万点/日","插件后焊50万点/日"]
			},
			{
				name:"球状零件(bga)间距",
				text:["不小于0.25mm","三防漆喷涂"]
			},
			{
				name:"板厚",
				text:["不大于3mm(样品无厚度限制)"]
			},
			{
				name:"订单起订量",
				text:["1套起贴"]
			},
			{
				name:"元器件服务",
				text:["全套代料 · 部分代料 · 只代工"]
			},
			{
				name:"PCBA的电路板尺寸",
				text:["小板尺寸：45mm x 45mm（小于该尺寸需拼板）","大板尺寸：400mm x 1200mm"]
			},
			{
				name:"可贴PCB板类型",
				text:["PCB硬板（FR-4，金属基板）","PCB软板（FPC）","软硬结合PCB","铝基板"]
			},
			{
				name:"元件尺寸",
				text:["0.80mm (混压min0.30mm)"]
			},
			{
				name:"最大/最小成品尺寸",
				text:["被动元件：贴装英制01005（0.4mm * 0.2mm），0201","BGA等高精IC：支持用X-ray来检测Min0.25mm间距的BGA元件"]
			}]
		},

		userInformation:{
			title:"用户须知",
			content1:{
				title:"您所须提供资料",
				text1:"BOM清单",
				prompt:"（必须提供）",
				text2:"详细，准确的BOM清单，如有变更须在BOM上注明；",
				text3:"Gerber文件",
				text4:"如需开拼版钢网，请提供拼版文件；",
				text5:"元器件位号图、坐标文件；",
				text6:"PCB文件：",
				text7:"如果坐标文件、位号图、Gerber文件客户未能导出的，也可提供PCB文件；",
				text8:"样板：",
				text9:"生产参照用，有条件的可提供。"
			},
			content2:{
				title:"代开钢网",
				text1:"为保障客户产品品质，我司暂不接受外来钢网；",
				text2:"代开钢网的费用为100元起；需开拼版钢网，请提供拼版文件，费用根据不同的点数 重新 核定价格；",
				text3:"钢网默认尺寸为37CM*47CM，其他尺寸需另报价；",
				text4:"PCB文件：",
				text5:"如要寄回钢网须在下单时确认，无特别申明视为不需退还钢网;",
				text6:"样板：",
				text7:"客户钢网只保留3个月，3个月后我司自行处理。"
			},
			content3:{
				title:"物料要求",
				text1:"客户自备物料，对易损耗的物料（样品：阻容件多提供15个样品，批量需要提供1%的备品。）需提供少量备品；",
				text2:"如少量物料备不齐，暂无需焊接，请列出明细；",
				text3:"有特殊要求、注意事项，请提前说明；",
				text4:"暂不接受外来的钢网，寄元器件或样板不接受到付;",
				text5:"代采购阻容件，需提供完整型号（封装、精度、伏数等），所提供阻容件为“三星”品牌（客户如要指定品牌，须特别声明）"
			}
		}

	},

	steelMesh:{
		banner:{
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/laser-title.png',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/laser-sm-title.png',
			img3:process.env.VUE_APP_OSS_URL + '/manufacture/banner-li.png',
			img4:process.env.VUE_APP_OSS_URL + '/manufacture/specialty1.png',
			img5:process.env.VUE_APP_OSS_URL + '/manufacture/specialty2.png',
			img6:process.env.VUE_APP_OSS_URL + '/manufacture/specialty3.png',
			img7:process.env.VUE_APP_OSS_URL + '/manufacture/specialty4.png',
			text1:"网面平整，张力高",
			text2:"硬度高，耐高温",
			text3:"当天发货，最快4小时",
			text4:"网面平整，张力高"
		},

		hotSales:{
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/hot-stencil.png',
			leftContent:{
				text1:"激光钢网",
				text2:"37.0cm×47.0cm",
				label1:"硬度强",
				label2:"耐磨损",
				label3:"无杂质",
				label4:"高温不变形",
				button:"特价购买",
			},
			rightContent1:{
				text:"一分钱一分货 坚持做好品质",
				label1:"采用日本进口304不锈钢弹簧钢片；",
				label2:"硬度高，耐高温，易清洗；",
				label3:"采购张力高的铝框，AB树脂胶；",
				label4:"钢网开孔精准，精准度可达到±20um；",
				img1:process.env.VUE_APP_OSS_URL + '/manufacture/stencil1.png',
				img2:process.env.VUE_APP_OSS_URL + '/manufacture/stencil2.png',
				img3:process.env.VUE_APP_OSS_URL + '/manufacture/stencil3.png',
				img4:process.env.VUE_APP_OSS_URL + '/manufacture/stencil4.png',
			},
			rightContent2:{
				text:"加厚管铝合金边框",
				label1:"高温不变形；",
				label2:"耐磨损，高光亮泽，无杂质；",
				img1:process.env.VUE_APP_OSS_URL + '/manufacture/alloy1.png',
				img2:process.env.VUE_APP_OSS_URL + '/manufacture/alloy2.png',
			}
		},

		smtFactory:{
			title:"专业SMT打样工厂",
			text1:"行业快捷的钢网制作",
			text2:"当天发货",
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing1.png',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing2.png',
			img3:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing3.png',
			img4:process.env.VUE_APP_OSS_URL + '/manufacture/stencil-proofing4.png',
		},

		chooseUs:{
			title:"为什么选择我们",
			list:[{
				title:'优质的生产原材料',
				content:'选用日本进口304不锈钢材料，硬度高，耐高温，易清洗。',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright1.png',
			},
			{
				title:'专业的服务团队',
				content:'技术经验丰富的团队，工程根据客户文件提供最合适的方案，客服实时跟进订单，帮助解决订单中各种问题。',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright2.png',
			},
			{
				title:'先进的激光加工设备',
				content:'国内先进的激光切割设备，精准度可达到±20um，激光器是采用德国进口的IPG，切割厚度为0.03-0.3mm。确保交期的同时也提供稳定的质量。',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright3.png',
			},
			{
				title:'严格的质量把控',
				content:'工程处理好文件之后，QC检查文件然后打印蜡纸，生产对照蜡纸和文件，钢网切割，发货前再进行检查，层层把关，保证质量始终如一，让客户用的放心。',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright4.png',
			},
			{
				title:'快速的物流方式',
				content:'韵达，天天，速尔，联昊通，顺丰等多种快递的物流方式可供选择。',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright5.png',
			},
			{
				title:'4小时快速交货',
				content:'二台激光机24小时工作，17：00点前下单当天安排发货，最迟第二天发货。',
				image:process.env.VUE_APP_OSS_URL + '/manufacture/bright6.png',
			}]
		},

		equipmentDisplay:{
			title:"设备展示",
			text1:"规格/CM",
			text2:"手刷有效面积",
			text3:"机刷有效面积",
			tableList:[{
				text:["30cm*40cm激光钢网","13cm*23cm","/"]
			},
			{
				text:["37cm*47cm激光钢网","20cm*30cm","20cm*24cm"]
			},
			{
				text:["42cm*52cm激光钢网","24cm*34cm","24cm*27cm"]
			},
			{
				text:["45cm*55cm激光钢网","27cm*37cm","26cm*30cm"]
			},
			{
				text:["58cm.4*58.4cm激光钢网","38cm*38cm","38cm*38cm"]
			},
			{
				text:["55cm*65cm激光钢网","37cm*48cm","35cm*38cm"]
			},
			{
				text:["73.6cm*73.6cm激光钢网","50cm*50cm","40cm*50cm"]
			},
			{
				text:["40cm*60cm激光钢网","22cm*40cm","22cm*36cm"]
			},
			{
				text:["40cm*70cm激光钢网","22cm*50cm","22cm*46cm"]
			},
			{
				text:["40cm*80cm激光钢网","22cm*60cm","22cm*56cm"]
			},
			{
				text:["40cm*100cm激光钢网","22cm*76cm","22cm*72cm"]
			},
			{
				text:["40cm*120cm激光钢网","22cm*90cm","22cm*92cm"]
			},
			{
				text:["40cm*140cm激光钢网","22cm*110cm","22cm*110cm"]
			},
			{
				text:["40cm*150cm激光钢网","22cm*120cm","22cm*120cm"]
			},
			{
				text:["4制作方式","顶层:顶底层合开一张","底层:顶底层各开一张"]
			},
			{
				text:["基准点（MARK）","半刻","通孔"]
			}]
		},

		userInformation:{
			title:"用户须知",
			text1:"刚网厚度:",
			text2:"我司钢网厚度有：0.08mm / 0.1mm / 0.12mm / 0.15mm / 0.2mm/ 0.3mm ,钢网厚度应以满足最细间距QFP,BGA为前提，兼顾最小的CHIP原件。详见下附表：",
			tableGaugeOutfit:{
				text1:"元件类型",
				text2:"间距",
				text3:"钢网厚度",
			},
			text3:"如有特殊要求，请说明，可根据指定的厚度安排生产。",
			text4:"客户所须提供资料:",
			text5:"以下任意一种即可：",
			text6:"请务必确认提供的文件/PCB板是正确的。",
			labelList:["GERBER文件","PCB文件","CAD文件","GBR文件","CAM文件","DXF文件或者是PCB实物空板"],
		}
	},

	bomSupply:{
		swperList:{
			title:"一站式配单服务",
			text1:"六大优势 / 不设阶梯价 / 可拆散零卖 / 样品按批量价出货等",
			text2:"支持紧缺、停产、断档、冷偏门、受控元器件供应服务，解决您的缺料苦恼，支持售前、售后增值性服务， 充当您工作中解决各类棘手问题的贴身顾问",
			text3:"支持剩余电子物料代销、清仓服务，助您减少资产损失，支持长期供货、备货服务，解决您的常用型号稳供忧虑等",
			list: [{
					title: "“一站式”配单服务",
					text: "支持BOM整单“ 一站式”采购服务，解决您的多点购货烦恼",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon1.png',
					content: "将复杂的询价处理环节变繁为简，如同为您配备专业的采购团队； 物料配套率可实现98%，降低询价成本； 每天24小时不间断运作，保证需求反馈效率。"
				},
				{
					title: "产品配齐率高",
					text: "支持紧缺、停产、断档、冷偏门、受控元器件供应服务，解决您的缺料苦恼",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon2.png',
					content: "几乎没有起订量和起订金额限制； 有效提供研发阶段的成本控制预算； 协助调查后续量产中各项物料市场供应情况，为稳定生产提供保障。"
				},
				{
					title: "多种类解决方案",
					text: "支持剩余电子物料代销、清仓服务，助您减少资产损失",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon3.png',
					content: "大量潜在客户需求，提高呆料变现率，盘活企业周转资金； 快速、简单、高比率解决剩余库存压力； 降低库存管理人工费用和仓储空间，控制多次盘点丢失风险。"
				},
				{
					title: "售前与售后",
					text: "支持售前、售后增值性服务， 充当您工作中解决各类棘手问题的贴身顾问",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon4.png',
					content: "提供技术支持，查找文档datasheet和资料； 帮助推荐寻找停产、断档器件的替代方案； 酌情提供样品。"
				},
				{
					title: "物料代销",
					text: "支持剩余电子物料代销、清仓服务，助您减少资产损失",
					image: process.env.VUE_APP_OSS_URL + '/manufacture/view-icon5.png',
					content: "大量潜在客户需求，提高呆料变现率，盘活企业周转资金； 快速、简单、高比率解决剩余库存压力； 降低库存管理人工费用和仓储空间，控制多次盘点丢失风险。"
				}
			]
		},

		recommendation:{
			title:"国产推荐",
			text1:"格物思结合大数据与人工智能技术，提供电子产品BOM成本核算、风险管控、替代料推荐、完整BOM元器件贸易应等服务。",
			text2:"公司旗下自有SMT工厂，30000+中小终端制造厂需求，通过研发采购的大数据库与国产电子元器件数据库的智能连接。",
			text3:"格物思自营现货仓库",
			text4:"格物思旗下SMT工厂",
			img1:process.env.VUE_APP_OSS_URL + '/manufacture/stock.jpg',
			img2:process.env.VUE_APP_OSS_URL + '/manufacture/industrialAutomation.png',
			img3:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img1.png',
			img4:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img2.png',
			img5:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img3.png',
			img6:process.env.VUE_APP_OSS_URL + '/manufacture/domestic-img4.png',
			text5:"替代料推荐",
			text6:"核算成本",
			text7:"核算成本",
			text8:"风险提示"
		}
	},

	solution:{
		schemeDesign:{
			solutionProvider:{
				title:"电子智能产品一站式方案解决商",
				text:"格物思拥有10余年经验的产品开发工程师， 提供多人并行7*24小时的急速服务可一天交货",
				list:[{
					img1:'img1',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index1.png',
					name:"方案",
					title:"硬件开发",
					textList:["嵌入式整机设计方案","单板及定制方案","标准化核心板模块"],
				},
				{
					img1:'img2',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index2.png',
					name:"方案",
					title:"软件开发",
					textList:["BSP板极集成开发","vI/O Processing","Linux/Android/WinCE订制"],
				},
				{
					img1:'img3',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index3.png',
					name:"方案",
					title:"云服务开发",
					textList:["多种数据库和存储权限管理","实现完整物联网集成"],
				},
				{
					img1:'img4',
					img2:process.env.VUE_APP_OSS_URL + '/solution/solve-index4.png',
					name:"方案",
					title:"工业设计",
					textList:["产品（ID）外观设计","产品（MD）结构设计","手板制作"],
				}]
			},

			serviceArea:{
				title:"安全、稳定的产品及服务领域",
				tabsList: [{
						name: '无线通信',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon1.png',
					},
					{
						name: '智能家居',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon2.png',
					},
					{
						name: '智慧城市',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon3.png',
					},
					{
						name: '消费电子',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon4.png',
					},
					{
						name: '工业物联网',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon5.png',
					},
					{
						name: 'AI人机交互',
						img: process.env.VUE_APP_OSS_URL + '/solution/nav-icon6.png',
					}
				],
				dataList:[{
					id:0,
					title:"嵌入式系统开发流程是怎样的？",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications1.jpg',
					content:"嵌入式系统开发流程是怎样的？接下来就让小编来详细为你介绍一下吧！嵌入式系统开发流程包含软件开发流程和硬件开发流程软件开发流程 ：一、需求调研分析1、系统分析员和用户初步了解需求，确认开发的功能模块，并列出要开发的系统的大功能模块，及每个大功能模块下的小功能模块有哪些；对于有明确需求的，可以初步定义好少量的界面。2、系统分析员深入了解和分析需求，制作一份功能需求文档。此文档清晰的写出系统大致的大功能模块和大功能模块下的小功能模块，还会写出相关的界面及界面功能。3、系统分析员跟用户再次进行需求确认。"
				},
				{
					id:0,
					title:"一文为你详解工业设计",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications2.jpg',
					content:"很多人对“工业设计”这个词搞不清楚，不知道工业设计与普通设计的区别。工业设计又称工业产品设计学，涉及到心理学、社会学、美学、人机工程学、机械构造、摄影、色彩学等。工业发展和劳动分工所带来的工业设计，与其它艺术、生产活动、工艺制作等都有明显不同，它是各种学科、技术和审美观念交叉的产物。"
				},
				{
					id:0,
					title:"嵌入式硬件工程师应具备哪些基本技能？",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications3.jpg',
					content:"设计是产品制作的第一关，那些硬件工程师既是产品设计者，又是质量把关者，对产品的质量与性能起着决定作用。那么，嵌入式硬件工程师应具备哪些基本技能？一、硬件工程师应具备的基本技能1、有需求分析、总体方案、详细设计的规划创造能力； 2、熟练运用设计工具，善于设计原理图、PCB板的能力； 3、熟练运用单片机、ARM、DSP、PLD、FPGA等进行软硬件开发调试的能力；4、熟练运用仿真工具、示波器、信号发生器等调测硬件的能力；5、掌握常用的标准电路的设计能力，如复位电路、常用滤波器电路、高速信号传输线的匹配电路等；"
				},
				{
					id:0,
					title:"电子产品专利申请要遵循哪些原则？",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications4.jpg',
					content:"我们都知道，电子产品研发出来通常都会去申请各种专利，以防别人侵权。在我国，国家知识产权局负责管理全国的专利工作，统一受理和审查专利申请，依法授予专利权。根据申请专利的类型不同，《专利法》对应当提交的申请文件规定了不同的要求；同时，针对不同的专利类型分别采取了不同的审查制度，对于发明专利申请采用早期公开延迟审查制度，而对于实用新型和外观设计专利申请则采用形式审查制度。那么，电子产品专利申请要遵循哪些原则？"
				},
				{
					id:0,
					title:"如何理解电子产品可靠性设计中的“降额”？",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications5.jpg',
					content:"电子产品的可靠性设计，是指在不增加或少量增加成本的前提下，避免不期望的产品缺陷，可以达到预设的可靠性要求，减少发生故障的时间，降低产品寿命周期的使用成本，更能预防安全事故发生。在电子产品设计阶段，避免器件功能应用缺陷、测试、降额是提升可靠性的三个基础手段。那么，如何理解电子产品可靠性设计中的“降额”？"
				},
				{
					id:0,
					title:"深圳智能电子产品定制流程有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications6.jpg',
					content:"格物思是国内领先的“电子智能硬件制造平台”，拥有多款成熟的智能电子产品的开发方案。接下来就来介绍：深圳电子智能产品定制流程有哪些？一、总体设计1. 需求分析：研发工程师会了解并确定客户的需求，如确定产品的样式、功能、规格、参数、数量等。2. 方案确定：研发工程师确定最佳的方式来解决需求。除了考虑芯片的性能之外，还要考虑经济因素和实用价值。"
				},
				{
					id:0,
					title:"利用红外温度传感器检测变送器的方案",
					img:process.env.VUE_APP_OSS_URL + '/solution/telecommunications7.jpg',
					content:"在高压输电线路的运行过程中，通常采用红外温度传感器进行检测，以保证更好地掌握高压输电线路的运行状态。今天，就让工程师为你讲解红外温度传感器检测变送器的方案：一、红外温度传感器的工作原理：自然界中，所有温度高于绝对零度(-273℃)的物体都会不断地以电磁波的形式辐射出红外能量。红外温度传感器中的光学系统可以采集被测目标发射的红外辐射能量，然后将其集中在光电探测器的光敏面上，转换成相应的电信号；信号经放大器处理后，根据仪器开发的算法和目标发射率进行校正，转化为被测目标的温度值。"
				},
				{
					id:1,
					title:"选择深圳小程序开发公司要考虑哪些因素？",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome1.jpg',
					content:"如今的小程序开发公司鱼龙混杂，一不小心就会找到没有资质的公司来合作，浪费自己的时间和精力。那么，选择深圳小程序开发公司要考虑哪些因素？1、公司成立时间虽说时长并不能作为关键因素，也不否认年轻的公司水平或许会高于成立时间较久的公司，但在可信度方面，还是会有所帮助，毕竟，经验是要靠时间的累积才能达成。"
				},
				{
					id:1,
					title:"美容小程序开发的功能有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome2.jpg',
					content:"美容小程序就是利用移动客户端帮助客户提供美容资讯推送、服务预定等相关服务的一款小程序，美容小程序还会跟线上服务平台和线下的门店一起发展，将美容行业的运营管理模式彻底的变成了O2O模式。那么，美容小程序开发的功能有哪些？1、在线预约服务支持用户在线预约美容服务时间，让用户弹性预约服务，减少耗费时间等待服务的可能。"
				},
				{
					id:1,
					title:"智能灯控APP开发的基本功能有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome3.jpg',
					content:"随着移动互联技术的发展，各类智能灯控app如雨后春笋般冒出。智能灯控app主要是通过在灯泡里装入LED驱动电源和蓝牙模块/Wifi模块/ZigBee模块，利用手机软件和场景控制器进行灯的开关控制以及不同的调光需求，满足用户多样化灯光需求！那么，智能灯控APP开发的基本功能有哪些？"
				},
				{
					id:1,
					title:"软件开发人员应具备的基本要素有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome4.jpg',
					content:"当今，软件开发是一门比较吃香的职业。那么，软件开发人员应具备的基本要素有哪些？一、获得学历有学历的软件开发人员，更容易找到对口的工作，大多数公司在任职门槛中都有这样的要求。如果你在一个软件开发人员比较集中的地区工作，拥有硕士学位可以让你在竞争中占据优势。二、课外继续学习深造"
				},
				{
					id:1,
					title:"电熨斗物联网单片机都有哪些功能？",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome5.jpg',
					content:"电熨斗是常用的家电设备，如果将电熨斗赋予智能化科技时，这款设备就变成了电熨斗物联网单片机。那么，电熨斗物联网单片机都有哪些功能？一、电熨斗单片机开发介绍这款电熨斗用的智能控制器件是8位单片机制作编程，具备A.S.O保护功能，能够按照电熨斗的放置状态提供定时的断电保护，避免人为原因的失误导致发热丝长时间加热，较好的保护衣物不会被电熨斗熨坏，从而防止火灾事件的发生。"
				},
				{
					id:1,
					title:"智能家居产品开发的基本准则和思想都有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome6.jpg',
					content:"智能家居产品开发的基本准则和思想都有哪些呢？让我们一起来了解一下：1、充分了解各方的设计需求，确定合适的解决方案。启动一个产品开发项目，原始的推动力会来自于很多方面，比如市场的需要，基于整个系统架构的需要，应用软件部门的功能实现需要，提高系统某方面能力的需要等，所以作为一个硬件系统的设计者，要主动的去了解各个方面的需求，并且综合起来，提出最合适的硬件解决方案。"
				},
				{
					id:1,
					title:"掀起智能家居APP开发的神秘面纱",
					img:process.env.VUE_APP_OSS_URL + '/solution/SmartHome7.jpg',
					content:"随着科技不断发展，诸多智能设备不断涌现，智能家居就是其中代表；人们使用智能家居APP开发，就能实现“以人为本”的全新家居生活体验。下面，就让工程师为你掀起智能家居APP开发的神秘面纱：一、智能家居APP开发的特点： 1、实用性：随着互联网不断发展，人们对于智能家居的需求也在不断上升，从用户的实际需求出发，尽可能满足用户对于智能家居系统操作需求，更加智能和人性化，具有较强的实用性，给人们生活带来一定的便利。"
				},
				{
					id:2,
					title:"饮品机解决方案",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity1.jpg',
					content:"新零售是互联网实现社会信息化、数字化的过程中，零售行业发展、变化的一个阶段，它是以消费者体验为中心的数据驱动的泛零售形态,可实现会员、支付、库存、服务等多方面数据的全面打通。饮品机采用物联网+平台+专利酿造技术"
				},
				{
					id:2,
					title:"洁净小屋整体解决方案",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity2.jpg',
					content:"一、项目背景洁净小屋（名称暂定）在公共场所提供1.5㎡的个人安全私密空间，用户可在小屋内办公、休息、用餐。经过前期的需求讨论与项目实地调研，根据一代产品投产应用中的问题，对二代产品在密封设计、气流稳流、结构美观度等方面提出了要求。"
				},
				{
					id:2,
					title:"小程序应用的优缺点都有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity3.jpg',
					content:"随着移动互联网迅速发展，APP改善了用户的生活习惯和服务效率。但是手机APP，有些使用率不大，占用手机内存，浪费了大量的资源，对流量也有要求。于是，小程序应运而生。那么，小程序应用的优缺点都有哪些？1、小程序应用的优点（1）小程序是一个轻量级的应用，体积小、功能多；具有便利性、便捷性和可控制性，及互动性强的优势。"
				},
				{
					id:2,
					title:"PC端产品跟移动端产品的区别在哪？",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity4.jpg',
					content:"随着移动互联网的普及，移动端上网的人数、流量已经超过了PC端，移动互联网时代大家都选择移动端作为突破口，传统的PC端产品为了在移动端占据一定的市场也要做移动端的扩展。那么，PC端产品跟移动端产品的区别在哪？让我们从以下三个方面来分析：1、产品属性有些产品或服务只适合在PC端，比如那些复杂、需要大运算的操作系统等；而有些内容天生就是为移动而生，比如微信类 IM 通讯软件。"
				},
				{
					id:2,
					title:"一个清晰合理的APP项目研发流程有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity5.jpg',
					content:"一个移动APP项目研发规模可大可小，但都离不开产品经理、ui设计师、前端开发、后端开发、测试等这些分工。那么，一个清晰合理的APP项目研发流程有哪些？一、需求阶段1、需求讨论：产品经理与客户沟通，内容包括：开发类型、需要开发的平台、具体的产品功能需求、具体的产品设计需求、项目期望完成时间、开发预算等；通过反复调研、讨论、输出交互方案。"
				},
				{
					id:2,
					title:"常见的运动健身app开发模式都有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity6.jpg',
					content:"如今，各种运动健身APP层出不穷，深受年轻人的喜欢。那么，常见的运动健身APP开发模式都有哪些？下面就让我们一起来了解一下：模式一：视频类运动健身APP这一类APP的基础逻辑是以优质视频课程内容切入，从教程内容的专业度来保证用户减肥塑形的有效性。模式二：社区类运动健身APP以社区切入任意一款移动APP应用产品，都不能忽略掉其社交属性，火辣健身和Fittime都是这一趋势的明证。"
				},
				{
					id:2,
					title:"智能产品设计都有哪些原则？",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity7.jpg',
					content:"设计师对一件需求的智能产品进行设计时，都有其独到的思考路线，还有必须需要遵守的原则。那么智能产品设计都有哪些原则？1、核心原则首先在设计之初需要客户提供对产品设计的一系列要求，我们需要对这些需求进行分析。一切设计都是为了满足用户的需求，无论是精神方面的需求还是物质方面的要求，这一点是不变的，也是产品设计的核心原则。"
				},
				{
					id:2,
					title:"大数据开发需要学习哪些技术?",
					img:process.env.VUE_APP_OSS_URL + '/solution/smartCity8.jpg',
					content:"大数据作为时下火热的词汇，随之而来的数据开发、数据仓库、数据安全、数据分析、数据挖掘等围绕大数据的商业利用逐渐成为行业人士争相追捧的利润焦点。随着大数据时代的来临，大数据开发也应运而生。那么，大数据开发需要学习哪些技术?学习路线图如下：阶段一：大数据基础——java语言基础方面(1)Java语言基础包括Java开发介绍、熟悉Eclipse开发工具、Java语言基础、Java流程控制、Java字符串、Java数组与类和对象、数字处理类与核心技术、I/O与反射、多线程、Swing程序与集合类等"
				},
				{
					id:3,
					title:"医疗小程序开发的意义和作用何在？",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics1.jpg',
					content:"医疗小程序可以解决我国医疗从业人员紧缺的问题，并且还能向社会大众提供便捷的服务，向老百姓展现我们的专业医疗资讯。那么，医疗小程序开发的意义和作用何在？1、医疗小程序开发的意义（1）对企业的帮助通过小程序将患者的预约信息传到医疗机构，可以让机构提前分配医疗资源，合理安排就诊服务；通过小程序的线上问诊功能，医疗机构可远程监控患者情况，提高服务质量。对于医疗产品销售商，通过小程序让商家增加获客渠道。"
				},
				{
					id:3,
					title:"医疗小程序开发的主要功能有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics2.jpg',
					content:"医疗小程序主要是帮助用户提供移动终端系统的医疗健康服务或者帮助医疗卫生机构提供高质量的服务平台、高价值的资讯显示平台，让医疗资源得到有秩序、合理的使用。那么，医疗小程序开发的主要功能有哪些？1、健康测试用户通过手机软件测试自身健康状况，根据测试情况再通过手机挂号就医或有针对性的调理身体。"
				},
				{
					id:3,
					title:"智能电动牙刷都有哪些功能？",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics3.jpg',
					content:"智能电动牙刷是近年来出现的新智能产品。调查表明，电动牙刷比普通牙刷更科学有效，可以减少牙龈炎和牙龈出血；通过快速旋转使刷头产生高频振动，将牙膏瞬间分解成微细的泡沫，深入清洗牙缝；同时刷子的抖动促进口腔血液循环，有利于口腔健康。智能牙刷是一种新型的高科技牙刷，它分析用户的刷牙习惯，记录牙齿信息，在移动应用程序中向智能手机显示数据。"
				},
				{
					id:3,
					title:"移动智能硬件对生活有哪些影响？",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics4.jpg',
					content:"随着科学技术的发展，智能硬件在传统设备的基础上，通过软硬件结合的方式，让传统的设备能够更智能。智能硬件能让人们的生活变得更方便，更健康。那么，移动智能硬件对生活有哪些影响呢？1、智能健康：通过智能手表、智能空气质量检测仪等智能硬件设备的记录数据等功能，让人们的生活变得更健康。2、智能运动：通过智能手环、智能羽毛球监测等智能运动设备，刺激人们的运动神经，让人们爱上运动。"
				},
				{
					id:3,
					title:"为你详解智能手环解决方案",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics5.jpg',
					content:"智能手环是内置智能操作系统、通过连接网络来实现多种功能的手表产品，一般能同步手机中的电话、短信、邮件、照片、音乐等。下面，就让专家为你详解智能手环解决方案。手环类产品比手表类产品诞生更早，但因为早期的实用性，手表被广泛使用，而手环一直处于可有可无状态；随着物联网技术发展，手表产品得到更新换代，出现智能手表，手环便成为智能手表形态链条上的一个垂直领域，并衍生出智能手环。"
				},
				{
					id:3,
					title:"如何做好产品外观设计创意？",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics6.jpg',
					content:"一个产品是否能激起人们的购买欲，获得人们的喜欢和社会赞许，外观设计举足轻重。那么，如何做好产品外观设计创意？1、“兵马未动，粮草先行”——竞品产品市场信息的调查报告。通过认真的市场调查，可以了解消费者对于产品品种、规格、质量、性能、价格的意见和要求，知道消费者倾向于什么样的产品外观造型、色彩搭配等，掌握市场对某种产品的需求量和销售趋势，掌握产品的市场占有率和竞争企业的市场占有情况，了解社会商品购买力和社会商品可供量的变化。"
				},
				{
					id:3,
					title:"医疗产品设计需要明确哪些信息？",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics7.jpg',
					content:"医疗产品设计需要明确哪些信息？通常，设计师在与客户沟通时，得到的是零散、碎片的信息，需要进一步整理分析，提炼出简洁明确的需求点。这些需求点包括：1、基本信息的收集整理该设备的定位是什么？如：利用电子技术、数字信号处理技术和图像处理技术，运用超声多普勒原理，结合医学临床实践，精心设计的应用于哪些疾病诊断的设备。"
				},
				{
					id:3,
					title:"哪些类型的消费电子产品需要使用PCB板？",
					img:process.env.VUE_APP_OSS_URL + '/solution/consumerElectronics8.jpg',
					content:"基本上，所有的电子产品都需要使用PCB板，但并非每个电器都需要电路板，简单的电器可能不需要电路，例如电动机。 那么，哪些类型的消费电子产品需要使用PCB板？1、需要集成电路的消费电子产品：这些电子产品旨在节省空间，并使产品更轻，更紧凑； 耐用且要获得良好的性能，PCB板能够满足这些产品空间、性能和可靠性的要求。2、用于家用电器的主板：数字电视放大器、手机、洗衣机、电子秤、电话、LED灯、空调、冰箱、立体声音响、MP3等。 "
				},
				{
					id:4,
					title:"企业软件开发定制具备哪些优势？",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet1.jpg',
					content:"随着企业对信息化的需求不断扩大，企业信息化建设步伐逐渐加快，一些先进企业已经开始实施企业信息化管理，希望通过信息化的实施，来提高企业的工作效率，提高企业在市场上的综合竞争力。实现企业信息化管理，离不开软件开发定制。那么，企业软件开发定制具备哪些优势？1、针对性强，适应性好企业所实施的信息系统是由专门的软件公司为其量身定做，充分发挥了企业个性化的优势，因而定制的软件可操作性强，应用针对性强。 "
				},
				{
					id:4,
					title:"云服务应用开发所面临的挑战有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet2.jpg',
					content:"云服务多年来发展迅速，已经在企业中慢慢获得认可。那么，云服务应用开发所面临的挑战有哪些？1、私有和安全云架构不会自动为其上的最终用户数据或应用程序授予安全合规性，因此为云计算编写的应用程序必须自身满足相关安全条款。虽然云供应商有此责任，但最重要仍然在应用程序设计师的的考虑范畴。"
				},
				{
					id:4,
					title:"监视微服务需要执行的五个步骤",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet3.jpg',
					content:"微服务是可自主部署的，以业务为中心的单元。并非所有部署工件都是单独的服务。对于微服务，我们希望将它们视为独立的服务，但还要评估它们之间的相互关系。下面，让我们看看监视微服务需要执行的五个步骤。1.确定只需启动几个服务"
				},
				{
					id:4,
					title:"如何做好工业产品设计？",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet4.jpg',
					content:"完成一个工业产品设计靠的是工作的经验，技术的积累，和及时更新对产品的认知。那么，如何做好工业产品设计？一起来了解一下：一、工业产品设计定义1、工业产品设计是工业设计工程的边缘性学科，其主要目的是帮助工程师设计出比较合理的产品外观设计。"
				},
				{
					id:4,
					title:"医疗器械产品设计开发流程都有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet5.jpg',
					content:"医疗器械产品的设计和开发有严格的质量管控要求，因为这直接关系到产品设计开发的效率和质量。那么，医疗器械产品设计开发流程都有哪些？1、项目的确认与立项通常，设计公司在收到客户的产品设计需求后，会下达项目任务书，正式立项，着手进行设计和开发工作。"
				},
				{
					id:4,
					title:"什么是云服务和云服务端开发？",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet6.jpg',
					content:"提到云服务，我们马上就会想到阿里云、百度云、腾讯云等。那么，什么是云服务和云服务端开发呢？云服务是一种简单高效、安全可靠、处理能力可弹性伸缩的计算服务，是云计算服务的重要服务之一，用途广泛。云服务实现的方式是客户通过互联网络，访问部署在云上的服务端。"
				},
				{
					id:4,
					title:"工业产品设计要注意哪些事项？",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet7.jpg',
					content:"完成一个工业产品设计靠的是工作的经验，技术的积累，和及时更新对产品的认知。那么，工业产品设计要注意哪些事项？一、设计产品前的分析1、清楚受众目标。设计师是厂家和消费者之间的桥梁，既要保证消费者对产品性能或美观的要求，又要实现经济实惠，使商家有利可图，所以在设计前，要清楚受众的目标;"
				},
				{
					id:4,
					title:"工程师详解：云计算的8项核心技术",
					img:process.env.VUE_APP_OSS_URL + '/solution/internet8.jpg',
					content:"云计算是一种以数据和处理能力为中心的密集型计算模式，它融合了多项ICT技术；其中，以虚拟化技术、分布式数据存储技术、编程模型、大规模数据管理技术、分布式资源管理、信息安全、云计算平台管理技术、绿色节能技术等8项技术最为关键。今天，工程师就为你详解云计算的8项核心技术：1、虚拟化技术虚拟化是云计算最重要的核心技术之一，它为云计算服务提供基础架构层面的支撑，是ICT服务快速走向云计算的最主要驱动力。"
				},
				{
					id:5,
					title:"云服务器开发的主要步骤都有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI1.jpg',
					content:"随着云计算的发展，云服务器已经成为企业用户选择服务器的首选。企业将业务迁移到云服务器，可以降低成本、简化工作流程，并消除对IT硬件的需求。那么，云服务器开发的主要步骤都有哪些？一、云服务器接收采集数据建立好云服务器之后，接下来是如何把采集的数据传输给云服务器。这包含两项工作：一是在云服务器上建立一个接收处理程序，这个程序有多种形式，只要能够提供接收数据的连接即可。通常可以使用以下方式："
				},
				{
					id:5,
					title:"为什么零售业需要利用边缘计算？",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI2.jpg',
					content:"边缘计算是在相同位置或最接近数据源的位置进行的计算过程，零售商企业认为该技术可以优化并为客户及其业务带来价值。为什么零售业需要利用边缘计算？1.提高运营效率传统零售企业利用专用的应用程序作为零售管理(如库存管理、定价和扫描)的解决方案，无法跟上零售业的动态性质。借助边缘计算，零售商可以最大限度地利用物联网设备及其可以实时传输的海量数据。"
				},
				{
					id:5,
					title:"选择云服务器的标准有哪些？",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI3.jpg',
					content:"随着网络的飞速发展，云服务器成为IT项目部署应用最多的产品之一。那么，选择云服务器的标准有哪些？1、云服务商的信用和资质一个网站的运营是否长久，很大程度与网站服务器密切相关。所以在购买云主机的时候，首先要看服务商的信用和资质，这能在很大程度上保障自己的利益。2、稳定性和速度测试体验随着客户访问量和服务变化，服务器需要不断地增加硬件资源，以提高服务水平和服务能力，所以在服务器租用前，一定要进行试用，确定没问题后再购买。"
				},
				{
					id:5,
					title:"小程序开发都有哪些问题及解决方法？",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI4.jpg',
					content:"小程序作为服务号的辅助，能够帮助企业或品牌提升业务量。那么，小程序开发都有哪些问题及解决方法呢？1、需求确定问题开发小程序遇到的第一个难题，就是要确定需求：企业需不需要小程序、需要什么类型的小程序？品牌方在开发小程序时，对小程序的定位、要求都要考虑清楚。小程序更适合需要线上线下紧密结合的品牌，更适合那些高频次、用完即走、功能需求不是非常复杂的品牌方，比如摩拜单车、麦当劳、肯德基、今日头条等。"
				},
				{
					id:5,
					title:"传统硬件行业为何需要开发智能APP？",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI5.jpg',
					content:"智能硬件APP开发是联系智能硬件与用户之间的一个枢纽，当企业研究出一款智能硬件后，需要通过手机客户端将其功能展示给用户，这样的手机客户端便是智能硬件APP。那么，传统硬件行业为何需要开发智能APP？1.人们有那个需求：随着人们生活水平的上升以及科学技术的发展，对传统硬件造成了不小的冲击。科学的发展，让人们的“惰”性变得越来越强，能够用手机解决的事情，已经不想再花力气去做了。对人们来说，智能硬件解放了双手，还让生活变得更方便。"
				},
				{
					id:5,
					title:"深圳app设计开发流程是怎样的？",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI6.jpg',
					content:"随着智能手机的普及，移动应用app开发就越来越火爆了。那么，app设计开发流程是怎样的呢，下面小编就来介绍一下吧。一、交流需求和方案评估开发工程师会确定客户开发app的需求，对app进行定位，将项目整体情况细化，确保app能够在规定期限完成。二、确定产品原型图客户如果有大概的商业模式图，将是一个很好的开发，这个时候就能很轻松做出产品的原型图。原型图所包括的内容如下："
				},
				{
					id:5,
					title:"云计算能给企业带来哪些机遇？",
					img:process.env.VUE_APP_OSS_URL + '/solution/AI7.jpg',
					content:"对企业来说，随着业务规模的不断拓展，企业将面临数据分析处理方面的难题，而云计算就能很好地应对，帮助企业实现更好的发展。那么，云计算能给企业带来哪些机遇？1、有利于做好市场定位企业要想使自己的品牌和产品获得成功，必须要有精准的市场定位。做好市场定位，需要大量的调研成本和丰富的经验积累，对于中小企业有一定的难度。但是通过云计算和大数据技术，中小企业也能轻松做到精准定位。"
				}],
				button:"更多案例",
				button2:"详情"
			},

			electronicDesign:{
				title:"电子设计与仿真",
				text:"SI、PI、EMC、CAE、DFM，全面的仿真与设计",
				icon1:{
					title:"信号完整性",
					text1:"设计规则、拓扑结构前仿，反射、串扰、",
					text2:"时序仿真分析，多板联合系统分析"
				},
				icon2:{
					title:"电源完整性",
					text1:"直流压降分析、平面谐振分析",
					text2:"PDN阻抗分析、去耦电容优化"
				},
				icon3:{
					title:"电磁兼容性",
					text1:"EMC设计、EMC整改",
					text2:"EMC测试"
				},
				icon4:{
					title:"可制造性",
					text1:"DFF可制造性分析、DFA可组装性分析",
					text2:"DFT可测试分析"
				},
				leftContent:{
					title:"服务优势",
					text1:"技术硬",
					text2:"资深PCB设计工程师人均从业10年以上",
					text3:"速度快",
					text4:"提供多人并行7*24小时的高品质服务可24小时急速交货",
					text5:"成本低",
					text6:"PCBA一站式服务,为您省时省心又省力",
					text7:"保密严",
					text8:"一对一的精准服务模式 ，专人专项数据保护",
				},
				rightContent:{
					title:"PCBA一站式服务 — 你想要的我们都有",
					text1:"PCB制板",
					text2:"可8小时加急，全国包邮",
					text3:"SMT贴装",
					text4:"一片起贴，可贴散料",
					text5:"元器件",
					text6:"人工精准报价，配齐率高"
				}
			}
		},

		layoutDesign:{
			designCapacity:{
				title:"10年以上设计能力",
				label1:"费用低",
				label2:"速度快",
				label3:"质量优",
				label4:"服务好",
				text:"客户只需提供基本的原理图和规格资料，从PCB设计到制板贴片，我们提供一站式解决方案。加快您的产品研发，推动新产品快速上市，充 分把握市场机遇赢得更大经济效益。专业的PCB设计理念，优秀的PCB LAYOUT团队，严格的设计流程向客户提供高效优质的服务。",
				img1:process.env.VUE_APP_OSS_URL + '/solution/computer.png',
				icon1:{
					text1:"50PIN内",
					label1:"200元",
					text2:"100PIN内",
					label2:"200元",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability1.png',
				},
				icon2:{
					text1:"100PIN内",
					label1:"24小时",
					text2:"500PIN内",
					label2:"48小时",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability2.png',
				},
				icon3:{
					text1:"资深PCB设计工程师",
					text2:"人均从业10年",
					text3:"保证PCB的设计质量",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability3.png',
				},
				icon4:{
					text1:"几十人的服务团队",
					text2:"一对一的服务模式",
					text3:"7*24小时的全天候服务",
					img:process.env.VUE_APP_OSS_URL + '/solution/ability3.png',
				}
			},

			designServices:{
				title:"设计服务",
				list: [{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service1.png',
							text: '免费为客户提供PCB封装建设（客户提供器件DataSheet）'
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service2.png',
							text: '免费为客户提供PCB阻抗计算、叠层设计、QA检查、工艺检查、EMC检查。'
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service3.png',
							text: '设计包含高速、高频、大功率、模拟、数模混合、HDI、FPC等设计类型。'
						}
					]
				}, {
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service4.png',
							text: '支持原理图和PCB软件格式：Cadence,Mentoree,Mentor pads,Altium,AD。'
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service5.png',
							text: '公司强大的R&D工程师团队做技术支撑，协助客户及Layout工程师做原理图分析及PCB设计，排除电气原理性设计错误，同时保证了设计的品质与可靠性。'
						},
						{
							img: process.env.VUE_APP_OSS_URL + '/solution/service6.png',
							text: '上门服务，根据客户产品的保密程度，以及人力资源紧张时提供人才派遣服务，我方工程师可在客户端做设计服务，贴身高效。'
						}
					]
				}],
				list2: [{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service1.png',
							text: '免费为客户提供PCB封装建设（客户提供器件DataSheet）'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service2.png',
							text: '免费为客户提供PCB阻抗计算、叠层设计、QA检查、工艺检查、EMC检查。'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service3.png',
							text: '设计包含高速、高频、大功率、模拟、数模混合、HDI、FPC等设计类型。'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service4.png',
							text: '支持原理图和PCB软件格式：Cadence,Mentoree,Mentor pads,Altium,AD。'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service5.png',
							text: '公司强大的R&D工程师团队做技术支撑，协助客户及Layout工程师做原理图分析及PCB设计，排除电气原理性设计错误，同时保证了设计的品质与可靠性。'
						}
					]
				},{
					list: [{
							img: process.env.VUE_APP_OSS_URL + '/solution/service6.png',
							text: '上门服务，根据客户产品的保密程度，以及人力资源紧张时提供人才派遣服务，我方工程师可在客户端做设计服务，贴身高效。'
						}
					]
				}]
			},

			ourAdvantages:{
				title:"我们的优势",
				label1:"资深PCB设计工程师,80款以上精密板设计经验",
				label2:"合作客户超过800家,一对一精准服务",
				label3:"提供多人并行7*24小时的服务,可一天急速交货",
				label4:"封装工程师、Layout工程师、EMI工程师、EMC工程师，专业的基石",
				leftContent:{
					title:'PCB设计软件',
				},
				rightContent:{
					title:"PCB设计能力",
					list:[{
						name:'层数可达',
						text:'40层'
					},
					{
						name:'线宽',
						text:'≥2mil（HDI）'
					},
					{
						name:'PIN数',
						text:'≤60000'
					},
					{
						name:'线距',
						text:'≥2mil（HDI）'
					},
					{
						name:'BGA间距',
						text:'≥0.20mm'
					},
					{
						name:'BGA-PIN数',
						text:'≤2500'
					},
					{
						name:'速讯号',
						text:'≤12G(差分讯号)'
					},
					{
						name:'价格HDI',
						text:'任意层互聊（ELIC）'
					}]
				}
			},

			designProcess:{
				title:"设计流程",
				designProcessTitle1:{
					text1:"计价",
					text2:"发布"
				},
				designProcessTitle2:{
					text1:"审核",
					text2:"报价"
				},
				designProcessTitle3:{
					text1:"支付",
					text2:"确认"
				},
				designProcessTitle4:{
					text1:"开始",
					text2:"设计"
				},
				designProcessTitle5:{
					text1:"多次",
					text2:"确认"
				},
				designProcessTitle6:{
					text1:"完成",
					text2:"上传"
				},
				designProcessTitle7:{
					text1:"客户",
					text2:"下载"
				},
				designProcessTitle8:{
					text1:"反馈",
					text2:"审核"
				},
				designProcessContent1:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case1.png',
					title:"客户需提供资料",
					text:"原理图（DSN或powerlogic）、网表、封装、结构图(DXF)、需新建库的器件资料、设计要求等"
				},
				designProcessContent2:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case2.png',
					title:"布局、布线评审",
					text:"依据设计规范、设计指导书、客户设计要求以及相关CHECKLIST进行。项目启动后，我方工程师进行原理图DRC检查 ，结构核对等电气设计要求，有问题第一时间反馈EQ记录给客户。"
				},
				designProcessContent3:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case3.png',
					title:"客户布局确认",
					text:"提供布局档案、结构档案供客户进行布局审查；客户确认布局合理性、层叠方案、阻抗方案、结构、封装，并确认布线参数"
				},
				designProcessContent4:{
					img:process.env.VUE_APP_OSS_URL + '/solution/case4.png',
					title:"设计资料输出",
					text:"PCB源文件、Gerber档案、装配档案、钢网档案、结构档案等。PCB Layout设计完成后，我方工程师进行互检，包括DFM检查，QA检查，EMC检查，客户确认OK后,出Gerber等生产文件"
				}
			},

			designCycle:{
				title:"设计周期",
				text1:'单板PIN数',
				text2:"设计交期（工作日）",
				tableList:[{
					text:["1000以内","3-5天"]
				},
				{
					text:["2000-3000","5-7天"]
				},
				{
					text:["4000-5000","8-12天"]
				},
				{
					text:["6000-7000","12-15天"]
				},
				{
					text:["8000-9000","15-18天"]
				},
				{
					text:["10000-13000","18-20天"]
				},
				{
					text:["14000-15000","20-22天"]
				},
				{
					text:["16000-20000","22-30天"]
				}]
			},

			designShow:{
				title:"设计展"
			}
		}
	},

	factoryDisplay:{
		displayFactory:{
			title:"格物思总部",
			text:"格物思全球电子智能硬件智造平台",
			text2:"公司介绍",

			workshop:{
				title:"PCB生产车间",
				list:[{
					title:"钻孔",
					text:"台湾东台高精密钻孔机，定位精度±0.05mm，自钻孔精度±0.018mm，自动检测刀径，断刀，钻孔后验孔，全面杜绝槽孔歪斜，漏孔。",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process1@icon.png',
					imgList:[{
						name:"双头钻孔机",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk1.jpg',
					},
					{
						name:"CNC锣边机",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk2.jpg',
					},
					{
						name:"高速六轴钻孔机",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk3.jpg',
					},
					{
						name:"钻孔生产线",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zk4.jpg',
					}]
				},
				{
					title:"沉铜电镀",
					text:"全自动沉铜电镀设备，70分钟图电，经过多道工序锤炼，孔铜，面铜厚度远超行业标准。",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process2@icon.png',
					imgList:[{
						name:"沉铜线",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct1.jpg',
					},
					{
						name:"电镀线细节",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct2.jpg',
					},
					{
						name:"VCP电镀",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct3.jpg',
					},
					{
						name:"VCP电镀细节",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/ct4.jpg',
					}]
				},
				{
					title:"线路",
					text:"LDI镭射曝光，一体化生产设备有效控制线幼、微短、蚀刻不净，线宽线距最小可达4mil，AOI光学检测，排除一切隐性品质问题",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process3@icon.png',
					imgList:[{
						name:"LDI爆光",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl1.jpg',
					},
					{
						name:"线路",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl2.jpg',
					},
					{
						name:"大型全自动蚀刻",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl3.jpg',
					},
					{
						name:"AOI光学检测",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/xl4.jpg',
					}]
				},
				{
					title:"阻焊字符",
					text:"全自动阻焊印刷，80分钟多温区烘烤，保证阻焊均匀性和附着力，全面解决孔环发红露铜之风险。",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process4@icon.png',
					imgList:[{
						name:"阻焊",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh1.jpg',
					},
					{
						name:"阻焊",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh2.jpg',
					},
					{
						name:"字符",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh3.jpg',
					},
					{
						name:"字符",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/zh4.jpg',
					}]
				},
				{
					title:"成型",
					text:"刚印制出来的PCB板是连片的，为符合客户要求的规格尺寸，需要对PCB板进行CNC锣边或V-cut。",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process5@icon.png',
					imgList:[{
						name:"锣边",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx1.jpg',
					},
					{
						name:"锣边",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx2.jpg',
					},
					{
						name:"锣边",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx3.jpg',
					},
					{
						name:"V割",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cx4.jpg',
					}]
				},
				{
					title:"电路板测试",
					text:"出货前采用自动电测或飞针测试，杜绝一切开路、短路。确保每一片到客户手上的板子都是合格产品。",
					icon:process.env.VUE_APP_OSS_URL + '/factoryDisplay/process6@icon.png',
					imgList:[{
						name:"飞针测试",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs1.jpg',
					},
					{
						name:"测试细节",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs2.jpg',
					},
					{
						name:"自动通断测试机",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs3.jpg',
					},
					{
						name:"自动测试机",
						img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/cs4.jpg',
					}]
				}]
			}
		},

		banner:{
			title:"备注： 1、特殊叠层包含-客户指定/盲埋/高频混压/阻抗/厚铜板等。 2、超过2.4mm需要特殊报价，常规板厚我司做到4mm，最大5mm；"
		},

		iaminatedStructure:{
			list: [{
				title: "四层板压合结构图",
				notes: "（不含电镀/阻焊/字符/表面工艺厚度）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "0.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.08 (1080)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.08 (1080)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.4 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.13mm",
					name: "0.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.60 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.33mm",
					name: "0.8mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.80 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.53mm",
					name: "1.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.00 +/-0.13mm",
							type: true
						}
					]
				}, {
					core: "0.73mm",
					name: "1.2mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.73",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.20 +/-10%",
							type: true
						}
					]
				}, {
					core: "1.13mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "1.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						}
					]
				}, {
					core: "1.53mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "1.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						}
					]
				}, {
					core: "1.96mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "1.96",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						}
					]
				}]
			},
			{
				title: "六层板压合结构图",
				notes: "（不含电镀/阻焊/字符/表面工艺厚度）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "0.8mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "0.80 +/-0.13mm",
							type: true
						}
					]
				},
				{
					core: "0.23mm",
					name: "1.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.00 +/-0.13mm",
							type: true
						}
					]
				},
				{
					core: "0.33mm",
					name: "1.2mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.20 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.53mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.73mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.73",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.73",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.93mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.93",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.93",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						}
					]
				}]
			},
			{
				title: "八层板压合结构图",
				notes: "（不含电镀/阻焊/字符/表面工艺厚度）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "1.2mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.20 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.23mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.23mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						}
					]
				},
				{
					core: "0.53mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.53",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						}
					]
				}]
			},
			{
				title: "十层板压合结构图",
				notes: "（不含电镀/阻焊/字符/表面工艺厚度）",
				navIndex: 0, //nav下标
				dataList: [{
					core: "0.13mm",
					name: "1.6mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L9-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L10-Cu:",
							text: "0.175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "1.60 +/-10%",
							type: true
						},

					]
				},
				{
					core: "0.23mm",
					name: "2.0mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.23",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.13",
							type: false
						},
						{
							title: "L9-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L10-Cu:",
							text: "0.175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.00 +/-10%",
							type: true
						},

					]
				},
				{
					core: "0.33mm",
					name: "2.4mm",
					text: [{
							title: "L1-Cu:",
							text: "0.0175 (0.5oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L2-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L3-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L4-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L5-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L6-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L7-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.19 (7628)",
							type: false
						},
						{
							title: "L8-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "CORE:",
							text: "0.33",
							type: false
						},
						{
							title: "L9-Cu:",
							text: "0.035 (1oz)",
							type: false
						},
						{
							title: "PP:",
							text: "0.12 (2116)",
							type: false
						},
						{
							title: "L10-Cu:",
							text: "0.175 (0.5oz)",
							type: false
						},
						{
							title: "Finish:",
							text: "2.40 +/-10%",
							type: true
						},

					]
				}]
			}]
		},

		processShow:{
			gaugeOutfit1:"项目",
			gaugeOutfit2:"项目名称",
			gaugeOutfit3:"制程能力",
			list:[{
				title:'整体制程能力',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'层数',
				text:'1-30层',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'高频混压HDI',
				text:'陶瓷料、PTFE料只能走机械钻盲埋孔或控深钻、背钻等（不能激光钻孔，不能直接压合铜箔）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'高速HDI',
				text:'按常规HDI制作',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'激光阶数',
				text:'1-5阶（≥6阶需要评审）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'板厚范围',
				text:'0.1-5.0mm（小于0.2mm，大于6.5mm需评审，）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'板厚范围',
				text:'0.1-5.0mm（小于0.2mm，大于6.5mm需评审，）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'最小成品尺寸',
				text:'单板5*5mm（小于3mm需评审）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'最大成品尺寸',
				text:'2-20层21*33inch; 备注：板边短边超出21inch需评审。',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'最大完成铜厚',
				text:'外层8OZ（大于8OZ需评审），内层6OZ（大于6OZ需评审）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'最小完成铜厚',
				text:'1/2oz',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'层间对准度',
				text:'≤3mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'通孔填孔范围',
				text:'板厚≤0.6mm，孔径≤0.2mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'树脂塞孔板厚范围',
				text:'0.254-6.0mm，PTFE板树脂塞孔需评审',
				list:[]
			},
			{
				title:'',
				textType:true,
				lengthIndex:18,
				towLengthIndex:2,
				name:'板厚度公差',
				text:'板厚≤1.0mm；±0.1mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'',
				text:'板厚＞1.0mm；±10%',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'阻抗公差',
				text:'±5Ω（＜50Ω），±10%（≥50Ω）；±8%（≥50Ω，需评审）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'翘曲度',
				text:'常规：0.75%，极限0.5%（需评审） 最大2.0%',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:0,
				name:'压合次数',
				text:'同一张芯板压合≤5次（大于3次需评审）',
				list:[]
			},
			{
				title:'材料类型',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'普通Tg FR4',
				text:'生益S1141、建滔KB6160A、国纪GF212',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'中Tg FR4',
				text:'生益S1150G(中Tg板材)、建滔KB6165F、建滔KB6165G（无卤）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'高Tg FR4',
				text:'生益S1165（无卤）、建滔KB6167G（无卤）、建滔KB6167F',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'铝基板',
				text:'国纪GL12、清晰CS-AL-88/89 AD2、聚秦JQ-143 1-8层混压FR-4',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'HDI板使用材料类型',
				text:'LDPP（IT-180A 1037和1086）、普通106与1080',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'高CTI',
				text:'生益S1600',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'高Tg FR4',
				text:'Isola：FR408、FR408HR、IS410、FR406、GETEK、PCL-370HR；联茂：IT-180A、IT-150DA； Nelco：N4000-13、N4000-13EP、N4000-13SI、N4000-13EPSI；松下:R-5775K（Megtron6）、R-5725（Megtron4）；建滔：KB6167F；台光：EM-827； 宏仁：GA-170；南亚：NP-180；台耀：TU-752、TU-662；日立：MCL-BE-67G（H）、MCL-E-679（W）、 MCL-E-679F（J）；腾辉：VT-47；',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'陶瓷粉填充高频材料',
				text:'Rogers：Rogers4350、Rogers4003;Arlon:25FR、25N;',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'聚四氟乙烯高频材料',
				text:'Rogers系列、Taconic系列、Arlon系列、Nelco系列、TP系列',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'PTFE半固化片',
				text:'Taconic：TP系列、TPN系列、HT1.5(1.5mil)、Fastrise系列',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:11,
				towLengthIndex:0,
				name:'材料混压',
				text:'Rogers、Taconic、Arlon、Nelco与FR-4',
				list:[]
			},
			{
				title:'金属基板',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'层数',
				text:'铝基板、铜基板：1-8层；冷板、烧结板、埋金属板：2-24层；陶瓷板：1-2层；',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'成品尺寸（铝基板、铜基板、冷板、烧结板、埋金属板）',
				text:'MAX:610*610mm、MIN:5*5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'生产尺寸最大（陶瓷板）',
				text:'100*100mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'成品板厚',
				text:'0.5-5.0mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'铜厚',
				text:'0.5-10 OZ',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'金属基厚',
				text:'0.5-4.5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'金属基材质',
				text:'AL：1100/1050/2124/5052/6061；Copper：紫铜纯铁',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'最小成品孔径及公差',
				text:'NPTH：0.5±0.05mm；PTH(铝基板、铜基板)：0.3±0.1mm；PTH(冷板、烧结板、埋金属板)：0.2±0.10mm；',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'外形加工精度',
				text:'±0.2mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'PCB部分表面处理工艺',
				text:'有/无铅喷锡；OSP；沉镍(钯)金；电(镍) 软/硬金；电镀锡；无镍电镀软硬金；厚金制作',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'金属表面处理',
				text:'铜：镀镍金；铝：阳极氧化、硬质氧化、化学钝化；机械处理：干法喷沙、拉丝',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'金属基材料',
				text:'全宝铝基板（T-110、T-111）；腾辉铝基板（VT-4A1、VT-4A2、VT-4A3）；莱尔德铝基板（1KA04、1KA06）；贝格斯金属基板（MP06503、HT04503）；TACONIC金属基板（TLY-5、TLY-5F）；',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'导热胶厚度（介质层）',
				text:'75-150um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'埋铜块尺寸',
				text:'3*3mm—70*80mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'埋铜块平整度（落差精度）',
				text:'±40um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'埋铜块到孔壁距离',
				text:'≥12mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:17,
				towLengthIndex:0,
				name:'导热系数',
				text:'0.3-3W/m.k（铝基板、铜基板、冷板）；8.33W/m.k（烧结板）；0.35-30W/m.k（埋金属板）；24-180W/m.k（陶瓷板）；',
				list:[]
			},
			{
				title:'产品类型',
				textType:false,
				lengthIndex:1,
				towLengthIndex:0,
				name:'刚性板',
				text:'背板、HDI、多层埋盲孔、厚铜板、电源厚铜、半导体测试板',
				list:[]
			},
			{
				title:'叠层方式',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'多次压合盲埋孔板',
				text:'同一面压合≤3',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'HDI板类型',
				text:'1+n+1、1+1+n+1+1、2+n+2、3+n+3(n中埋孔≤0.3mm)，激光盲孔可以电镀填孔',
				list:[]
			},
			{
				title:'局部混压',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'局部混压区域机械钻孔到导体最小距离',
				text:'≤10层：14mil；12层：15mil；＞12层：18mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:2,
				towLengthIndex:0,
				name:'局部混压交界处到钻孔最小距离',
				text:'≤12层：12mil；＞12层：15mil',
				list:[]
			},
			{
				title:'表面处理',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'无铅',
				text:'电镀铜镍金、沉金、镀硬金（有/无镍）、镀金手指、无铅喷锡、OSP、化学镍钯金、 镀软金（有/无镍）、沉银、沉锡、ENIG+OSP、ENIG+G/F、全板镀金+G/F、沉银+G/F、沉锡+G/F',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'有铅',
				text:'有铅喷锡',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'厚径比',
				text:'10：1（有铅/无铅喷锡，化学沉镍金，沉银，沉锡，化学镍钯金）；8：1（OSP）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'加工尺寸(MAX)',
				text:'沉金：520*800mm，垂直沉锡：500*600mm、水平沉锡：单边小于500mm；水平沉 银：单边小于500mm；有铅/无铅喷锡：520*650mm；OSP：单边小于500mm；电镀硬金：450*500mm； 单边不允许超过520mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'加工尺寸(MIN)',
				text:'沉锡：60*80mm；沉银：60*80mm；有铅/无铅喷锡：150*230mm；OSP：60*80mm； 小于以上尺寸的走大板表处',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'加工板厚',
				text:'沉金：0.2-7.0mm，沉锡：0.3-7.0mm（垂直沉锡线）、0.3-3.0mm（水平 线）；沉银：0.3-3.0mm；有铅/无铅喷锡：0.6-3.5mm；0.4mm以下喷锡板需评审制作； OSP：0.3-3.0mm；电镀硬金：0.3-5.0mm（板厚比10:1）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'沉金板IC最小间距或PAD到线最小间距',
				text:'3mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'金手指高度最大',
				text:'1.5inch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'金手指间最小间距',
				text:'6mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:0,
				name:'分段金手指最小分段间距',
				text:'7.5mil',
				list:[]
			},
			{
				title:'表面镀层厚度',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'喷锡',
				text:'2-40um（有铅喷锡大锡面最薄厚度0.4um，无铅喷锡大锡面最薄厚度1.5um）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'OSP',
				text:'膜层厚度：0.2-0.4um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'化学沉镍金',
				text:'镍厚：3-5um；金厚：1-3uinch，≥3uinch需评审',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'化学沉银',
				text:'6-12uinch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'化学沉锡',
				text:'锡厚≥1um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'电镀硬金',
				text:'2-50uinch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'电镀软金',
				text:'金厚0.10-1.5um（干膜图镀工艺），金厚0.10-4.0um（非干膜图镀工艺）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'化学镍钯金',
				text:'NI:3-5um，Pd：1-6uinch，Au：1-4uinch',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'电镀铜镍金',
				text:'金厚0.025-0.10um，镍厚≥3um，基铜厚度最大1OZ',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'金手指镀镍金',
				text:'金厚1-50uinch(要求值指最薄点)，镍厚≥3um',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'碳油',
				text:'10-50μm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'绿油',
				text:'铜面盖油(10-18um)、过孔盖油（5-8um）、线路拐角处≥5um（一次印刷、铜厚48um以下）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:0,
				name:'蓝胶',
				text:'0.20-0.80mm',
				list:[]
			},
			{
				title:'钻孔',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'0.1/0.15/0.2mm机械钻孔最大板厚',
				text:'0.8mm/1.5mm/2.5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'激光钻孔孔径最小',
				text:'0.1mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'激光钻孔孔径最大',
				text:'0.15mm',
				list:[]
			},
			{
				title:'',
				textType:true,
				lengthIndex:25,
				towLengthIndex:6,
				name:'机械孔直径（成品）',
				text:'0.10-6.2mm（对应钻刀0.15-6.3mm）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'PTFE材料（含混压）板最小成品孔径0.25mm（对应钻刀0.35mm）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'机械埋盲孔孔径≤0.3mm（对应钻刀0.4mm）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'盘中孔绿油塞孔钻孔直径≤0.45mm（对应钻刀0.55mm）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'连孔孔径最小0.35mm（对应钻刀0.45mm）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'',
				text:'金属化半孔孔径最小0.30mm（对应钻刀0.4mm）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'通孔板厚径比最大',
				text:'20:1(不含≤0.2mm刀径；＞12:1需评)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'激光钻孔深度孔径比最大',
				text:'1：1',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'机械控深钻盲孔深度孔径比最大',
				text:'1.3:1(孔径≤0.20mm),1.15:1(孔径≥0.25mm)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'机械控深钻（背钻）深度最小',
				text:'0.2mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-机械钻孔到导体最小距离(非埋盲孔板和一阶激光盲孔)',
				text:'5.5mil(≤8层)；6.5mil（10-14）；7mil(＞14层)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-机械钻孔到导体最小距离(机械埋盲孔板和二阶激光埋盲孔)',
				text:'7mil（一次压合）；8mil(二次压合)；9mil(三次压合)',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-机械钻孔到导体最小距离(激光盲埋孔)',
				text:'7mil（1+N+1）；8mil（1+1+N+1+1或2+N+2）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-激光钻孔到导体最小体距离(1、2阶HDI板)',
				text:'5mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-不同网络孔壁之间距离最小（补偿后）',
				text:'10mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-相同网络孔壁之间距离最小（补偿后）',
				text:'6mil(通孔；激光盲孔)；10mil（机械盲埋孔）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-非金属孔壁之间距离最小（补偿后）',
				text:'8mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-孔位公差（与CAD数据比）',
				text:'±2mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-NPTH孔孔径公差最小',
				text:'±2mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-免焊器件孔孔径精度',
				text:'±2mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-锥形孔深度公差',
				text:'±0.15mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:25,
				towLengthIndex:0,
				name:'钻孔-锥形孔孔口直径公差',
				text:'±0.15mm',
				list:[]
			},
			{
				title:'焊盘（环）',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'激光孔内、外层焊盘尺寸最小',
				text:'10mil（4mil激光孔），11mil（5mil激光孔）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'机械过孔内、外层焊盘尺寸最小',
				text:'16mil（8mil孔径）',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'BGA焊盘直径最小',
				text:'有铅喷锡工艺10mil，无铅喷锡工艺12mil，其它工艺7mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:4,
				towLengthIndex:0,
				name:'焊盘公差（BGA）',
				text:'+/-1.2mil（焊盘＜12mil）；+/-10%（焊盘≥12mil）',
				list:[]
			},
			{
				title:'线宽/间距',
				textType:true,
				lengthIndex:13,
				towLengthIndex:11,
				name:'铜厚对应的极限线宽',
				text:'1/2OZ：3/3mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'1OZ: 3/4mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'2OZ: 5/5mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'3OZ: 7/7mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'4OZ: 12/12mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'5OZ: 16/16mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'6OZ: 20/20mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'7OZ: 24/24mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'8OZ: 28/28mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'9OZ: 30/30mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:11,
				name:'',
				text:'10OZ: 32/30mil',
				list:[]
			},
			{
				title:'',
				textType:true,
				lengthIndex:13,
				towLengthIndex:2,
				name:'线宽公差',
				text:'≤10mil：+/-1.0mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:13,
				towLengthIndex:2,
				name:'',
				text:'＞10mil：+/-1.5mil',
				list:[]
			},
			{
				title:'阻焊字符',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'阻焊塞孔最大钻孔直径（两面盖油',
				text:'0.5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'阻焊油墨颜色',
				text:'绿、黄、黑、蓝、红、白、紫、哑光绿、哑油黑、高折射白油',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'字符油墨颜色',
				text:'白、黄、黑',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'蓝胶铝片塞孔最大直径',
				text:'5mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'树脂塞孔钻孔孔径范围',
				text:'0.1-1.0mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'树脂塞孔最大厚径比',
				text:'12：1',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'阻焊桥最小宽度',
				text:'绿油4mil、杂色6mil 控制阻焊桥需特别要求',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'最小字符线宽宽度',
				text:'白色字符3mil 高24mil； 黑色字符5mil 高32mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'镂空字最小间距',
				text:'镂空宽度8mil 高40mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:10,
				towLengthIndex:2,
				name:'阻焊层镂空字',
				text:'镂空宽度8mil 高40mil',
				list:[]
			},
			{
				title:'外形',
				textType:true,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT不漏铜的中心线到图形距离',
				text:'H≤1.0mm:0.3mm(20°指V-CUT角度)、0.33mm(30°)、0.37mm(45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'',
				text:'1.0＜H≤1.6mm:0.36mm(20°)、0.4mm(30°)、0.5mm(45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'',
				text:'1.6＜H≤2.4mm:0.42mm(20°)、0.51mm(30°)、0.64mm(45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'',
				text:'2.4＜H≤3.2mm:0.47mm(20°)、0.59mm(30°)、0.77mm(45°);',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT对称度公差',
				text:'±4mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT线数量最多',
				text:'100条',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT角度公差',
				text:'±5度',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'V-CUT角度规格',
				text:'20、30、45度',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'金手指倒角角度',
				text:'20、30、45度',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'金手指倒角角度公差',
				text:'±5度',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'金手指旁TAB不倒伤的最小距离',
				text:'6mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'金手指侧边与外形边缘线最小距离',
				text:'8mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'控深铣槽（边）深度精度（NPTH）',
				text:'±0.10mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'外形尺寸精度(边到边)',
				text:'±8mil',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'铣槽槽孔最小公差（PTH）',
				text:'槽宽、槽长方向均±0.15mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'铣槽槽孔最小公差（NPTH）',
				text:'槽宽、槽长方向均±0.10mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'钻槽槽孔最小公差（PTH）',
				text:'槽宽方向±0.075mm；槽长/槽宽<2:槽长方向+/-0.1mm；槽长/槽宽≥2:槽长方向+/-0.075mm',
				list:[]
			},
			{
				title:'',
				textType:false,
				lengthIndex:18,
				towLengthIndex:4,
				name:'钻槽槽孔最小公差（NPTH）',
				text:'槽宽方向±0.05mm；槽长/槽宽<2:槽长方向+/-0.075mm；槽长/槽宽≥2:槽长方向+/-0.05mm',
				list:[]
			}]
		},

		productList:{
			title:"全球电子智能硬件智造平台 - ",
			text:"无论多急,都能如期",
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr1.jpg',
				title:"多层盲孔高频PCB板",
				text:"盲孔高频电路板",
				time:"1个月前"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr2.jpg',
				title:"微波射频板",
				text:"4层沉金高频板",
				time:"1个月前"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr3.jpg',
				title:"四层混压高频板",
				text:"Rogers高频板",
				time:"1个月前"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr4.jpg',
				title:"6层高频PCB",
				text:"6层沉金线路板",
				time:"1个月前"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr5.jpg',
				title:"汽车摄像头PCB",
				text:"刚挠结合电路板",
				time:"1个月前"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr6.jpg',
				title:"通讯4层PCB板",
				text:"无铅喷锡电路板",
				time:"1个月前"
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr7.jpg',
				title:"汽车4层PCB板",
				text:"四层沉金线路板",
				time:"1个月前"
			}]
		},

		activity:{
			title1:"全球",
			title2:"电子智能硬件智造",
			title3:"品牌",
			text1:"品牌，源于贴心服务",
			text2:"品质，源于不断创新",
			list:[{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz1.JPG',
				title:"2022第十届中国电子信息博览会",
				text:"2022年第十届中国电子信息博览会顺利收官。为了参加此次展会，公司积极组织精英团队，由资深工程师和业务带领，作为电路板行业的标杆企业，展示出了业内先进的技术和创新解决方案，吸引了众多国内外观众的关注和参观。",
				time:"2022-09-22",
				imgList:[{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz1.JPG'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz2.JPG'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz3.JPG'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz4.JPG'
				}]
			},
			{
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz5.jpg',
				title:"2023第十一届中国电子信息博览会",
				text:"2023年4月9日，第十一届中国电子信息博览会（CITE2023）在深圳会展中心顺利收官。作为展示全球电子信息产业最新产品和技术的顶级平台，中国电子信息博览会自2013年首次举办以来，经过十一载时光淬炼，一路见证了产业发展的壮阔历程，已成为推动技术创新的参与者与引领产业发展的风向标。",
				time:"2022-04-09",
				imgList:[{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz5.jpg'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz6.jpg'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz7.jpg'
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/hz8.jpg'
				}]
			}]
		},

		sunbathing:{
			title1:"全球65万家",
			title2:"客户选择格物思",
			text:"PCBA一站式服务, 好评率达99%"
		}
	},

	aboutUs:{
		aboutUs:{
			// title:"北京格物思电子有限公司",
			title:"深圳市格物思信息科技有限公司",
			content1:{
				title:"全球用户数",
				text:"余家"
			},
			content2:{
				title:"日常流程订单",
				text:"余单"
			},
			content3:{
				title:"员工总数",
				text:"余人"
			},
			content4:{
				title:"月出货量",
				text:"平米"
			},
			content5:{
				title:"覆盖国家",
				text:"个"
			},
			content6:"深圳市格物思信息科技有限公司成立于2009年，目前在国内设立多家分公司(深圳芯航国际电子有限公司，武汉格物思信息科技有限公司等)，并在香港、深圳、德国、美国等地设立仓储物流中心;全国设有两大生产基地:安徽广德产业基地和浙江诸暨视觉智能产业园。目前已为欧洲、亚洲的客户提供涵盖元器件、PCB、SMT等一站式PCBA服务。利用互联网、物联网、云技术对电子制造业细分行业全价值链进行深度的改造，采用分析行业共同需求、集中采购、拉式补货的备货方式，帮助工厂客户按客户需要进行生产，实现真正意义上的VMI模式。",
			// content6:"北京格物思电子有限公司成立于2009年，目前在国内设立多家分公司(深圳芯航国际电子有限公司，武汉格物思信息科技有限公司等)，并在香港、深圳、德国、美国等地设立仓储物流中心;全国设有两大生产基地:安徽广德产业基地和浙江诸暨视觉智能产业园。目前已为欧洲、亚洲的客户提供涵盖元器件、PCB、SMT等一站式PCBA服务。利用互联网、物联网、云技术对电子制造业细分行业全价值链进行深度的改造，采用分析行业共同需求、集中采购、拉式补货的备货方式，帮助工厂客户按客户需要进行生产，实现真正意义上的VMI模式。",
			content7:"格物思以其名称中格物致知的含义为根本宗旨，以科学、严谨的方式，寻找行业最底层的痛点，追求“快”:服务快、响应快、交期快、发展快，追求“好”:服务态度好、质量好、体验好。立志成为电子制造业最省心的助手！"
		},

		honor:{
			title:"资质荣誉"
		},

		coreConcepts:{
			title:'核心理念',
			content:'服务，信任，共赢，创新',
			list:[{
				id:1,
				title:'我们的愿景',
				content:'成为中国最值得信赖的IC元器件科技贸易服务商'
			},
			{
				id:2,
				title:'我们的使命',
				content:'成就客户，成就品牌，成就员工'
			},
			{
				id:4,
				title:'企业价值观',
				content:'客户第一，尊重个人，诚信正直，团队协作、追求卓越'
			}]
		},

		contactUs:{
			title1:"加强沟通",
			title2:"共创双赢",
			text1:"格物思 ● 全球电子智能硬件智造平台",
			title3:"联系我们",
			text2:"座机",
			text3:"邮箱",
			text4:"地址",
			list:[{
				name:'北京格物思电子有限公司',
				phone:'010-86229430',
				email:'info@koiios.net',
				address:'万科中央城福泽路8号院(北京房山区万科中央城福泽路8号院2号楼1511)'
			},{
				// name:'深圳市芯航国际电子有限公司（深圳总部）',
				name:'深圳市格物思信息科技有限公司',
				phone:'0755-82056199、82056299、82056399、82056499',
				email:'info@koiios.net',
				address:'深圳市罗湖区桂园街道红宝路139号蔡屋围金龙大厦1506-1508'
			},
			{
				name:'武汉分公司',
				phone:'027-63497638  63498598  63497068',
				email:'info@koiios.net',
				address:'武汉市东湖新技术开发区金融港一路7号光谷智慧园1号楼1304室'
			},
			{
				name:'香港分公司',
				phone:'00852-37052636',
				email:'info@koiios.net',
				address:'新界粉岭安全街33号丰盈工贸中心1楼J室'
			},
            {
                name:'荷兰分公司',
                //phone:'00852-37052636',
                email:'info@koiios.net',
                address:'荷兰阿姆斯特丹Kraijenhoffstraat 137A，1018RG'
            }
			],
			text5:"新闻资讯",
			text6:"格物思 ● 全球电子智能硬件智造平台"
		}
	},

	top:{
		content1:"订单管理",
		text1:"登录",
		text2:"注册",
		text3:"退出登录",
		text4:"您好",
		tabsList:[{
			id:1,
			name:'首页',
			list:[]
		},
		{
			id:2,
			name:'智能报价',
			list:[{
				id:18,
				name:"PCB计价"
			},
			{
				id:19,
				name:"SMT计价"
			},
			{
				id:20,
				name:"钢网计价"
			},
			{
				id:21,
				name:"BOM配单"
			}]
		},
		{
			id:3,
			name:'PCB制板',
			list:[{
				id:1,
				name:"PCB制板"
			},
			{
				id:2,
				name:"PCB批量"
			},
			// {
			// 	id:3,
			// 	name:"FPC批量"
			// },
			{
				id:4,
				name:"铝基板"
			}]
		},
		{
			id:4,
			name:'PCBA制造',
			list:[{
				id:5,
				name:"SMT贴片"
			},
			{
				id:6,
				name:"钢片"
			}]
		},
		{
			id:5,
			name:'元器件供应',
			list:[]
		},
		{
			id:6,
			name:'解决方案',
			list:[{
				id:7,
				name:"方案设计"
			},
			{
				id:8,
				name:"Layout设计"
			}]
		},
		{
			id:7,
			name:'工厂展示',
			list:[{
				id:9,
				name:"工厂展示"
			},
			{
				id:10,
				name:"层压结构"
			},
			{
				id:11,
				name:"工艺能力"
			},
			{
				id:12,
				name:"产品展示"
			},
			{
				id:13,
				name:"活动专题"
			},
			{
				id:14,
				name:"客户晒单"
			}]
		},
		{
			id:8,
			name:'关于格物思',
			list:[{
				id:15,
				name:"关于格物思"
			},
			{
				id:16,
				name:"联系我们"
			},{
				id:17,
				name:"新闻资讯"
			}]
		}],
	},
	login:{
		content:{
			title:"欢迎登录 格物思官方平台",
			title2:"欢迎注册 格物思官方平台",
			text1:"海外原厂库存，各大代理现货，支持线上、线下交易提供人民币、美金的结款结算方式。",
			text2:"根据客户和市场需求，我们常备通用物料现货库存，Tl、ADI、 MAXIM、MINI、ATMEL 等常用品牌，一站式解决客户的BOM 表需求。",
			text3:"集合欧美亚全球现货、期货资源，为您搜寻紧缺偏冷门停产器件，解决您的研发生产短缺、小批量物料难题。",
			text4:"采用专业的电子显微设备，及专业的验货人员，确保元器件的质量。",
			text5:"用大批量集采的方式，多渠道比价，确保最优的价格!",
			text6:"先进的ERP推送系统，不断的优化压缩响应的时间，确保响应快速!",
			button:"查看更多"
		},
		right:{
			title:"欢迎登录",
			mobile:"请输入账号",
			pword:"请输入密码",
			remember:"记住密码",
			button1:"登 录",
			button2:'登 录 中',
			text1:"还没有账号？",
			text2:"立即注册",
			text3:"账号登录",
			text4:"手机登录",
			text5:"管理后台登录",
			text6:"返回"
		}
	},
	register:{
		right:{
			title:"立即注册",
			phone:"请输入手机号码",
			qq:"请输入QQ号码",
			pword:"请输入密码",
			confirmpword:"请再次输入密码",
			smscode:"验证码",
			smscodeButton1:"发送验证码",
			smscodeButton2:"重新发送",
			button1:"立 即 注 册",
			button2:"注 册 中",
			button3:"返回登录",
			message:"获取验证码失败"
		}
	}
}
