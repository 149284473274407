const permission = {
	state:{
		isActive:false,
		topTabsIndex:0,
		routeMenuList:null,
		roleList:null,
	},
	mutations:{
		IS_ACTIVE(state, { isActive }) {
			state.isActive = isActive
		},
		TOP_TABS_INDEX(state, { topTabsIndex }){
			state.topTabsIndex = topTabsIndex
		},
		ROUTE_MENU_LIST(state, { routeMenuList }){
			state.routeMenuList = routeMenuList
		},
		ROLE_LIST(state, { roleList }){
			state.roleList = roleList
		}
	},
	actions:{
		setIsActive({ commit }, data) {
			commit('IS_ACTIVE', {
				isActive: data.isActive
			})
		},
		setTopTabsIndex({ commit }, data) {
			commit('TOP_TABS_INDEX', {
				topTabsIndex: data.topTabsIndex
			})
		},
		setRouteMenuList({ commit }, data) {
			commit('ROUTE_MENU_LIST', {
				routeMenuList: data.routeMenuList
			})
		},
		setRoleList({ commit }, data){
			commit('ROLE_LIST', {
				roleList: data.roleList
			})
		}
	},
	modules:{
		
	}
}

export default permission