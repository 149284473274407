
const permission = {
	state: {
		tabsIndex: null, //tabs下标位置
		language: navigator.language == 'zh-CN' ? 'cn' : 'en', //语言
	
		//-----------------------智能报价字段-----------------------------
		shippingMethod: 1, //出货方式
	
		boardLength: '', //板子长度
	
		boardWidth: '', //板子宽度
	
		boardNumber: '', //板子数量
	
		boardArea: '', //板子面积
	
		designDocuments: 0, //设计文件数
	
		boardType: 1, //板子类型
	
		boardNumberPliesIndex: 2, //板子层数
	
		TGindex: 2, //板材/TG下标
		TGsub: 3, //板材/TG
	
		boardThicknessIndex: 8, //板子厚度下标
		BoardThickness: 9, //板子厚度选项位置
	
		copperThicknessIndex: 0, //铜箔厚度下标
		copperThickness: 1, //铜箔厚度
	
		minimumLineWidth: 4, //最小线宽
	
		minimumThroughHole: 4, //最小过孔
	
		resistanceWeldingColor: 1, //焊接颜色
	
		characterColor: 1, //字符颜色
	
		solderPadSprayCoating: 1, //焊盘喷镀
	
		resistanceWeldingCoverage: 1, //焊接盖盘
	
		goldthick: 1,
	
		TestMethodRadio: 1,
	
		checkList: [], //特殊工艺所有选择后的选项
		checkListData: "", //特殊工艺选项字符串化用于传参
	
		packagingRequirements: 1, //包装要求
	
		addOrderNumber: 1, //增加订单编号
	
		dateManufacture: 1, //增加或者更新生产日期
	
		ULidentification: 1, //ul标识
	
		productionDraft: 1, //确认生产稿
	
		invoiceType: 1, //发票类型
	
		invoicingMethod: 1, //发票方式
	
		contractTitle: '', //收据以及合同抬头
	
		placingOrder: '', //下单人
		placingOrderPhone: '', //下单人手机号
	
		technicians: '', //技术人姓名
		techniciansPhone: '', //技术人手机号
	
		shippingAddress: '', //收货地址
		
		shippingAddressList:[],//收获地址列表
		
		shippingAddresslength:null,//长度
	
		calcPcbPriceData: '', //calcPcbPrice接口返回的计价数据
		
		bomForm:null,//bom Form表单选项
		
		xlsxName:null,//bom 上传的xlsx名
		
		bomSize:null,//bom 上传文件大小
		
		plainOptions:null,//bom 表头
		
		xlsxDataList:[],//bom xlsx列表
		
		columns:[],//表格第一行参数
		
		bomDataList:[],//xlsx内容
		
		pcbInfo:null,//pcb提交订单对象
		
		smtInfo:null,//smt提交订单对象
		
		steelInfo:null,//钢网提交订单对象
		
		bomInfo:null,//bom配单提交订单对象
		
		pcbOrderInfo:null,//pcb 订单列表
		
		componentsNumber:null,//smt元器件数量
		
		circuitBoardsNumber:null,//电路板数量
		
		processRequirements:2,//工艺需求
		
		steelMeshTypeIndex:1,//是否开钢网
		
		purchaseBehalfIndex:1,//代购元件
		
		conformalCoatingIndex:1,//是否喷三防漆
		
		componentBakingIndex:2,//元器件是否烘培
		
		bakingTimeIndex:1,//烘培时间
		
		bakingTimeIndex:1,//烘培时间
		
		smtDemand:null,//其他需求
		
		deliveryTime:1,//发货时间
		
		smtOrderInfo:null,//smt 订单列表
		
		steelTypeIndex:1,//钢网类型
		
		steelMeshSpecifications:1,//钢网规格
		
		nanoprocessing:1,//纳米处理
		
		smtProductionMethod:1,//smt制作方式
		
		steelMeshNumber:null,//钢网数量
		
		markRequirements:1,//mark需求
		
		steelMeshUsage:1,//钢网用途
		
		thicknessIndex:1,//厚度
		
		polishProcessIndex:1,//抛光工艺
		
		steelOtherRequirements:null,//其他需求
		
		steelOrderInfo:null,//钢网订单数据
		
		smtfee:null,//smt价格
		
		stentfee:null,//钢网价格
		
		bomQuantityIndex:1,//bom采购数量下标
		
		bomQuantity:null,//bom采购数量
		
		purchasemark:null,//采购备注
		
		weixinOrderIndex:1,//微信小程序订单下标
		
		dataInfoContent: null,//所有选项
	},
	mutations: {
		TABS_INDEX(state, {
			tabsIndex
		}) {
			state.tabsIndex = tabsIndex
		},
		LANGUAGE(state, {
			language
		}) {
			state.language = language
		},
		SHIPPING_METHOD(state, {
			shippingMethod
		}) {
			state.shippingMethod = shippingMethod
			// console.log(state.shippingMethod)
		},
		BOARD_LENGTH(state, {
			boardLength
		}) {
			state.boardLength = boardLength
		},
		BOARD_WIDTH(state, {
			boardWidth
		}) {
			state.boardWidth = boardWidth
		},
		BOARD_NUMBER(state, {
			boardNumber
		}) {
			state.boardNumber = boardNumber
		},
		BOARD_AREA(state, {
			boardArea
		}) {
			state.boardArea = boardArea
		},
		DESIGN_DOCUMENTS(state, {
			designDocuments
		}) {
			state.designDocuments = designDocuments
		},
		BOARD_TYPE(state, {
			boardType
		}) {
			state.boardType = boardType
		},
		BOARD_NUMBER_PLIES_INDEX(state, {
			boardNumberPliesIndex
		}) {
			state.boardNumberPliesIndex = boardNumberPliesIndex
		},
		TG_INDEX(state, {
			TGindex
		}) {
			state.TGindex = TGindex
		},
		TG_SUB(state, {
			TGsub
		}) {
			state.TGsub = TGsub
		},
		BOARD_THICKNESS_INDEX(state, {
			boardThicknessIndex
		}) {
			state.boardThicknessIndex = boardThicknessIndex
		},
		BOARD_THICKNESS(state, {
			BoardThickness
		}) {
			state.BoardThickness = BoardThickness
		},
		COPPER_THICKNESS_INDEX(state, {
			copperThicknessIndex
		}) {
			state.copperThicknessIndex = copperThicknessIndex
		},
		COPPER_THICKNESS(state, {
			copperThickness
		}) {
			state.copperThickness = copperThickness
		},
		MINIMUM_LINE_WIDTH(state, {
			minimumLineWidth
		}) {
			state.minimumLineWidth = minimumLineWidth
		},
		MINIMUM_THROUGH_HOLE(state, {
			minimumThroughHole
		}) {
			state.minimumThroughHole = minimumThroughHole
		},
		RESISTANCE_WELDING_COLOR(state, {
			resistanceWeldingColor
		}) {
			state.resistanceWeldingColor = resistanceWeldingColor
		},
		CHARACTER_COLOR(state, {
			characterColor
		}) {
			state.characterColor = characterColor
		},
		SOLDER_PAD_SPRAY_COATING(state, {
			solderPadSprayCoating
		}) {
			state.solderPadSprayCoating = solderPadSprayCoating
		},
		RESISTANCE_WELDING_COVERAGE(state, {
			resistanceWeldingCoverage
		}) {
			state.resistanceWeldingCoverage = resistanceWeldingCoverage
		},
		GOLDTHICK(state, {
			goldthick
		}) {
			state.goldthick = goldthick
			// sessionStorage.setItem("GOLDTHICK", JSON.stringify(goldthick))
		},
		TEST_METHOD_RADIO(state, {
			TestMethodRadio
		}) {
			state.TestMethodRadio = TestMethodRadio
		},
		CHECK_LIST(state, {
			checkList
		}) {
			state.checkList = checkList
		},
		CHECK_LIST_DATA(state, {
			checkListData
		}) {
			// console.log("CHECK_LIST_DATA",checkListData)
			state.checkListData = checkListData
		},
	
		PACKAGING_REQUIREMENTS(state, {
			packagingRequirements
		}) {
			state.packagingRequirements = packagingRequirements
		},
		ADD_ORDER_NUMBER(state, {
			addOrderNumber
		}) {
			state.addOrderNumber = addOrderNumber
		},
		DATE_MANU_FACTURE(state, {
			dateManufacture
		}) {
			state.dateManufacture = dateManufacture
		},
		UL_IDENTIFICATION(state, {
			ULidentification
		}) {
			state.ULidentification = ULidentification
		},
		PRODUCTION_DRAFT(state, {
			productionDraft
		}) {
			state.productionDraft = productionDraft
		},
		INVOICE_TYPE(state, {
			invoiceType
		}) {
			state.invoiceType = invoiceType
		},
		INVOICING_METHOD(state, {
			invoicingMethod
		}) {
			state.invoicingMethod = invoicingMethod
		},
		CONTRACT_TITLE(state, {
			contractTitle
		}) {
			state.contractTitle = contractTitle
		},
		PLACING_ORDER(state, {
			placingOrder
		}) {
			state.placingOrder = placingOrder
		},
		PLACING_ORDER_PHONE(state, {
			placingOrderPhone
		}) {
			state.placingOrderPhone = placingOrderPhone
		},
		TECHNICIANS(state, {
			technicians
		}) {
			state.technicians = technicians
		},
		TECHNICIANS_PHONE(state, {
			techniciansPhone
		}) {
			state.techniciansPhone = techniciansPhone
		},
		SHIPPING_ADDRESS(state, {
			shippingAddress
		}) {
			state.shippingAddress = shippingAddress
		},
		SHIPPING_ADDRESS_LIST(state, {
			shippingAddressList
		}) {
			state.shippingAddressList = shippingAddressList
		},
		SHIPPING_ADDRESS_LENGTH(state, {
			shippingAddresslength
		}) {
			state.shippingAddresslength = shippingAddresslength
		},
		CALC_PCB_PRICE_DATA(state, {
			calcPcbPriceData
		}) {
			state.calcPcbPriceData = calcPcbPriceData
		},
		BOM_FORM(state, {
			bomForm
		}) {
			state.bomForm = bomForm
		},
		XLSX_NAME(state, {
			xlsxName
		}) {
			state.xlsxName = xlsxName
		},
		BOM_SIZE(state, {
			bomSize
		}) {
			state.bomSize = bomSize
		},
		PLAIN_OPTIONS(state, {
			plainOptions
		}) {
			state.plainOptions = plainOptions
		},
		XLSX_DATA_LIST(state, {
			xlsxDataList
		}) {
			state.xlsxDataList = xlsxDataList
		},
		COLUMNS(state, {
			columns
		}) {
			state.columns = columns
		},
		BOM_DATA_LIST(state, {
			bomDataList
		}) {
			state.bomDataList = bomDataList
		},
		PCB_INFO(state, {
			pcbInfo
		}) {
			state.pcbInfo = pcbInfo
		},
		SMT_INFO(state, {
			smtInfo
		}) {
			state.smtInfo = smtInfo
		},
		STEEL_INFO(state, {
			steelInfo
		}) {
			state.steelInfo = steelInfo
		},
		BOM_INFO(state, {
			steelInfo
		}) {
			state.steelInfo = steelInfo
		},
		PCB_ORDER_INFO(state, {
			pcbOrderInfo
		}) {
			state.pcbOrderInfo = pcbOrderInfo
		},
		COMPONENTS_NUMBER(state, {
			componentsNumber
		}) {
			state.componentsNumber = componentsNumber
		},
		CIRCUIT_BOARDS_NUMBER(state, {
			circuitBoardsNumber
		}) {
			state.circuitBoardsNumber = circuitBoardsNumber
		},
		PROCESS_REQUIREMENTS(state, {
			processRequirements
		}) {
			state.processRequirements = processRequirements
		},
		STEEL_MESH_TYPE_INDEX(state, {
			steelMeshTypeIndex
		}) {
			state.steelMeshTypeIndex = steelMeshTypeIndex
		},
		PURCHASE_BEHALF_INDEX(state, {
			purchaseBehalfIndex
		}) {
			state.purchaseBehalfIndex = purchaseBehalfIndex
		},
		CONFORMAL_COATING_INDEX(state, {
			conformalCoatingIndex
		}) {
			state.conformalCoatingIndex = conformalCoatingIndex
		},
		COMPONENT_BAKING_INDEX(state, {
			componentBakingIndex
		}) {
			state.componentBakingIndex = componentBakingIndex
		},
		BAKING_TIME_INDEX(state, {
			bakingTimeIndex
		}) {
			state.bakingTimeIndex = bakingTimeIndex
		},
		SMT_DEMAND(state, {
			smtDemand
		}) {
			state.smtDemand = smtDemand
		},
		DELIVERY_TIME(state, {
			deliveryTime
		}) {
			state.deliveryTime = deliveryTime
		},
		SMT_ORDER_INFO(state, {
			smtOrderInfo
		}) {
			state.smtOrderInfo = smtOrderInfo
		},
		STEEL_TYPE_INDEX(state, {
			steelTypeIndex
		}) {
			state.steelTypeIndex = steelTypeIndex
		},
		STEEL_MESH_SPECIFICATIONS(state, {
			steelMeshSpecifications
		}) {
			state.steelMeshSpecifications = steelMeshSpecifications
		},
		NANOPROCESSING(state, {
			nanoprocessing
		}) {
			state.nanoprocessing = nanoprocessing
		},
		SMT_PRODUCTION_METHOD(state, {
			smtProductionMethod
		}) {
			state.smtProductionMethod = smtProductionMethod
		},
		STEEL_MESH_NUMBER(state, {
			steelMeshNumber
		}) {
			state.steelMeshNumber = steelMeshNumber
		},
		MARK_REQUIREMENTS(state, {
			markRequirements
		}) {
			state.markRequirements = markRequirements
		},
		STEEL_MESH_USAGE(state, {
			steelMeshUsage
		}) {
			state.steelMeshUsage = steelMeshUsage
		},
		THICKNESS_INDEX(state, {
			thicknessIndex
		}) {
			state.thicknessIndex = thicknessIndex
		},
		POLISH_PROCESS_INDEX(state, {
			polishProcessIndex
		}) {
			state.polishProcessIndex = polishProcessIndex
		},
		STEEL_OTHER_REQUIREMENTS(state, {
			steelOtherRequirements
		}) {
			state.steelOtherRequirements = steelOtherRequirements
		},
		STEEL_ORDER_INFO(state, {
			steelOrderInfo
		}) {
			state.steelOrderInfo = steelOrderInfo
		},
		SMT_FEE(state, {
			smtfee
		}) {
			state.smtfee = smtfee
		},
		STENT_FEE(state, {
			stentfee
		}) {
			state.stentfee = stentfee
		},
		BOM_QUANTITY_INDEX(state, {
			bomQuantityIndex
		}) {
			state.bomQuantityIndex = bomQuantityIndex
		},
		BOM_QUANTITY(state, {
			bomQuantity
		}) {
			state.bomQuantity = bomQuantity
		},
		PURCHASE_MARK(state, {
			purchasemark
		}) {
			state.purchasemark = purchasemark
		},
		WEI_XIN_ORDER_INDEX(state, {
			weixinOrderIndex
		}) {
			state.weixinOrderIndex = weixinOrderIndex
		},
		DATA_INFO_CONTENT(state, {
			dataInfoContent
		}) {
			state.dataInfoContent = dataInfoContent
		},
	},
	actions: {
		setTabsIndex({
			commit
		}, data) {
			commit('TABS_INDEX', {
				tabsIndex: data.tabsIndex
			})
		},
		setLanguage({
			commit
		}, data) {
			commit('LANGUAGE', {
				language: data.language
			})
		},
		setShippingMethod({
			commit
		}, data) {
			commit('SHIPPING_METHOD', {
				shippingMethod: data.shippingMethod
			})
		},
		setBoardLength({
			commit
		}, data) {
			commit('BOARD_LENGTH', {
				boardLength: data.boardLength
			})
		},
		setBoardWidth({
			commit
		}, data) {
			commit('BOARD_WIDTH', {
				boardWidth: data.boardWidth
			})
		},
		setBoardNumber({
			commit
		}, data) {
			commit('BOARD_NUMBER', {
				boardNumber: data.boardNumber
			})
		},
		setBoardArea({
			commit
		}, data) {
			commit('BOARD_AREA', {
				boardArea: data.boardArea
			})
		},
		setDesignDocuments({
			commit
		}, data) {
			commit('DESIGN_DOCUMENTS', {
				designDocuments: data.designDocuments
			})
		},
		setBoardType({
			commit
		}, data) {
			commit('BOARD_TYPE', {
				boardType: data.boardType
			})
		},
		setBoardNumberPliesIndex({
			commit
		}, data) {
			commit('BOARD_NUMBER_PLIES_INDEX', {
				boardNumberPliesIndex: data.boardNumberPliesIndex
			})
		},
		setTGindex({
			commit
		}, data) {
			commit('TG_INDEX', {
				TGindex: data.TGindex
			})
		},
		setTGsub({
			commit
		}, data) {
			commit('TG_SUB', {
				TGsub: data.TGsub
			})
		},
		setBoardThicknessIndex({
			commit
		}, data) {
			commit('BOARD_THICKNESS_INDEX', {
				boardThicknessIndex: data.boardThicknessIndex
			})
		},
		setBoardThickness({
			commit
		}, data) {
			commit('BOARD_THICKNESS', {
				BoardThickness: data.BoardThickness
			})
		},
		setCopperThicknessIndex({
			commit
		}, data) {
			commit('COPPER_THICKNESS_INDEX', {
				copperThicknessIndex: data.copperThicknessIndex
			})
		},
		setCopperThickness({
			commit
		}, data) {
			commit('COPPER_THICKNESS', {
				copperThickness: data.copperThickness
			})
		},
		setMinimumLineWidth({
			commit
		}, data) {
			commit('MINIMUM_LINE_WIDTH', {
				minimumLineWidth: data.minimumLineWidth
			})
		},
		setMinimumThroughHole({
			commit
		}, data) {
			commit('MINIMUM_THROUGH_HOLE', {
				minimumThroughHole: data.minimumThroughHole
			})
		},
		setResistanceWeldingColor({
			commit
		}, data) {
			commit('RESISTANCE_WELDING_COLOR', {
				resistanceWeldingColor: data.resistanceWeldingColor
			})
		},
		setCharacterColor({
			commit
		}, data) {
			commit('CHARACTER_COLOR', {
				characterColor: data.characterColor
			})
		},
		setSolderPadSprayCoating({
			commit
		}, data) {
			commit('SOLDER_PAD_SPRAY_COATING', {
				solderPadSprayCoating: data.solderPadSprayCoating
			})
		},
		setResistanceWeldingCoverage({
			commit
		}, data) {
			commit('RESISTANCE_WELDING_COVERAGE', {
				resistanceWeldingCoverage: data.resistanceWeldingCoverage
			})
		},
		setGoldthick({
			commit
		}, data) {
			commit('GOLDTHICK', {
				goldthick: data.goldthick
			})
		},
		setTestMethodRadio({
			commit
		}, data) {
			commit('TEST_METHOD_RADIO', {
				TestMethodRadio: data.TestMethodRadio
			})
		},
		setCheckList({
			commit
		}, data) {
			commit('CHECK_LIST', {
				checkList: data.checkList
			})
		},
		setCheckListData({
			commit
		}, data) {
			commit('CHECK_LIST_DATA', {
				checkListData: data.checkListData
			})
		},
		setPackagingRequirements({
			commit
		}, data) {
			commit('PACKAGING_REQUIREMENTS', {
				packagingRequirements: data.packagingRequirements
			})
		},
		setAddOrderNumber({
			commit
		}, data) {
			commit('ADD_ORDER_NUMBER', {
				addOrderNumber: data.addOrderNumber
			})
		},
		setDateManufacture({
			commit
		}, data) {
			commit('DATE_MANU_FACTURE', {
				dateManufacture: data.dateManufacture
			})
		},
		setULidentification({
			commit
		}, data) {
			commit('UL_IDENTIFICATION', {
				ULidentification: data.ULidentification
			})
		},
		setProductionDraft({
			commit
		}, data) {
			commit('PRODUCTION_DRAFT', {
				productionDraft: data.productionDraft
			})
		},
		setInvoiceType({
			commit
		}, data) {
			commit('INVOICE_TYPE', {
				invoiceType: data.invoiceType
			})
		},
		setInvoicingMethod({
			commit
		}, data) {
			commit('INVOICING_METHOD', {
				invoicingMethod: data.invoicingMethod
			})
		},
		setContractTitle({
			commit
		}, data) {
			commit('CONTRACT_TITLE', {
				contractTitle: data.contractTitle
			})
		},
		setPlacingOrder({
			commit
		}, data) {
			commit('PLACING_ORDER', {
				placingOrder: data.placingOrder
			})
		},
		setPlacingOrderPhone({
			commit
		}, data) {
			commit('PLACING_ORDER_PHONE', {
				placingOrderPhone: data.placingOrderPhone
			})
		},
		setTechnicians({
			commit
		}, data) {
			commit('TECHNICIANS', {
				technicians: data.technicians
			})
		},
		setTechniciansPhone({
			commit
		}, data) {
			commit('TECHNICIANS_PHONE', {
				techniciansPhone: data.techniciansPhone
			})
		},
		setShippingAddress({
			commit
		}, data) {
			commit('SHIPPING_ADDRESS', {
				shippingAddress: data.shippingAddress
			})
		},
		setShippingAddressList({
			commit
		}, data) {
			commit('SHIPPING_ADDRESS_LIST', {
				shippingAddressList: data.shippingAddressList
			})
		},
		setShippingAddressLength({
			commit
		}, data) {
			commit('SHIPPING_ADDRESS_LENGTH', {
				shippingAddresslength: data.shippingAddresslength
			})
		},
		setCalcPcbPriceData({
			commit
		}, data) {
			commit('CALC_PCB_PRICE_DATA', {
				calcPcbPriceData: data.calcPcbPriceData
			})
		},
		setBomForm({
			commit
		}, data) {
			commit('BOM_FORM', {
				bomForm: data.bomForm
			})
		},
		setXlsxName({
			commit
		}, data) {
			commit('XLSX_NAME', {
				xlsxName: data.xlsxName
			})
		},
		setBomSize({
			commit
		}, data) {
			commit('BOM_SIZE', {
				bomSize: data.bomSize
			})
		},
		setPlainOptions({
			commit
		}, data) {
			commit('PLAIN_OPTIONS', {
				plainOptions: data.plainOptions
			})
		},
		setXlsxDataList({
			commit
		}, data) {
			commit('XLSX_DATA_LIST', {
				xlsxDataList: data.xlsxDataList
			})
		},
		setColumns({
			commit
		}, data) {
			commit('COLUMNS', {
				columns: data.columns
			})
		},
		setBomDataList({
			commit
		}, data) {
			commit('BOM_DATA_LIST', {
				bomDataList: data.bomDataList
			})
		},
		setPcbInfo({
			commit
		}, data) {
			commit('PCB_INFO', {
				pcbInfo: data.pcbInfo
			})
		},
		setSmtInfo({
			commit
		}, data) {
			commit('SMT_INFO', {
				smtInfo: data.smtInfo
			})
		},
		setSteelInfo({
			commit
		}, data) {
			commit('STEEL_INFO', {
				steelInfo: data.steelInfo
			})
		},
		setBomInfo({
			commit
		}, data) {
			commit('BOM_INFO', {
				bomInfo: data.bomInfo
			})
		},
		setPcbOrderInfo({
			commit
		}, data) {
			commit('PCB_ORDER_INFO', {
				pcbOrderInfo: data.pcbOrderInfo
			})
		},
		setComponentsNumber({
			commit
		}, data) {
			commit('COMPONENTS_NUMBER', {
				componentsNumber: data.componentsNumber
			})
		},
		setCircuitBoardsNumber({
			commit
		}, data) {
			commit('CIRCUIT_BOARDS_NUMBER', {
				circuitBoardsNumber: data.circuitBoardsNumber
			})
		},
		setProcessRequirements({
			commit
		}, data) {
			commit('PROCESS_REQUIREMENTS', {
				processRequirements: data.processRequirements
			})
		},
		setSteelMeshTypeIndex({
			commit
		}, data) {
			commit('STEEL_MESH_TYPE_INDEX', {
				steelMeshTypeIndex: data.steelMeshTypeIndex
			})
		},
		setPurchaseBehalfIndex({
			commit
		}, data) {
			commit('PURCHASE_BEHALF_INDEX', {
				purchaseBehalfIndex: data.purchaseBehalfIndex
			})
		},
		setConformalCoatingIndex({
			commit
		}, data) {
			commit('CONFORMAL_COATING_INDEX', {
				conformalCoatingIndex: data.conformalCoatingIndex
			})
		},
		setComponentBakingIndex({
			commit
		}, data) {
			commit('COMPONENT_BAKING_INDEX', {
				componentBakingIndex: data.componentBakingIndex
			})
		},
		setBakingTimeIndex({
			commit
		}, data) {
			commit('BAKING_TIME_INDEX', {
				bakingTimeIndex: data.bakingTimeIndex
			})
		},
		setSmtDemand({
			commit
		}, data) {
			commit('SMT_DEMAND', {
				smtDemand: data.smtDemand
			})
		},
		setDeliveryTime({
			commit
		}, data) {
			commit('DELIVERY_TIME', {
				deliveryTime: data.deliveryTime
			})
		},
		setSmtOrderInfo({
			commit
		}, data) {
			commit('SMT_ORDER_INFO', {
				smtOrderInfo: data.smtOrderInfo
			})
		},
		setSteelTypeIndex({
			commit
		}, data) {
			commit('STEEL_TYPE_INDEX', {
				steelTypeIndex: data.steelTypeIndex
			})
		},
		setSteelMeshSpecifications({
			commit
		}, data) {
			commit('STEEL_MESH_SPECIFICATIONS', {
				steelMeshSpecifications: data.steelMeshSpecifications
			})
		},
		setNanoprocessing({
			commit
		}, data) {
			commit('NANOPROCESSING', {
				nanoprocessing: data.nanoprocessing
			})
		},
		setSmtProductionMethod({
			commit
		}, data) {
			commit('SMT_PRODUCTION_METHOD', {
				smtProductionMethod: data.smtProductionMethod
			})
		},
		setSteelMeshNumber({
			commit
		}, data) {
			commit('STEEL_MESH_NUMBER', {
				steelMeshNumber: data.steelMeshNumber
			})
		},
		setMarkRequirements({
			commit
		}, data) {
			commit('MARK_REQUIREMENTS', {
				markRequirements: data.markRequirements
			})
		},
		setSteelMeshUsage({
			commit
		}, data) {
			commit('STEEL_MESH_USAGE', {
				steelMeshUsage: data.steelMeshUsage
			})
		},
		setThicknessIndex({
			commit
		}, data) {
			commit('THICKNESS_INDEX', {
				thicknessIndex: data.thicknessIndex
			})
		},
		setPolishProcessIndex({
			commit
		}, data) {
			commit('POLISH_PROCESS_INDEX', {
				polishProcessIndex: data.polishProcessIndex
			})
		},
		setSteelOtherRequirements({
			commit
		}, data) {
			commit('STEEL_OTHER_REQUIREMENTS', {
				steelOtherRequirements: data.steelOtherRequirements
			})
		},
		setSteelOrderInfo({
			commit
		}, data) {
			commit('STEEL_ORDER_INFO', {
				steelOrderInfo: data.steelOrderInfo
			})
		},
		setSmtFee({
			commit
		}, data) {
			commit('SMT_FEE', {
				smtfee: data.smtfee
			})
		},
		setStentfee({
			commit
		}, data) {
			commit('STENT_FEE', {
				stentfee: data.stentfee
			})
		},
		setBomQuantityIndex({
			commit
		}, data) {
			commit('BOM_QUANTITY_INDEX', {
				bomQuantityIndex: data.bomQuantityIndex
			})
		},
		setBomQuantity({
			commit
		}, data) {
			commit('BOM_QUANTITY', {
				bomQuantity: data.bomQuantity
			})
		},
		setPurchasemark({
			commit
		}, data) {
			commit('PURCHASE_MARK', {
				purchasemark: data.purchasemark
			})
		},
		setWeixinOrderIndex({
			commit
		}, data) {
			commit('WEI_XIN_ORDER_INDEX', {
				weixinOrderIndex: data.weixinOrderIndex
			})
		},
		setDataInfoContent({
			commit
		}, data) {
			commit('DATA_INFO_CONTENT', {
				dataInfoContent: data.dataInfoContent
			})
		},
	},
	modules: {
	
	},
}

export default permission

