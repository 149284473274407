const permission = {
	state:{
		orderMainquery:null,
		procMainquery:null,
		saleList:[],
		sellAskExcel:null,
		rfqEcelList:null,
		rfqEcelForm:null,
		cgColumns:null,
		rfqMoneyList:null,
		rfqTaxList:null,
		rfqDeliList:null,
		exportColumns:null,
		exportCellData:null,
		exportPcbInfoList:null,
		collpayItem:null,
		replyDataList:null,
		taxPoints:null,
		shipway:null,
		placeOrderList:null,
		purchasePlacedList:null,//采购下单列表
		paymentForm:null,//付款form表单
		paymentData:null,//付款数据列表
		paymentExamineForm:null,//付款审核form表单
		paymentExamineData:null,//付款审核数据列表
		payAccTableList:null,//付款记账表
		payAccFrom:null,//付款from
		payAccInvoceData:null,//记账返点数据
		payAccSubData:null,//记账客户数据
		makeCollForm:null,//开票收票form
		makeCollTableList:null,//开票收票表单
		receivingForm:null,//收票form
		receivingTableList:null,//收票表单
		salesCollForm:null,//销售回款form
		salesCollList:null,//回款table
		anotherOrderList:null,//再次下单数据
		grossProfitList:null,//调整毛利
		returnSendDetails:null,//收发货数据
		addExcelFrom:null,
		approvalData:null,
		redistributionList:null,//重新分配采购选项
		toBeQuotedList:null,//待采购报价新增列表
		quotedList:null,//采购已报价新增列表
		//操作缓存对象
		cacheIndexArray:{
			procureIndex:'待报价',
			collVerifyIndex:'未确认',
			sellOrderIndex:6,
			collpayIndex:'收款',
			sellReturnIndex:'退货申请',
			purchaseReturnIndex:'退货列表',
			performanceIndex:'销售订单',
			purPerformanceIndex:'订单',
			sellPerformanceIndex:'订单',
			makeCollIndex:'开票',
			sellRebateIndex:4,
			purchaseRebate:'返点申请',
			sellReturnMoney:'0'
		},
		addInventoryList:null,//新增
		customerList:{
			userList:[],
			addressList:[],
			invoceList:[],
			loanList:[],
		},
		windowHeight:0
	},
	mutations:{
		ORDER_MAIN_QUERY(state, { orderMainquery }) {
			state.orderMainquery = orderMainquery
		},
		PROC_MAIN_QUERY(state, { procMainquery }) {
			state.procMainquery = procMainquery
		},
		SALE_LIST(state, { saleList }) {
			state.saleList = saleList
		},
		SELL_ASK_EXCEL(state, { sellAskExcel }){
			state.sellAskExcel = sellAskExcel
		},
		RFQ_ECEL_LIST(state, { rfqEcelList }){
			state.rfqEcelList = rfqEcelList
		},
		RFQ_ECEL_FORM(state, { rfqEcelForm }){
			state.rfqEcelForm = rfqEcelForm
		},
		CG_COLUMNS(state, { cgColumns }){
			state.cgColumns = cgColumns
		},
		RFQ_MONEY_LIST(state, { rfqMoneyList }){
			state.rfqMoneyList = rfqMoneyList
		},
		RFQ_TAX_LIST(state, { rfqTaxList }){
			state.rfqTaxList = rfqTaxList
		},
		RFQ_DELI_LIST(state, { rfqDeliList }){
			state.rfqDeliList = rfqDeliList
		},
		EXPORT_COLUMNS(state, { exportColumns }){
			state.exportColumns = exportColumns
		},
		EXPORT_CELL_DATA(state, { exportCellData }){
			state.exportCellData = exportCellData
		},
		EXPORT_PCB_INFO_LIST(state, { exportPcbInfoList }){
			state.exportPcbInfoList = exportPcbInfoList
		},
		COLLPAY_ITEM(state, { collpayItem }){
			state.collpayItem = collpayItem
		},
		REPLY_DATA_LIST(state, { replyDataList }){
			state.replyDataList = replyDataList
		},
		TAX_POINTS(state, { taxPoints }){
			state.taxPoints = taxPoints
		},
		SHIPWAY(state, { shipway }){
			state.shipway = shipway
		},
		PLACE_ORDER_LIST(state, { placeOrderList }){
			state.placeOrderList = placeOrderList
		},
		PURCHASE_PLACED_LIST(state, { purchasePlacedList }){
			state.purchasePlacedList = purchasePlacedList
		},
		PAYMENT_FORM(state, { paymentForm }){
			state.paymentForm = paymentForm
		},
		PAYMENT_DATA(state, { paymentData }){
			state.paymentData = paymentData
		},
		PAYMENT_EXAMINE_FORM(state, { paymentExamineForm }){
			state.paymentExamineForm = paymentExamineForm
		},
		PAYMENT_EXAMINE_DATA(state, { paymentExamineData }){
			state.paymentExamineData = paymentExamineData
		},
		PAY_ACC_TABLE_LIST(state, { payAccTableList }){
			state.payAccTableList = payAccTableList
		},
		PAY_ACC_FROM(state, { payAccFrom }){
			state.payAccFrom = payAccFrom
		},
		PAY_ACC_INVOCE_DATA(state, { payAccInvoceData }){
			state.payAccInvoceData = payAccInvoceData
		},
		PAY_ACC_SUB_DATA(state, { payAccSubData }){
			state.payAccSubData = payAccSubData
		},
		MAKE_COLL_FORM(state, { makeCollForm }){
			state.makeCollForm = makeCollForm
		},
		MAKE_COLL_TABLE_LIST(state, { makeCollTableList }){
			state.makeCollTableList = makeCollTableList
		},
		RECEIVING_FORM(state, { receivingForm }){
			state.receivingForm = receivingForm
		},
		RECEUVING_TABLE_LIST(state, { receivingTableList }){
			state.receivingTableList = receivingTableList
		},
		SALES_COLL_FORM(state, { salesCollForm }){
			state.salesCollForm = salesCollForm
		},
		SALES_COLL_LIST(state, { salesCollList }){
			state.salesCollList = salesCollList
		},
		ANOTHER_ORDER_LIST(state, { anotherOrderList }){
			state.anotherOrderList = anotherOrderList
		},
		GROSS_PROFIT_LIST(state, { grossProfitList }){
			state.grossProfitList = grossProfitList
		},
		CACHE_INDEX_ARRAY(state, { cacheIndexArray }){
			if(cacheIndexArray.procureIndex){
				state.cacheIndexArray.procureIndex = cacheIndexArray.procureIndex;
			}
			if(cacheIndexArray.collVerifyIndex){
				state.cacheIndexArray.collVerifyIndex = cacheIndexArray.collVerifyIndex;
			}
			if(cacheIndexArray.sellOrderIndex){
				state.cacheIndexArray.sellOrderIndex = cacheIndexArray.sellOrderIndex;
			}
			if(cacheIndexArray.collpayIndex){
				state.cacheIndexArray.collpayIndex = cacheIndexArray.collpayIndex;
			}
			if(cacheIndexArray.sellReturnIndex){
				state.cacheIndexArray.sellReturnIndex = cacheIndexArray.sellReturnIndex;
			}
			if(cacheIndexArray.purchaseReturnIndex){
				state.cacheIndexArray.purchaseReturnIndex = cacheIndexArray.purchaseReturnIndex;
			}
			if(cacheIndexArray.performanceIndex){
				state.cacheIndexArray.performanceIndex = cacheIndexArray.performanceIndex;
			}
			if(cacheIndexArray.purPerformanceIndex){
				state.cacheIndexArray.purPerformanceIndex = cacheIndexArray.purPerformanceIndex;
			}
			if(cacheIndexArray.sellPerformanceIndex){
				state.cacheIndexArray.sellPerformanceIndex = cacheIndexArray.sellPerformanceIndex;
			}
			if(cacheIndexArray.makeCollIndex){
				state.cacheIndexArray.makeCollIndex = cacheIndexArray.makeCollIndex;
			}
			if(cacheIndexArray.sellRebateIndex){
				state.cacheIndexArray.sellRebateIndex = cacheIndexArray.sellRebateIndex;
			}
			if(cacheIndexArray.purchaseRebate){
				state.cacheIndexArray.purchaseRebate = cacheIndexArray.purchaseRebate;
			}
			if(cacheIndexArray.sellReturnMoney){
				state.cacheIndexArray.sellReturnMoney = cacheIndexArray.sellReturnMoney;
			}
		},
		RETURN_SEND_DETAILS(state, { returnSendDetails }){
			state.returnSendDetails = returnSendDetails
		},
		ADD_EXCEL_FROM(state, { addExcelFrom }){
			state.addExcelFrom = addExcelFrom
			// console.log(state.addExcelFrom)
		},
		ADD_INVENTORY_LIST(state, { addInventoryList }){
			state.addInventoryList = addInventoryList
			// console.log(state.addInventoryList)
		},
		APPROVAL_DATA(state, { approvalData }){
			state.approvalData = approvalData
		},
		REDISTRIBUTION_LIST(state, { redistributionList }){
			state.redistributionList = redistributionList
		},
		CUSTOMER_LIST(state, { customerList }){
			if(customerList.userList){
				state.customerList.userList = customerList.userList;
			}
			if(customerList.addressList){
				state.customerList.addressList = customerList.addressList;
			}
			if(customerList.invoceList){
				state.customerList.invoceList = customerList.invoceList;
			}
			if(customerList.loanList){
				state.customerList.loanList = customerList.loanList;
			}
		},
		TO_BE_QUOTED_LIST(state, { toBeQuotedList }){
			state.toBeQuotedList = toBeQuotedList
		},
		QUOTED_LIST(state, { quotedList }){
			state.quotedList = quotedList
		},
		WINDOW_HEIGHT(state, { windowHeight }){
			state.windowHeight = windowHeight
		},
	},
	actions:{
		setOrderMainquery({ commit }, data) {
			commit('ORDER_MAIN_QUERY', {
				orderMainquery: data.orderMainquery
			})
		},
		setProcMainquery({ commit }, data) {
			commit('PROC_MAIN_QUERY', {
				procMainquery: data.procMainquery
			})
		},
		setSaleList({ commit }, data) {
			commit('SALE_LIST', {
				saleList: data.saleList
			})
		},
		setSellAskExcel({ commit }, data) {
			commit('SELL_ASK_EXCEL', {
				sellAskExcel: data.sellAskExcel
			})
		},
		setRfqEcelList({ commit }, data) {
			commit('RFQ_ECEL_LIST', {
				rfqEcelList: data.rfqEcelList
			})
		},
		setCgColumns({ commit }, data) {
			commit('CG_COLUMNS', {
				cgColumns: data.cgColumns
			})
		},
		setRfqEcelForm({ commit }, data) {
			commit('RFQ_ECEL_FORM', {
				rfqEcelForm: data.rfqEcelForm
			})
		},
		setRfqMoneyList({ commit }, data) {
			commit('RFQ_MONEY_LIST', {
				rfqMoneyList: data.rfqMoneyList
			})
		},
		setRfqTaxList({ commit }, data) {
			commit('RFQ_TAX_LIST', {
				rfqTaxList: data.rfqTaxList
			})
		},
		setRfqDeliList({ commit }, data) {
			commit('RFQ_DELI_LIST', {
				rfqDeliList: data.rfqDeliList
			})
		},
		setExportColumns({ commit }, data) {
			commit('EXPORT_COLUMNS', {
				exportColumns: data.exportColumns
			})
		},
		setExportCellData({ commit }, data) {
			commit('EXPORT_CELL_DATA', {
				exportCellData: data.exportCellData
			})
		},
		setExportPcbInfoList({ commit }, data) {
			commit('EXPORT_PCB_INFO_LIST', {
				exportPcbInfoList: data.exportPcbInfoList
			})
		},
		setCollpayItem({ commit }, data) {
			commit('COLLPAY_ITEM', {
				collpayItem: data.collpayItem
			})
		},
		setReplyDataList({ commit }, data) {
			commit('REPLY_DATA_LIST', {
				replyDataList: data.replyDataList
			})
		},
		setTaxPoints({ commit }, data) {
			commit('TAX_POINTS', {
				taxPoints: data.taxPoints
			})
		},
		setShipway({ commit }, data) {
			commit('SHIPWAY', {
				shipway: data.shipway
			})
		},
		setPlaceOrderList({ commit }, data) {
			commit('PLACE_ORDER_LIST', {
				placeOrderList: data.placeOrderList
			})
		},
		setPurchasePlacedList({ commit }, data) {
			commit('PURCHASE_PLACED_LIST', {
				purchasePlacedList: data.purchasePlacedList
			})
		},
		setPaymentForm({ commit }, data) {
			commit('PAYMENT_FORM', {
				paymentForm: data.paymentForm
			})
		},
		setPaymentData({ commit }, data) {
			commit('PAYMENT_DATA', {
				paymentData: data.paymentData
			})
		},
		setPaymentExamineForm({ commit }, data) {
			commit('PAYMENT_EXAMINE_FORM', {
				paymentExamineForm: data.paymentExamineForm
			})
		},
		setPaymentExamineData({ commit }, data) {
			commit('PAYMENT_EXAMINE_DATA', {
				paymentExamineData: data.paymentExamineData
			})
		},
		setPayAccTableList({ commit }, data) {
			commit('PAY_ACC_TABLE_LIST', {
				payAccTableList: data.payAccTableList
			})
		},
		setPayAccFrom({ commit }, data) {
			commit('PAY_ACC_FROM', {
				payAccFrom: data.payAccFrom
			})
		},
		setPayAccInvoceData({ commit }, data) {
			commit('PAY_ACC_INVOCE_DATA', {
				payAccInvoceData: data.payAccInvoceData
			})
		},
		setPayAccSubData({ commit }, data) {
			commit('PAY_ACC_SUB_DATA', {
				payAccSubData: data.payAccSubData
			})
		},
		setMakeCollForm({ commit }, data) {
			commit('MAKE_COLL_FORM', {
				makeCollForm: data.makeCollForm
			})
		},
		setMakeCollTableList({ commit }, data) {
			commit('MAKE_COLL_TABLE_LIST', {
				makeCollTableList: data.makeCollTableList
			})
		},
		setReceivingForm({ commit }, data) {
			commit('RECEIVING_FORM', {
				receivingForm: data.receivingForm
			})
		},
		setReceivingTableList({ commit }, data) {
			commit('RECEUVING_TABLE_LIST', {
				receivingTableList: data.receivingTableList
			})
		},
		setSalesCollForm({ commit }, data) {
			commit('SALES_COLL_FORM', {
				salesCollForm: data.salesCollForm
			})
		},
		setSalesCollList({ commit }, data) {
			commit('SALES_COLL_LIST', {
				salesCollList: data.salesCollList
			})
		},
		setAnotherOrderList({ commit }, data) {
			commit('ANOTHER_ORDER_LIST', {
				anotherOrderList: data.anotherOrderList
			})
		},
		setGrossProfitList({ commit }, data) {
			commit('GROSS_PROFIT_LIST', {
				grossProfitList: data.grossProfitList
			})
		},
		setCacheIndexArray({ commit }, data) {
			commit('CACHE_INDEX_ARRAY', {
				cacheIndexArray: data.cacheIndexArray
			})
		},
		setReturnSendDetails({ commit }, data) {
			commit('RETURN_SEND_DETAILS', {
				returnSendDetails: data.returnSendDetails
			})
		},
		setAddExcelFrom({ commit }, data) {
			commit('ADD_EXCEL_FROM', {
				addExcelFrom: data.addExcelFrom
			})
		},
		setAddInventoryList({ commit }, data) {
			commit('ADD_INVENTORY_LIST', {
				addInventoryList: data.addInventoryList
			})
		},
		setApprovalData({ commit }, data) {
			commit('APPROVAL_DATA', {
				approvalData: data.approvalData
			})
		},
		setRedistributionList({ commit }, data) {
			commit('REDISTRIBUTION_LIST', {
				redistributionList: data.redistributionList
			})
		},
		setCustomerList({ commit }, data) {
			commit('CUSTOMER_LIST', {
				customerList: data.customerList
			})
		},
		setToBeQuotedList({ commit }, data) {
			commit('TO_BE_QUOTED_LIST', {
				toBeQuotedList: data.toBeQuotedList
			})
		},
		setQuotedList({ commit }, data) {
			commit('QUOTED_LIST', {
				quotedList: data.quotedList
			})
		},
		setWindowHeight({ commit }, data) {
			commit('WINDOW_HEIGHT', {
				windowHeight: data.windowHeight
			})
		},
	},
	modules:{
		
	}
}

export default permission