import request from '@/utils/request'

// 品牌列表接口
export function brandList(data) {
  return request({
    url: '/xhBrand/brandList',
    method: 'get',
	params:data
  })
}

// 资讯中心列表接口
export function websiteList(data) {
  return request({
    url: '/xhInformationCenter/websiteList',
    method: 'get',
	params:data
  })
}

export function saveOrder(data) {
  return request({
    url: '/orderInfo/saveOrder',
    method: 'post',
	data:data
  })
}

// 资讯中心列表接口
export function calcPcbPrice(data) {
  return request({
    url: '/pcb/calcPcbPrice',
    method: 'post',
	data:data
  })
}

// 获取验证码接口
export function getCode(data) {
  return request({
    url: '/user/getCode',
    method: 'get',
	params:data
  })
}

// 注册接口
export function saveUser(data) {
  return request({
    url: '/customer/saveuser',
    method: 'post',
	data:data
  })
}

// 内部登录接口
export function userLand(data) {
  return request({
    url: '/user/land',
    method: 'post',
	data:data
  })
}

// 用户登录接口
export function clientLand(data) {
  return request({
    url: '/customer/clientLand',
    method: 'get',
	params:data
  })
}

//smt计价
export function calcPcbComponentlnfoPrice(data){
	return request({
	  url: '/pcb/calcSmtPrice',
	  method: 'post',
		data:data
	})
}

//钢网计价
export function calcStenPrice(data){
	return request({
	  url: '/pcb/calcStenPrice',
	  method: 'post',
		data:data
	})
}

//跳转小程序
export function generateScheme(data){
	return request({
	  url: '/wechat/generateScheme',
	  method: 'post',
	  data:data
	})
}

//我的积分消费记录
export function scorelist(data){
	return request({
	  url: '/user/scorelist',
	  method: 'post',
	  data:data
	})
}

//erp登录获取权限菜单接口
export function getMenuByRoleId(data){
	return request({
	  url: '/sys/getMenuByRoleId',
	  method: 'get',
	  params:data
	})
}

//erp登录获取权限按钮接口
export function getAllEnMenu(data){
	return request({
	  url: '/sys/getAllEnMenu',
	  method: 'get',
	  params:data
	})
}

//退出接口
export function logout(data){
	return request({
	  url: '/user/logout',
	  method: 'post',
	  data:data
	})
}

//发送邮件
export function sendmail(data){
	return request({
	  url: '/common/sendmail',
	  method: 'post',
	  data:data
	})
}

//点击日志接口
export function saveLog(data){
	return request({
	  url: '/common/saveLog',
	  method: 'post',
	  data:data
	})
}

//字典
export function getDictListByPid(data){
	return request({
	  url: '/dict/getDictListByPid',
	  method: 'post',
	  data:data
	})
}

//中文转字母
export function getABC(data){
	return request({
	  url: '/common/getABC',
	  method: 'post',
	  data:data
	})
}

//首页今日工作跟进
export function getPersonTaskList(data){
	return request({
	  url: '/report/getPersonTaskList',
	  method: 'post',
	  data:data
	})
}
