<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<!-- 加了keep-alive 就不会销毁 会被缓存下来 -->
			<keep-alive>
				<!-- 路由占位符 -->
				<router-view v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>

			<!-- 路由占位符 -->
			<router-view v-if="!$route.meta.keepAlive"></router-view>
		</div>
	</a-config-provider>
</template>

<script>
	import zhCN from 'ant-design-vue/es/locale/zh_CN'
	import route from '@/router/index.js'
	import wx from 'weixin-js-sdk'
	import Cookies from "js-cookie";
	import {
		clientLand,
		generateScheme,
	} from '@/api/home/index'
	import {
		rsaDecrypt
	} from '@/utils/rsa.js'
	import {
		getMenuByRoleId,
		getAllEnMenu
	} from '@/api/home/index.js'
	import newFile from '@/assets/401_images/new_file.json'
	// import fetchData from '../build.js'
	export default {
		name: "app",
		data() {
			return {
				locale: zhCN,
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			};
		},
		watch: {
			//监听浏览器窗口改变
			// windowWidth(val) {
			// 	this.windowWidth = val;
			// 	//监听高宽变化后后把监听的高宽赋值给vuex
			// 	this.$store.dispatch("setWindowWidthHeight", {
			// 	    windowWidth: val,
			// 	});
			// 	this.widthHide()
			// },
			// windowHeight(val) {
			// 	//监听高宽变化后后把监听的高宽赋值给vuex
			// 	this.$store.dispatch("setWindowWidthHeight", {
			// 	    windowHeight: val,
			// 	});
			// 	// this.windowHeight = val;
			// },
		},
		created(e) {
			// const component = { render(c) { return c('router-view') } }
			// console.log("2222",component)
			// console.log(this.$permission.randomColor())
			// this.webList.map(res=>{
			// 	let color=this.$permission.randomColor()
			// 	this.webList.map(res2=>{
			// 		if(res.orderno==res2.orderno){
			// 			res2['color'] = color
			// 		}
			// 	})
			// })
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					that.windowWidth = document.body.clientWidth;
					that.windowHeight = document.body.clientHeight;
				})();
			};
			if (newFile.newFileType == false) {
				this.$router.options.routes.map(res=>{
					if(res.path=='/erp'){
						if(res.children.length==1){
							setTimeout(() => {
								this.$router.go(0)
							}, 500);
						}
					}
				})
			}
			// 调用
			that.judgeEv()
			// this.initWebSocket();
		},

		computed: {

		},

		mounted() {
			var _hmt = _hmt || [];
			(function() {
				var hm = document.createElement("script");
				hm.src = "https://hm.baidu.com/hm.js?b468ef4f4636a2cdf554240b41976132";
				var s = document.getElementsByTagName("script")[0];
				s.parentNode.insertBefore(hm, s);
			})();
		},
		//销毁,否则跳到别的路由还是会出现
		destroyed() {

		},
		methods: {
			judgeEv() {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					// ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
					wx.miniProgram.getEnv((res) => {
						if (res.miniprogram) {
							let currentUrl = decodeURIComponent(window.location.href);
							currentUrl = currentUrl.split('?')[1]
							if (currentUrl !== undefined) {
								let userInfo = rsaDecrypt(currentUrl.split("userInfo=")[1].replace(/ /g, '+'))
								let data = JSON.parse(userInfo)
								// console.log(data)
								// let nikename = data[0].split("=")[1]
								// let phonenumber = data[1].split("=")[1]
								// let uid = data[2].split("=")[1]
								// let avatarurl = data[3].split("=")[1]
								// let userscore = data[4].split("=")[1]
								// console.log("1111",nikename)
								let weixinInfo = {
									mobile: data.phonenumber,
									type: 3
								}
								clientLand(weixinInfo).then(res2 => {
									if (res2.code == "200") {
										let user = {
											nikename: data.nickname,
											phonenumber: data.phonenumber,
											uid: data.uid,
											avatarurl: res2.data.avatarurl,
											userscore: data.userscore
										}
										// console.log(user)
										sessionStorage.setItem("getUserInfo", JSON.stringify(user))
										Cookies.set("userInfo2", JSON.stringify(res2.data), {
											expires: 30
										});
										Cookies.set("role2", res2.role, {
											expires: 30
										});
									} else {
										this.$message.error(res2.data.msg);
									}
								})
							}
						} else {
							// alert("不在小程序里");
							// console.log("不在小程序里")
						}
					})
				} else {
					// alert('不在微信里');
					if (this.windowWidth <= 768) {
						// console.log("不在小程序里")
						let data = {
							path: "/pages/login/index",
							query: ''
						}
						generateScheme(data).then(res => {
							this.$router.push({
								path: '/weixin',
								query: {
									openlink: res.openlink
								}
							})
						})
					} else {
						this.loginType();
					}
				}
			},
			initWebSocket() {
				let that = this;
				if ("WebSocket" in window) {
					console.log("您的浏览器支持 WebSocket!");
					that.ws = new WebSocket(`ws://localhost:6001/api/imserver/1`);
					that.$globalWebSocket.setWs(that.ws);
					// that.ws.onopen = that.onopen();
					that.ws.onopen = function() {
						console.log('webSocket connect successful')
					};
					that.ws.onclose = function() {
						// 关闭 websocket
						console.log("webSocket connect closed");
						setTimeout(() => {
							that.initWebSocket();
						}, 2000);
					};
				} else {
					// 浏览器不支持 WebSocket
					console.log("您的浏览器不支持 WebSocket!");
				}
			},

			//---------------判断是否登录过-------------------------
			loginType: function() {
				var userInfo = Cookies.get("userInfo")
				if (userInfo) {
					var role = Cookies.get("role")
					if (role !== "client") {
						getMenuByRoleId({
							roleId: role
						}).then(res => {
							sessionStorage.setItem("meunList", JSON.stringify(res))
							let data = []
							res.map(res2 => {
								let arr = {
									path: res2.menuenname,
									title: res2.menuname,
									name: res2.menupath,
									closable: true,
									meta: {
										name: res2.menuname,
										icon: res2.menuico,
										activeMenu: res2.menupath,
										keepAlive: res2.keeplive,
										type: res2.isvisible
									},
									children: []
								}
								if (res2.subList !== null) {
									res2.subList.map(res3 => {
										let arr2 = {
											path: res3.menuenname,
											title: res3.menuname,
											name: res3.menupath,
											closable: true,
											meta: {
												name: res3.menuname,
												icon: res3.menuico,
												activeMenu: res3.menupath,
												keepAlive: res3.keeplive,
												type: res3.isvisible,
												url: res3.url,
											},
										}
										arr.children.push(arr2)
									})
									data.push(arr)
								}
							})
							// console.log("app",data)
							this.$store.dispatch("setRouteMenuList", {
								routeMenuList: JSON.stringify(data),
							});

							getAllEnMenu({
								roleId: role
							}).then(res4 => {
								// console.log("getMenuByRoleId",res)
								let getAllEnMenu = []
								res4.data.map(res5 => {
									getAllEnMenu.push(res5.menuenname)
								})
								this.$store.dispatch("setRoleList", {
									roleList: JSON.stringify(getAllEnMenu),
								});
							})
						})
					}
				}
			}
		},
	};
</script>

<style lang="less">

</style>