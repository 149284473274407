import JSEncrypt from 'jsencrypt' // 加密公钥
const key = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsvqOG4DxG7L9rDTohtrI
CC+t0mKkJvJaRaD6mrzCU8Kft0ELU6DSAkodEtCgM2pKM4dOg5pF/6vgOOYy+nKu
u2RMAedKzGP4foarSLNNkhE81RXcEon22J273dAT35g4hfdvxDxrNOf/XFkN6aob
Ph/QTiAEDmH+WkKoZoJ87gh37YUoCeHKLKM0hMN39jmqGWHD1IhvtI2631QuiK0g
0+C7SmezCeB30VrvHwe3Enbs3ZlONkArb+lu8PZn314IWHBzAdYPhGda/a+7p/I4
XR6wBSGY2rI60kbZQVQc49put3wPQA5WNI2rAk/8mFnt94ZHI2gh9csyjDo62/pT
sQIDAQAB
-----END PUBLIC KEY-----` // 加密
export function rsaEncrypt(msg) {
	const jsencrypt = new JSEncrypt() 
	jsencrypt.setPublicKey(key) 
	const encryptMsg = jsencrypt.encrypt(msg) 
	return encryptMsg
} // 解密私钥
const privateKey = `-----BEGIN PRIVATE KEY-----
MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCy+o4bgPEbsv2s
NOiG2sgIL63SYqQm8lpFoPqavMJTwp+3QQtToNICSh0S0KAzakozh06DmkX/q+A4
5jL6cq67ZEwB50rMY/h+hqtIs02SETzVFdwSifbYnbvd0BPfmDiF92/EPGs05/9c
WQ3pqhs+H9BOIAQOYf5aQqhmgnzuCHfthSgJ4cosozSEw3f2OaoZYcPUiG+0jbrf
VC6IrSDT4LtKZ7MJ4HfRWu8fB7cSduzdmU42QCtv6W7w9mffXghYcHMB1g+EZ1r9
r7un8jhdHrAFIZjasjrSRtlBVBzj2m63fA9ADlY0jasCT/yYWe33hkcjaCH1yzKM
Ojrb+lOxAgMBAAECggEBAIVa+Q13Kq9H04k/Ns5cL1UiKXKrusGmLCrbQj6PTH/a
cOS7GzRpjHDWRglh1yvYE4eHVrHp/iP9LalXpD5Fuvm3RobYjjvydRHZR8B4kOC3
5Uqj6gEKORm3lWY1hslAA0WWUvm1Ru/5uKRXWm1zKK9GRxAe/Ma2ry0To6QVr/4u
o/2o9mKSMntpBxQwxF9Gm/GG+YWd+zDEt4davwGxe84krCzgiPDHasmD8hrZlhST
A49TGnPXWXv/QQo1+0P1/PG1gsk+P7MIUx3iDkAdqcBYsJUqE32jAIabQTaVDxTj
jqxhT6PPSLP5H+epRMsJLmawQqL43IfWyA5sHEKbX8ECgYEA3TLJj7/kOk7qw5xc
3nZeIFGeniU9HPacig/NOQU6p04ZJf5CbKa2orc92j3IuMOcyAmicPYxGAzJcJ4E
tm+1uHZQPU92C0o4Ob71miLpbQ8bF6vQC3CqGvLQesFBHqjDpYrTot4SuybFooN/
ray0138tHMoi4owItwaC/QNRt1kCgYEAzyNJH4NbXJ3lDYHsz81A9bgKTesapZ4q
234f1J0w3H5pSzuV+bflr7/BCNQJUf/krOxhd8Z6vZ9GPOGUIbYcbiUfnWAKUQY0
MYL6rUEXURhZSQpAfAFPDObvJ1gTwqDYDjbVdPrcopdfoh1DpygXg9y+zSvYo9S2
Nc+P0Fn5TBkCgYAPiFNzDqx+E7CUj9RNos41QsX6oO7Lq92OLFHi4SUfPnKxoksT
yhklD+z9+DyyDLpjqZmIiSElaTB0AgSesvV1wIkd5QiK/mk4VNp2OmFd0SO748q2
IvEu90IAfLWP65XheAAofaOfFLfoE4fRsED6qOmIDgGpGuA5yfFExwjj8QKBgBSg
UOHAdNKOLobfzzMKH1PYlb4YkTi4vU/KCWVkmb77H4UMU+zf8RFFKPW2F4+Aycam
azZAH2zOdXIOjG+m8MHPFIzZSW20DnJ3tnqWJro+kHWDfdRbVQHV5S6nyJ/y4FDa
CPtzF5FhPXapL1lv1pec3asUmKgHwHUvTATVUMvRAoGBALQQggbf5SUWcCBLaBZ1
7hyO8KhkrTsFBzak5ffLiNu3ueoS2acthnsDoJprrSmKT4BDudjYP9Y+qi6Nf/is
I5jtB2OAph7oQFRKagsq9NeNZeBTS83Fo0ov+cpClPjyCIozAwtRLovoX5TinEr2
glBQJaEt/aVc/RYrivrIh6u8
-----END PRIVATE KEY-----` // 解密
export function rsaDecrypt(msg) {
	const decrypt = new JSEncrypt() 
	decrypt.setPrivateKey(privateKey) 
	const decryptMsg = decrypt.decrypt(msg) 
	return decryptMsg
}