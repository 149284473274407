export default [{
  path: 'home',
  title: '首页',
  name: '/order/home',
  closable: false,
  role:'client',
  meta: {
    name: '首页',
    icon: 'el-icon-s-home',
    activeMenu: '/order/home',
    keepAlive: true,
    type: true
  },
  component: () => import('@/business/views/home.vue')
}, {
  path: 'orderManagement',
  title: '订单管理',
  name: '/order/orderManagement',
  role:'client',
  closable: true,
  meta: {
    name: '订单管理',
    icon: 'el-icon-s-order',
    activeMenu: '/order/orderManagement',
    keepAlive: true,
    type: true
  },
  component: { render(b) { return b('router-view') } },
  children: [{
	  path: 'shoppingCart',
	  name: '/order/orderManagement/shoppingCart',
	  role:'client',
	  meta: {
	    name: '购物车',
	    icon: 'el-icon-s-order',
	    activeMenu: '/order/orderManagement/shoppingCart',
	    keepAlive: true,
	    type: true
	  },
	  component: () => import('@/business/views/erp/order/shoppingCart/index.vue')
  },{
	  path: 'pcbOrder',
	  name: '/order/orderManagement/pcbOrder',
	  role:'client',
	  meta: {
	    name: 'PCB订单管理',
	    icon: 'el-icon-s-order',
	    activeMenu: '/order/orderManagement/pcbOrder',
	    keepAlive: true,
	    type: true
	  },
	  component: () => import('@/business/views/erp/order/pcbOrder/index.vue')
  },
  {
  	  path: 'smtOrder',
  	  name: '/order/orderManagement/smtOrder',
	  role:'client',
  	  meta: {
  	    name: 'SMT订单管理',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/orderManagement/smtOrder',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/erp/order/smtOrder/index.vue')
  },
  {
  	  path: 'seelOrder',
  	  name: '/order/orderManagement/seelOrder',
	  role:'client',
  	  meta: {
  	    name: '钢网订单管理',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/orderManagement/seelOrder',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/erp/order/seelOrder/index.vue')
  },
  {
  	  path: 'bomOrder',
  	  name: '/order/orderManagement/bomOrder',
	  role:'client',
  	  meta: {
  	    name: 'BOM订单管理',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/orderManagement/bomOrder',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/erp/order/bomOrder/index.vue')
  }]
},{
  path: 'userInfo',
  title: '账户信息管理',
  name: '/order/userInfo',
  role:'client',
  closable: true,
  meta: {
    name: '账户信息管理',
    icon: 'el-icon-user-solid',
    activeMenu: '/order/userInfo',
    keepAlive: true,
    type: true
  },
  component: { render(b) { return b('router-view') } },
  children: [{
  	  path: 'basicInformation',
  	  name: '/order/userInfo/basicInformation',
	  role:'client',
  	  meta: {
  	    name: '基本信息',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/userInfo/basicInformation',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/user/userInfo/basicInformation.vue')
  },
  {
  	  path: 'address',
  	  name: '/order/userInfo/address',
  	  role:'client',
  	  meta: {
  	    name: '收货地址管理',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/userInfo/address',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/user/userInfo/address.vue')
  },
  {
  	  path: 'changePassword',
  	  name: '/order/userInfo/changePassword',
  	  role:'client',
  	  meta: {
  	    name: '修改登录密码',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/userInfo/changePassword',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/user/userInfo/changePassword.vue')
  },
  {
  	  path: 'consumptionRecords',
  	  name: '/order/userInfo/consumptionRecords',
  	  role:'client',
  	  meta: {
  	    name: '积分消费记录',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/userInfo/consumptionRecords',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/user/userInfo/consumptionRecords.vue')
  },
  {
  	  path: 'obtainingRecords',
  	  name: '/order/userInfo/obtainingRecords',
  	  role:'client',
  	  meta: {
  	    name: '积分获取记录',
  	    icon: 'el-icon-s-order',
  	    activeMenu: '/order/userInfo/obtainingRecords',
  	    keepAlive: true,
  	    type: true
  	  },
  	  component: () => import('@/business/views/user/userInfo/obtainingRecords.vue')
  }]
}]
